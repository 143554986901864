import React from 'react';
import PropTypes from 'prop-types';

class BetaAccounts extends React.Component {
  constructor(props) {
    super(props);

    this.removeNotification = this.removeNotification.bind(this);
  }

  removeNotification() {
    this.props.removeNotification({ componentId: this.props.options.id });
  }

  render() {
    return (
      <div>
        <div>Preview the upcoming overhaul of the accounts website:  <a href="https://beta-accounts.paradoxinteractive.com" target="_blank">Check it out here</a></div>
        <div className="buttonContainer">
          <a className="close" onClick={this.removeNotification} role="button" tabIndex="0">
            <div className="inner" />
          </a>
        </div>
      </div>
    );
  }
}

BetaAccounts.propTypes = {
  removeNotification: PropTypes.func.isRequired,
  options: PropTypes.shape({
    id: PropTypes.number.isRequired,
    text: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};

export default BetaAccounts;
