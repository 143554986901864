import {
  SIGNUP_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  CONFIRMATION_SUCCESS,
  STEAM_CONNECTED,
  STEAM_CONNECT_EXPIRED,
  STEAM_NOT_CONNECTED,
  STEAM_CONNECT_REQUEST,
  STEAM_CONNECT_SUCCESS,
  STEAM_CONNECT_FAILURE,
  PSN_CONNECTED,
  PSN_NOT_CONNECTED,
  PSN_CONNECT_REQUEST,
  PSN_CONNECT_SUCCESS,
  PSN_CONNECT_FAILURE,
  XBOX_CONNECTED,
  XBOX_NOT_CONNECTED,
  XBOX_CONNECT_REQUEST,
  XBOX_CONNECT_SUCCESS,
  XBOX_CONNECT_FAILURE,
  TWITCH_CONNECTED,
  TWITCH_NOT_CONNECTED,
  TWITCH_CONNECT_REQUEST,
  TWITCH_CONNECT_SUCCESS,
  TWITCH_CONNECT_FAILURE,
  EPIC_CONNECTED,
  EPIC_NOT_CONNECTED,
  EPIC_CONNECT_REQUEST,
  EPIC_CONNECT_SUCCESS,
  EPIC_CONNECT_FAILURE,
  ACCOUNT_DETAILS_UPDATE_REQUEST,
  ACCOUNT_DETAILS_UPDATE_SUCCESS,
  REFERRAL_CODE_SUCCESS,
  GAMES_SUCCESS,
  GAMES_REQUEST_FINSIHED,
  INVALIDATE_GAMES,
  ORDERS_SUCCESS,
  DOWNLOADS_SUCCESS,
  SET_EMAIL_SUCCESS,
  REFRESH_SESSION_FAILURE,
  REFRESH_SESSION_SUCCESS,
  GAME_ACHIEVEMENTS_REQUEST,
  GAME_ACHIEVEMENTS_FAILURE,
  GAME_ACHIEVEMENTS_SUCCESS,
  ACTIVATE_GAME_KEY_SUCCESS,
} from '../actions/authentication';
import { randomBytes } from 'crypto';
import { encode as base64url } from 'base64url';
const random = (bytes = 32) => base64url(randomBytes(bytes));

const defaultUser = {
  session: {},
  userId: null,
  email: '',
  accountDetails: {},
  account: {},
};

export const initialState = {
  user: defaultUser,
  games: [],
  gameStateValid: false,
  downloads: [],
  orderHistory: {},
  orders: [],
  userHasMoreOrders: false,
  isLoginProcess: false,
  isLogoutProcess: false,
  isSuccess: false,
  isSignedIn: false,
  errorTitle: '',
  isSteamConnected: false,
  isSteamExpired: false,
  isSteamConnectionFetched: false,
  steamConnectProcess: false,
  isPsnConnected: false,
  isPsnConnectionFetched: false,
  psnConnectProcess: false,
  isXboxConnected: false,
  isXboxConnectionFetched: false,
  xboxConnectProcess: false,
  isTwitchConnected: false,
  isTwitchConnectionFetched: false,
  twitchConnectProcess: false,
  isEpicConnected: false,
  isEpicConnectionFetched: false,
  epicConnectProcess: false,
  accountDetailsUpdateProcess: false,
  loginInfoMessage: '',
  isLoadingAchievements: false,
  achievements: {},
  referralCode: '',
  twitchState: random(),
  twitchNonce: random(),
  epicState: random(),
  epicNonce: random(),
  psnState: random(),
  xboxState: random(),
  steamState: random(),
};

const authentication = (state = initialState, action) => {
  switch (action.type) {
    case SIGNUP_FAILURE:
      return { ...state, errorTitle: action.payload.message };
    case LOGIN_REQUEST:
      return { ...state, isLoginProcess: true };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoginProcess: false,
        isSuccess: true,
        isSignedIn: true,
        user: action.user,
        errorTitle: '',
      };
    case REFRESH_SESSION_FAILURE:
    case LOGOUT:
      return initialState;
    case CONFIRMATION_SUCCESS: {
      const newState = { ...state };
      newState.user.account.isEmailVerified = true;

      return newState;
    }
    case STEAM_CONNECTED:
      return { ...state, isSteamConnected: true, isSteamConnectionFetched: true };
    case STEAM_CONNECT_EXPIRED:
      return { ...state, isSteamExpired: action.expired, isSteamConnectionFetched: true };
    case STEAM_NOT_CONNECTED:
      return { ...state, isSteamConnected: false, isSteamConnectionFetched: true };
    case STEAM_CONNECT_REQUEST:
      return { ...state, steamConnectProcess: true };
    case STEAM_CONNECT_SUCCESS:
      return { ...state, isSteamConnected: true, steamConnectProcess: false };
    case STEAM_CONNECT_FAILURE:
      return { ...state, errorTitle: 'Steam connect failed', steamConnectProcess: false };
    case PSN_CONNECTED:
      return { ...state, isPsnConnected: true, isPsnConnectionFetched: true };
    case PSN_NOT_CONNECTED:
      return { ...state, isPsnConnected: false, isPsnConnectionFetched: true };
    case PSN_CONNECT_REQUEST:
      return { ...state, psnConnectProcess: true };
    case PSN_CONNECT_SUCCESS:
      return { ...state, isPsnConnected: true, psnConnectProcess: false };
    case PSN_CONNECT_FAILURE:
      return { ...state, errorTitle: 'PSN connect failed', psnConnectProcess: false };
    case XBOX_CONNECTED:
      return { ...state, isXboxConnected: true, isXboxConnectionFetched: true };
    case XBOX_NOT_CONNECTED:
      return { ...state, isXboxConnected: false, isXboxConnectionFetched: true };
    case XBOX_CONNECT_REQUEST:
      return { ...state, xboxConnectProcess: true };
    case XBOX_CONNECT_SUCCESS:
      return { ...state, isXboxConnected: true, xboxConnectProcess: false };
    case XBOX_CONNECT_FAILURE:
      return { ...state, errorTitle: 'XBOX connect failed', xboxConnectProcess: false };
    case TWITCH_CONNECTED:
      return { ...state, isTwitchConnected: true, isTwitchConnectionFetched: true };
    case TWITCH_NOT_CONNECTED:
      return { ...state, isTwitchConnected: false, isTwitchConnectionFetched: true };
    case TWITCH_CONNECT_REQUEST:
      return { ...state, twitchConnectProcess: true };
    case TWITCH_CONNECT_SUCCESS:
      return { ...state, isTwitchConnected: true, twitchConnectProcess: false };
    case TWITCH_CONNECT_FAILURE:
      return { ...state, errorTitle: 'Twitch connect failed', twitchConnectProcess: false };
    case EPIC_CONNECTED:
      return { ...state, isEpicConnected: true, isEpicConnectionFetched: true };
    case EPIC_NOT_CONNECTED:
      return { ...state, isEpicConnected: false, isEpicConnectionFetched: true };
    case EPIC_CONNECT_REQUEST:
      return { ...state, epicConnectProcess: true };
    case EPIC_CONNECT_SUCCESS:
      return { ...state, isEpicConnected: true, epicConnectProcess: false };
    case EPIC_CONNECT_FAILURE:
      return { ...state, errorTitle: 'Epic connect failed', epicConnectProcess: false };
    case ACCOUNT_DETAILS_UPDATE_REQUEST:
      return { ...state, accountDetailsUpdateProcess: true };
    case ACCOUNT_DETAILS_UPDATE_SUCCESS: {
      const newState = { ...state, accountDetailsUpdateProcess: false };
      newState.user.accountDetails = action.accountDetails;

      return newState;
    }
    case GAMES_SUCCESS:
      return { ...state, games: action.games };
    case GAMES_REQUEST_FINSIHED:
      return { ...state, gameStateValid: true };
    case INVALIDATE_GAMES:
      return { ...state, gameStateValid: false };
    case DOWNLOADS_SUCCESS:
      return { ...state, downloads: action.downloads };
    case ORDERS_SUCCESS:
      return { ...state, orders: action.orders, userHasMoreOrders: action.userHasMoreOrders };
    case SET_EMAIL_SUCCESS: {
      const newState = { ...state };
      newState.user.email = action.newEmail;

      return newState;
    }
    case REFRESH_SESSION_SUCCESS: {
      const newState = {
        ...state,
        user: { ...state.user, ...action.user },
      };
      newState.isSignedIn = true;

      return newState;
    }
    case GAME_ACHIEVEMENTS_REQUEST:
      return {
        ...state,
        isLoadingAchievements: true,
      };
    case GAME_ACHIEVEMENTS_FAILURE:
      return {
        ...state,
        isLoadingAchievements: false,
      };
    case GAME_ACHIEVEMENTS_SUCCESS:
      return {
        ...state,
        isLoadingAchievements: false,
        achievements: {
          ...state.achievements,
          [action.gameSku]: action.gameAchievements,
        },
      };
    case ACTIVATE_GAME_KEY_SUCCESS: {
      const newState = { ...state };

      const game = newState.games.find(g => g.sku === action.gameSku);
      game.keys[0].activated = true;

      return newState;
    }
    case REFERRAL_CODE_SUCCESS: {
      const newState = {
        ...state,
        referralCode: action.referralCode,
      };
      return newState;
    }
    default:
      return state;
  }
};

export default authentication;
