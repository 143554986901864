import React from 'react';
import PropTypes from 'prop-types';
import { withLoadingAnimation } from '../../../../utilities/loading';

class UnverifiedEmail extends React.Component {
  constructor(props) {
    super(props);
    this.resend = this.resend.bind(this);
    this.removeNotification = this.removeNotification.bind(this);
  }

  async resend() {
    withLoadingAnimation(this.props.confirmationEmailRequest(this.props.userId));
  }

  removeNotification() {
    this.props.removeNotification({ componentId: this.props.options.id });
  }

  render() {
    if (this.props.compState.requestSent) {
      return (
        <div>
          <div>
            We’ve sent a confirmation email to the address you’ve provided. Please click it to
            confirm your account.
          </div>
          <div className="buttonContainer">
            <a className="close" onClick={this.removeNotification} role="button" tabIndex="0">
              <div className="inner" />
            </a>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div>
          <h2>You need to confirm your email in order to use all of our services.</h2>
          <div>We have sent a link to your inbox to confirm your email. </div>
        </div>
        <div className="buttonContainer">
          <button className="button" onClick={this.resend}>
            Resend Confirmation
          </button>
          <a className="close" onClick={this.removeNotification} role="button" tabIndex="0">
            <div className="inner" />
          </a>
        </div>
      </div>
    );
  }
}

UnverifiedEmail.defaultProps = {
  compState: {
    requestSent: false,
  },
};

UnverifiedEmail.propTypes = {
  removeNotification: PropTypes.func.isRequired,
  confirmationEmailRequest: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  options: PropTypes.shape({
    id: PropTypes.number.isRequired,
    timing: PropTypes.number,
  }).isRequired,
  compState: PropTypes.shape({
    requestSent: PropTypes.bool,
  }),
};

export default UnverifiedEmail;
