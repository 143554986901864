import { connect } from 'react-redux';
import View from './steamAccount.view.jsx';
import { requestSteamDisconnect } from '../../../../actions/authentication';
import { getSteamUrl } from '../../../../utilities/Utilities';

const mapStateToProps = state => ({
  steamUrl: getSteamUrl(state.authentication.steamState),
  userId: state.authentication.user.userId,
  isSteamConnected: state.authentication.isSteamConnected,
  isSteamConnectionFetched: state.authentication.isSteamConnectionFetched,
  steamState: state.authentication.steamState,
});

const mapDispatchToProps = {
  requestSteamDisconnect,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
