import { APPEND_NOTIFICATION, REMOVE_NOTIFICATION } from '../actions/notifications';

import {
  LOGOUT,
  CONFIRMATION_EMAIL_SUCCESS,
  REFRESH_SESSION_FAILURE,
} from '../actions/authentication';

export const initialState = {
  active: false,
  components: [],
};

export const notificationItem = (name, options = {}, state = {}) => ({
  name,
  options,
  state,
});

const notifications = (state = initialState, action) => {
  switch (action.type) {
  case APPEND_NOTIFICATION: {
    const newState = { ...state };
    newState.components.push(
      notificationItem(action.compName, { ...action.options, id: action.id }),
    );

    return newState;
  }
  case CONFIRMATION_EMAIL_SUCCESS: {
    const newState = { ...state };
    const item = newState.components.find(c => c.name === 'UnverifiedEmail');
    item.state = { ...item.state, requestSent: true };

    return newState;
  }

  case REMOVE_NOTIFICATION: {
    const newState = { ...state };

    if (action.text) {
      newState.components = newState.components.filter(item => item.options.text !== action.text);
    } else if (action.componentId) {
      newState.components = newState.components.filter(
        item => item.options.id !== action.componentId,
      );
    } else if (action.name) {
      newState.components = newState.components.filter(item => item.name !== action.name);
    } else if (action.triggeredBy) {
      newState.components = newState.components.filter(
        item => item.options.triggeredBy !== action.triggeredBy,
      );
    }

    return newState;
  }
  case REFRESH_SESSION_FAILURE:
  case LOGOUT: {
    return {
      active: false,
      components: [],
    };
  }
  default:
    return state;
  }
};

export default notifications;
