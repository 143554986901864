import React from 'react';
import PropTypes from 'prop-types';
import { loading } from '../../../../utilities/loading';
import NotificationsBase from '../../notifications/Base/base';

import DownloadItem from '../DownloadItem';

class Downloads extends React.Component {
  async componentDidMount() {
    loading.start();
    await this.props.requestDownloads(this.props.downloads);
    loading.end();
  }

  render() {
    const downloadList = this.props.downloads.map(download => (
      <DownloadItem key={download.id} {...download} userId={this.props.userId} />
    ));

    return (
      <div>
        <NotificationsBase notificationType={['Download']} />
        <div id="yourDownloads">
          <h2>Your Downloads</h2>
          <ul className="downloadList">{downloadList}</ul>
        </div>
      </div>
    );
  }
}

Downloads.propTypes = {
  userId: PropTypes.string.isRequired,
  requestDownloads: PropTypes.func.isRequired,
  downloads: PropTypes.array.isRequired,
};

export default Downloads;
