import { respondWith, requestFromCore } from '../utils';

const status = async token => {
  const resp = await fetch('/api/newsletter/status', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
    method: 'GET',
    credentials: 'same-origin',
  });

  return respondWith(await resp.json());
};

const updateGroup = async(groupId, enable = false, token) => {
  const response = await requestFromCore({
    httpMethod: 'PUT',
    url: 'accounts/newsletter/groups',
    data: {
      groups: {
        [groupId]: enable,
      },
    },
    token,
  });

  return respondWith(await response.json());
};

const subscribe = async token => {
  const response = await requestFromCore({
    httpMethod: 'POST',
    url: 'accounts/newsletter/subscribe',
    token,
  });

  return respondWith(await response.json());
};

const unsubscribe = async token => {
  const response = await requestFromCore({
    httpMethod: 'DELETE',
    url: 'accounts/newsletter/subscribe',
    token,
  });

  return respondWith(await response.json());
};

export default {
  status,
  updateGroup,
  subscribe,
  unsubscribe,
};
