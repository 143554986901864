import React from 'react';
import RedeemCode from './Redeem/redeem';
import ClaimDrops from './Claim/claim';
import NotificationsBase from '../notifications/Base/base';

const redeemAndClaim = props => (
  <div>
    <NotificationsBase notificationType={['Redeem']} />
    <div id="redeemCode">
      <div>
        <RedeemCode {...props} />
      </div>
      <div>
        <ClaimDrops {...props} />
      </div>
    </div>
  </div>
);

export default redeemAndClaim;
