import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { persistStore, persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/es/storage';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'react-router-redux';

import reducers from './reducers/reducers';
import Utilities from './utilities/Utilities';

// prepare middleware
const history = createBrowserHistory();
const middleware = [thunk, routerMiddleware(history)];
const composeEnhancers = composeWithDevTools({
  serialize: true,
});
const enhancer = composeEnhancers(applyMiddleware(...middleware));

// restore state from cache if user is authenticated
const rootReducer = persistCombineReducers(
  {
    key: 'reduxState',
    blacklist: ['notifications'],
    storage,
  },
  reducers,
);

const store = createStore(rootReducer, enhancer);
const persistor = persistStore(store);
if (!Utilities.sessionCookie) {
  persistor.purge();
}

export { store, history, persistor };
