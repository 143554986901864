import {
  NEWSLETTER_STATUS_SUCCESS,
  SUBSCRIBE_NEWSLETTER_SUCCESS,
  UNSUBSCRIBE_NEWSLETTER_SUCCESS,
  SUBSCRIBE_GROUP_NEWSLETTER_SUCCESS,
  UNSUBSCRIBE_GROUP_NEWSLETTER_SUCCESS,
  NEWSLETTER_CONFIRMATION_SUCCESS,
} from '../actions/newsletter';

import { LOGOUT, REFRESH_SESSION_FAILURE } from '../actions/authentication';

export const initialState = {
  fetchedStatus: false,
  status: '',
  groups: [],
  platformGroups: [],
};

const newsletter = (state = initialState, action) => {
  switch (action.type) {
  case NEWSLETTER_STATUS_SUCCESS: {
    return {
      ...state,
      fetchedStatus: true,
      status: action.subscriber.status,
      groups: action.subscriber.groups,
      platformGroups: action.subscriber.platformGroups,
    };
  }
  case SUBSCRIBE_GROUP_NEWSLETTER_SUCCESS: {
    const newState = { ...state };
    const group = newState.groups.find(item => item.id === action.group.id) || newState.platformGroups.find(item => item.id === action.group.id);;
    group.enabled = !group.enabled;
    newState.groups[newState.groups.indexOf(group)] = group;

    return newState;
  }
  case UNSUBSCRIBE_GROUP_NEWSLETTER_SUCCESS: {
    const newState = { ...state };
    const group = newState.groups.find(item => item.id === action.group.id) || newState.platformGroups.find(item => item.id === action.group.id);
    group.enabled = !group.enabled;
    newState.groups[newState.groups.indexOf(group)] = group;

    return newState;
  }
  case SUBSCRIBE_NEWSLETTER_SUCCESS: {
    return { ...state, status: 'pending' };
  }
  case UNSUBSCRIBE_NEWSLETTER_SUCCESS: {
    return { ...state, status: 'unsubscribed' };
  }
  case NEWSLETTER_CONFIRMATION_SUCCESS: {
    return { ...state, status: 'subscribed' };
  }
  case REFRESH_SESSION_FAILURE:
  case LOGOUT:
    return initialState;
  default:
    return state;
  }
};

export default newsletter;
