import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import SetEmail from '../SetEmail/setEmail';
import { loading } from '../../../../utilities/loading';
import DateOfBirth from '../../../authentication/DateOfBirth';
import Country from '../../../authentication/Country';
import NotificationsBase from '../../notifications/Base/base';

class EditAccount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      accountDetails: this.props.accountDetails,
      redirectToSettings: false,
      errorMessage: '',
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDobChange = this.handleDobChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const name = event.target.name;
    const value = event.target.value;

    this.setState(prevState => ({
      accountDetails: Object.assign({}, prevState.accountDetails, {
        [name]: value,
      }),
    }));
  }

  handleDobChange(dateOfBirth) {
    this.setState(prevState => ({
      accountDetails: {
        ...prevState.accountDetails,
        dateOfBirth,
      },
    }));
  }

  async handleSubmit(event) {
    event.preventDefault();
    loading.start();

    try {
      await this.props.requestAccountDetailsUpdate(this.state.accountDetails);
      this.setState({
        redirectToSettings: true,
      });
    } catch (e) {
      if (e === 'ua') {
        this.setState({
          errorMessage: 'You need to be over 16 years old to have an account.',
        });
      } else {
        this.setState({
          errorMessage: 'Unable to update account details. Please try again.',
        });
      }
    } finally {
      loading.end();
    }
  }

  render() {
    if (this.state.redirectToSettings) {
      return <Redirect to="/settings" />;
    }

    return (
      <div>
        <NotificationsBase notificationType={['Settings']} />
        <div id="editProfile">
          <div>
            <h2>Edit Profile</h2>
            <form className="accountDetails" onSubmit={this.handleSubmit}>
              <div>
                <div className="darkContentBlock borderBottom">
                  <label className="form-group" htmlFor="email">
                    <div>First Name</div>
                    <input
                      field="firstName"
                      type="text"
                      name="firstName"
                      onChange={this.handleInputChange}
                      className="form-control"
                      value={this.state.accountDetails.firstName}
                    />
                  </label>

                  <label className="form-group" htmlFor="lastName">
                    <div>Last Name</div>
                    <input
                      field="lastName"
                      type="text"
                      name="lastName"
                      onChange={this.handleInputChange}
                      className="form-control"
                      value={this.state.accountDetails.lastName}
                    />
                  </label>

                  <label className="form-group" htmlFor="addressLine1">
                    <div>Address Line 1</div>
                    <input
                      field="addressLine1"
                      type="text"
                      name="addressLine1"
                      onChange={this.handleInputChange}
                      className="form-control"
                      value={this.state.accountDetails.addressLine1}
                    />
                  </label>

                  <label className="form-group" htmlFor="addressLine2">
                    <div>Address Line 2</div>
                    <input
                      field="addressLine2"
                      type="text"
                      name="addressLine2"
                      onChange={this.handleInputChange}
                      className="form-control"
                      value={this.state.accountDetails.addressLine2}
                    />
                  </label>

                  <label className="form-group" htmlFor="city">
                    <div>City</div>
                    <input
                      field="city"
                      type="text"
                      name="city"
                      onChange={this.handleInputChange}
                      className="form-control"
                      value={this.state.accountDetails.city}
                    />
                  </label>

                  <label className="form-group" htmlFor="zipCode">
                    <div>Postal Code</div>
                    <input
                      field="zipCode"
                      type="text"
                      name="zipCode"
                      onChange={this.handleInputChange}
                      className="form-control"
                      value={this.state.accountDetails.zipCode}
                    />
                  </label>

                  <Country
                    handleInputChange={this.handleInputChange}
                    country={this.state.accountDetails.country}
                    state={this.state.accountDetails.state}
                  />

                  <DateOfBirth
                    // TODO validate DOB
                    updateOnParent={this.handleDobChange}
                    date={this.state.accountDetails.dateOfBirth}
                    borderStyle={
                      this.state.errorMessage ===
                      'You need to be over 16 years old to have an account.'
                        ? { border: '1px solid #fe0642' }
                        : { border: '1px solid #adadad' }
                    }
                  />
                </div>
                <div className="darkContentBlock">
                  <input type="submit" className="button" value="Save Profile" />
                </div>
              </div>
            </form>
          </div>
          <SetEmail {...this.props} />
        </div>
      </div>
    );
  }
}

EditAccount.propTypes = {
  requestAccountDetailsUpdate: PropTypes.func.isRequired,
  accountDetails: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    dateOfBirth: PropTypes.string,
    phoneNumber: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
  }),
};

EditAccount.defaultProps = {
  accountDetails: PropTypes.shape({
    firstName: null,
    lastName: null,
    addressLine1: null,
    addressLine2: null,
    city: null,
    state: null,
    country: null,
    language: null,
  }),
};

export default EditAccount;
