import React from 'react';
import PropTypes from 'prop-types';

const FormFeedback = props => {
  if (props.feedback !== '') {
    return <div className="formFeedback">{props.feedback}</div>;
  }
  return null;
};

FormFeedback.propTypes = {
  feedback: PropTypes.string,
};

export default FormFeedback;
