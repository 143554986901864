import { connect } from 'react-redux';
import View from './activateButton.view.jsx';
import { activateGameKeyRequest } from '../../../../../../actions/authentication';
import { appendNotification } from '../../../../../../actions/notifications';

const mapStateToProps = state => ({
  userId: state.authentication.user.userId,
  isSteamConnected: state.authentication.isSteamConnected,
  isSteamExpired: state.authentication.isSteamExpired,
});

const mapDispatchToProps = {
  activateGameKeyRequest,
  appendNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
