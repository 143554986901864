import React from 'react';
import PropTypes from 'prop-types';
import { withLoadingAnimation } from '../../../../utilities/loading';

class Claim extends React.Component {
  constructor(props) {
    super(props);
    this.handleClaimTwitchDrops = this.handleClaimTwitchDrops.bind(this);
  }

  componentDidMount() {
    withLoadingAnimation(
      this.props.requestConnections(this.props.userId),
    );
  }

  handleClaimTwitchDrops(event) {
    event.preventDefault();
    this.props.requestTwitchDrops();
  }

  render() {
    if (this.props.isTwitchConnected) {
      return (
        <div>
          <h2>Twitch Drops</h2>
          <div className="summary darkContentBlock borderBottom">
            <div className="redeemCodeNotice">
              You have to claim drops on Twitch before you can claim them to your Paradox account here.
            </div>
          </div>
          <div className="darkContentBlock center">
            <button
              className="button"
              onClick={this.handleClaimTwitchDrops}
              data-test="claim-twitch-drops"
            >
              Claim drops
            </button>
          </div>
        </div>
      );
    }
    return (
      <div>
        <h2>Twitch Drops</h2>
        <div className="summary darkContentBlock borderBottom">
          <div className="redeemCodeNotice">
            You need to connect to Twitch to be able to claim Drops.
          </div>
        </div>
        <div className="darkContentBlock center">
          <a href={this.props.twitchUrl} className="button-outline" data-test="connect-twitch-account">
            Link account
          </a>
        </div>
      </div>
    );
  }
}

Claim.propTypes = {
  userId: PropTypes.string.isRequired,
  twitchUrl: PropTypes.string.isRequired,
  isTwitchConnected: PropTypes.bool.isRequired,
  requestTwitchDrops: PropTypes.func.isRequired,
  requestConnections: PropTypes.func.isRequired,
};

export default Claim;
