import React from 'react';
import PropTypes from 'prop-types';

import osWindows from '../../../../public/images/icons/os-windows.svg';
import osLinux from '../../../../public/images/icons/os-linux.svg';
import osMacOs from '../../../../public/images/icons/os-macos.svg';
import platformSteam from '../../../../public/images/icons/platform-steam.svg';
import platformParadoxLauncher from '../../../../public/images/icons/platform-paradoxLauncher.svg';

const OsPlatformSupport = ({ operatingSystems, platforms }) => {
  const operatingSystemsMap = {
    windows: <img src={osWindows} alt="Windows" key="Windows" />,
    linux: <img src={osLinux} alt="Linux" key="Linux" />,
    mac: <img src={osMacOs} alt="Mac OS" key="Mac OS" />,
  };

  const platformsMap = {
    steam: <img src={platformSteam} alt="Steam" key="Steam" />,
    paradoxLauncher: (
      <img src={platformParadoxLauncher} alt="Paradox Launcher" key="Paradox Launcher" />
    ),
  };

  return (
    <div className="support">
      {operatingSystems.length ? (
        <div className="operatingSystems">
          {operatingSystems.map(os => operatingSystemsMap[os])}
        </div>
      ) : null}
      {platforms.length ? (
        <div className="platforms">{platforms.map(platform => platformsMap[platform])}</div>
      ) : null}
    </div>
  );
};

OsPlatformSupport.propTypes = {
  operatingSystems: PropTypes.arrayOf(PropTypes.string).isRequired,
  platforms: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default OsPlatformSupport;
