import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import View from './headers.view.jsx';
import { logoutRequest } from '../../../actions/authentication';

const mapStateToProps = state => ({
  email: state.authentication.user.email,
  firstName: state.authentication.user.accountDetails.firstName,
  isSignedIn: state.authentication.isSignedIn,
});

const mapDispatchToProps = {
  logoutRequest,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View));
