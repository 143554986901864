import React from 'react';
import PropTypes from 'prop-types';
import steamIcon from '../../../../../public/images/icons/steam.svg';

class SteamAccount extends React.Component {
  constructor(props) {
    super(props);
    this.handleDisconnect = this.handleDisconnect.bind(this);
  }

  handleDisconnect(event) {
    event.preventDefault();
    this.props.requestSteamDisconnect(this.props.userId);
  }

  render() {
    if (this.props.isSteamConnected) {
      return (
        <div id="thirdPartyAccount">
          <div className="darkContentBlock">
            <img className="steamImg" src={steamIcon} alt="Steam icon" />
            <div>
              <div className="status">Linked</div>
            </div>
            <button
              className="button-outline red"
              onClick={this.handleDisconnect}
              data-test="disconnect-steam-account"
            >
              Unlink
            </button>
          </div>
        </div>
      );
    }

    return (
      <div id="thirdPartyAccount">
        <div className="darkContentBlock">
          <img src={steamIcon} className="link-thirdparty-img steamImg" alt="Steam icon" />
          <div className="status">
            Link your Steam account.
          </div>
          <a href={this.props.steamUrl} className="button-outline" data-test="connect-steam-account">
            Link account
          </a>
        </div>
      </div>
    );
  }
}

SteamAccount.propTypes = {
  steamUrl: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  isSteamConnected: PropTypes.bool.isRequired,
  isSteamConnectionFetched: PropTypes.bool.isRequired,
  requestSteamDisconnect: PropTypes.func.isRequired,
};

export default SteamAccount;
