import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import View from './signUp.view.jsx';
import { signupRequest } from '../../../actions/authentication';

const mapStateToProps = state => ({
  user: state.authentication.user,
  errorTitle: state.authentication.errorTitle || '',
});

const mapDispatchToProps = {
  signupRequest,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View));
