import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { loading } from '../../../utilities/loading';
import { resetPasswordEmailRequest } from '../../../actions/authentication';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      requestSent: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      [name]: value,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();

    try {
      loading.start();
      await this.props.resetPasswordEmailRequest(this.state.email);
      this.setState({
        errorTitle: null,
      });

      this.setState({ requestSent: true });
    } catch (e) {
      this.setState({
        errorTitle:
          'Could not contact the server to request a password reset. Please try again later.',
      });
    } finally {
      loading.end();
    }
  }

  render() {
    if (this.state.requestSent) {
      return (
        <form id="forgotPassword" onSubmit={this.handleSubmit}>
          <div>
            <h3>Request to reset your password received</h3>
            <div className="content">
              Please check your inbox for a confirmation email, and follow the link to reset
              password.
            </div>
          </div>
          <div className="footer">
            <Link to="/login" className="button-outline">
              Back to login
            </Link>
          </div>
        </form>
      );
    }

    return (
      <form id="forgotPassword" onSubmit={this.handleSubmit}>
        <div>
          <h3>Forgot your password?</h3>
          <div className="content">
            Enter the email address you’ve used to sign up, and we’ll send you instructions on how
            to reset your password.
          </div>
          <label htmlFor="email">
            <input
              id="email"
              className="form-control"
              type="email"
              name="email"
              required
              placeholder="Your email address"
              onChange={this.handleInputChange}
            />
          </label>
        </div>
        {this.state.errorTitle && (
          <div style={{ color: 'red', marginBottom: '20px' }}>{this.state.errorTitle}</div>
        )}
        <div className="footer">
          <Link to="/login" className="button-outline">
            Back to login
          </Link>
          <input type="submit" value="Submit" className="button pull-right" />
        </div>
      </form>
    );
  }
}

ResetPassword.propTypes = {
  resetPasswordEmailRequest: PropTypes.func,
};

const mapStateToProps = ({ authentication }) => ({
  errorTitle: authentication.errorTitle,
  requestSent: authentication.requestSent,
});

const mapDispatchToProps = {
  resetPasswordEmailRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
