import React from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import base64Url from 'base64url';
import ConfirmationMessage from '../../ConfirmationMessage';
import { getTwitchRedirectUri } from "../../../utilities/Utilities";
import styles from './twitchConnect.scss';

class TwitchConnect extends React.Component {
  constructor(props) {
    super(props);
    this.state = { requestSent: false, errorTitle: null, returnUrl: '/settings', returnUrlLabel: 'Settings' };
  }

  async componentDidMount() {
    const parsedUrl = this.parsedUrl;

    if (parsedUrl && 'state' in parsedUrl) {
      try {
        const decodedState = base64Url.decode(parsedUrl.state);
        const parsedState = JSON.parse(decodedState);
        if (parsedState.redirect && parsedState.redirect !== 'settings') {
          const newState = { returnUrl: `/${parsedState.redirect}`, returnUrlLabel: 'Paradox Accounts' };
          if (parsedState.redirect === 'redeem') {
            newState.returnUrlLabel = 'Codes and Drops';
          }
          this.setState(newState);
        }
        if (parsedState.state !== this.props.twitchState) {
          this.setState({ requestSent: true, errorTitle: 'An error occurred' });
          return;
        }
      } catch (error) {
        this.setState({ requestSent: true, errorTitle: 'An error occurred' });
        return;
      }
    } else {
      this.setState({ requestSent: true, errorTitle: 'An error occurred' });
      return;
    }

    if (parsedUrl && 'error' in parsedUrl) {
      if (parsedUrl.error === 'access_denied') {
        this.setState({ requestSent: true, errorTitle: 'You cancelled the Twitch account linking process' });
        return;
      }
      this.setState({ requestSent: true, errorTitle: parsedUrl.error_description || 'An error occurred' });
      return;
    }

    if (parsedUrl && 'code' in parsedUrl) {
      try {
        await this.props.twitchConnectRequest(
          parsedUrl.code,
          getTwitchRedirectUri(),
          this.props.twitchNonce
        );
        this.setState({ requestSent: true });
      } catch (error) {
        this.setState({ requestSent: true, errorTitle: error.message });
      }
    }
  }

  get parsedUrl() {
    return queryString.parse(this.props.location.hash || this.props.location.search);
  }

  get linkContent() {
    if (this.state.errorTitle) {
      return (
        <Link to={this.state.returnUrl} className="button-outline">
          Back to {this.state.returnUrlLabel}
        </Link>
      );
    }

    return null;
  }

  get resultContext() {
    if (!this.state.requestSent) {
      return <p className={styles.margin_small}>Linking your Twitch account...</p>;
    }

    if (this.state.errorTitle) {
      return (
        <p className={styles.margin_small}>
          {this.state.errorTitle}.
        </p>
      );
    }

    window.location = this.state.returnUrl;
    return (
      <React.Fragment>
        <p className={styles.margin_small}>
          Your Twitch account is now linked.
        </p>
        <p className={styles.margin_small}>
          Redirecting you back to {this.state.returnUrlLabel}...
        </p>
      </React.Fragment>
    );
  }

  render() {    
    if (this.props.isSignedIn) {
      return (
        <ConfirmationMessage>
          {this.resultContext}
          {this.linkContent}
        </ConfirmationMessage>
      );
    }

    return <Redirect to="/" />;
  }
}

TwitchConnect.propTypes = {
  isSignedIn: PropTypes.bool.isRequired,
  twitchConnectRequest: PropTypes.func.isRequired,
  twitchState: PropTypes.string.isRequired,
  twitchNonce: PropTypes.string.isRequired,
};

export default TwitchConnect;
