import React from 'react';

import Header from '../Header/header';
import Sections from '../Sections/sections';
import Footer from '../Footer';

const Dashboard = () => (
  <div id="dashboard">
    <Header />
    <Sections />
    <Footer />
  </div>
);

export default Dashboard;
