import { connect } from 'react-redux';
import View from './xboxConnect.view.jsx';
import { xboxConnectRequest } from '../../../actions/authentication';

const mapStateToProps = state => ({
  isSignedIn: state.authentication.isSignedIn,
  userId: state.authentication.user.userId,
  errorTitle: state.authentication.user.errorTitle || '',
  xboxState: state.authentication.xboxState,
});

const mapDispatchToProps = {
  xboxConnectRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
