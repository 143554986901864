import React from 'react';
import { Route, NavLink, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import GameAchievements from '../achievements/GameAchievements';
import GameDetails from '../details/GameDetails';
import OsPlatformSupport from '../../OsPlatformSupport';
import Utilities from '../../../../../utilities/Utilities';
import { withLoadingAnimation } from '../../../../../utilities/loading';
import NotificationsBase from '../../../notifications/Base/base';

class Game extends React.Component {
  async componentWillMount() {
    await withLoadingAnimation(this.props.requestGames(), this.props.games);
  }

  componentDidMount() {
    if (this.props.gameInfo.sku) {
      withLoadingAnimation(
        this.props.gameAchievementsRequest(this.props.gameInfo.sku),
        this.achievements,
      );
    }

    withLoadingAnimation(
      this.props.requestSteamConnection(this.props.userId),
      this.props.isSteamConnectionFetched,
    );
  }

  componentWillReceiveProps(nextProps) {
    if (this.achievements.length === 0) {
      if (!this.props.isLoadingAchievements && this.props.gameInfo.sku !== nextProps.gameInfo.sku) {
        withLoadingAnimation(
          nextProps.gameAchievementsRequest(nextProps.gameInfo.sku),
          this.achievements || nextProps.achievements[this.props.gameInfo.sku] || [],
        );
      }
    }
  }

  get achievements() {
    return this.props.achievements[this.props.gameInfo.sku] || [];
  }

  get detailsRoute() {
    if ((this.props.gameInfo.keys || []).length > 0) {
      return (
        <Route
          path={`${this.props.match.url}/details`}
          component={() => (
            <div>
              {(this.props.gameInfo.keys || []).map(key => (
                <GameDetails
                  gameInfo={this.props.gameInfo}
                  gameKey={key}
                  key={key.transaction.transaction_id}
                />
              ))}
            </div>
          )}
        />
      );
    }

    return null;
  }

  get gameTitle() {
    return Utilities.serializeTitle(this.props.gameInfo.title);
  }

  get communityForums() {
    if (this.props.gameInfo.forumUrl) {
      return (
        <a href={this.props.gameInfo.forumUrl} className="button pull-right">
          Community Forums
        </a>
      );
    }

    return null;
  }

  get detailsLink() {
    return (
      <li>
        <NavLink
          to={{
            pathname: `/games/${this.gameTitle}/details`,
          }}
          activeClassName="active"
        >
          Details
        </NavLink>
      </li>
    );
  }

  get achievementsLink() {
    if (this.achievements.length > 0) {
      return (
        <li>
          <NavLink
            to={{
              pathname: `${this.props.match.url}/achievements`,
            }}
            activeClassName="active"
          >
            Achievements
          </NavLink>
        </li>
      );
    }

    return null;
  }

  get achievementsRoute() {
    if (this.achievements.length > 0) {
      return (
        <Route
          path={`${this.props.match.url}/achievements`}
          component={() => (
            <GameAchievements gameInfo={this.props.gameInfo} achievements={this.achievements} />
          )}
        />
      );
    }

    return null;
  }

  render() {
    if (this.props.games.length > 0) {
      return (
        <div>
          <NotificationsBase notificationType={['Game']} />
          <div id="game">
            <Link to="/games" className="breadcrumbs">
              <div className="center">
                <div className="leftArrow">{'<'}</div>
                <div>Back to game list</div>
              </div>
            </Link>

            <div className="titleBackground center">
              <h2>{this.props.gameInfo.title}</h2>
              <OsPlatformSupport
                operatingSystems={this.props.gameInfo.operatingSystems}
                platforms={this.props.gameInfo.platforms}
              />
              {this.communityForums}
            </div>

            <div className="sections">
              <div className="header">
                <ul className="pageCenter">
                  {this.detailsLink}
                  {this.achievementsLink}
                </ul>
              </div>
            </div>

            {this.detailsRoute}
            {this.achievementsRoute}
          </div>
        </div>
      );
    }

    return <span />;
  }
}

Game.propTypes = {
  userId: PropTypes.string.isRequired,
  gameInfo: PropTypes.shape({
    title: PropTypes.string.isRequired,
    platforms: PropTypes.arrayOf(PropTypes.string).isRequired,
    type: PropTypes.string.isRequired,
    sku: PropTypes.string.isRequired,
    keys: PropTypes.array,
    operatingSystems: PropTypes.array.isRequired,
    forumUrl: PropTypes.string,
  }).isRequired,
  requestGames: PropTypes.func.isRequired,
  gameAchievementsRequest: PropTypes.func.isRequired,
  requestSteamConnection: PropTypes.func.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  achievements: PropTypes.object.isRequired,
  isLoadingAchievements: PropTypes.bool.isRequired,
  isSteamConnectionFetched: PropTypes.bool.isRequired,
  games: PropTypes.array.isRequired,
};

export default Game;
