import { connect } from 'react-redux';
import View from './authentication.view.jsx';
import { loginRequest } from '../../../actions/authentication';

const mapStateToProps = ({ authentication }) => ({
  isSignedIn: authentication.isSignedIn,
});

const mapDispatchToProps = {
  loginRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
