import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import AchievementItem from './AchievementItem';
import { ACHIEVEMENTS_PREFIXES } from '../../../../../actions/authentication';

const PER_PAGE = 10;

class GameAchievements extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      offset: 0,
    };

    this.handlePageClick = this.handlePageClick.bind(this);
  }

  get pageCount() {
    return Math.ceil(this.props.achievements.length / PER_PAGE);
  }

  get achievements() {
    return this.props.achievements;
  }

  get unlockedItems() {
    return this.achievements.filter(items => items.userData).length;
  }

  get totalItems() {
    return this.achievements.length;
  }

  handlePageClick(data) {
    const selected = data.selected;
    this.setState({ offset: Math.ceil(selected * PER_PAGE) });

    this.scrollToAchievements();
  }

  scrollToAchievements() {
    const yRelativeToDocument =
      document.querySelector('#gameAchievements .list').getBoundingClientRect().top +
      window.pageYOffset;

    window.scrollTo(0, yRelativeToDocument);
  }

  get gameNamespace() {
    return (
      ACHIEVEMENTS_PREFIXES.find(options => this.props.gameInfo.sku.startsWith(options.prefix)) ||
      {}
    ).namespace;
  }

  get achievementsList() {
    return this.achievements
      .slice(this.state.offset, this.state.offset + PER_PAGE)
      .map(item => (
        <AchievementItem key={item.name} gameNamespace={this.gameNamespace} {...item} />
      ));
  }

  render() {
    return (
      <div id="gameAchievements">
        <div className="grey darkContentBlock">
          You have unlocked
          <span className="highlight">
            {' '}
            {this.unlockedItems} out of {this.totalItems}{' '}
          </span>achievements
        </div>
        <div className="list">
          <div className="listHeader grey center">
            <div>Achievement</div>
            <div className="pull-right">Status</div>
          </div>
          {this.achievementsList}
        </div>
        <ReactPaginate
          previousLabel="Previous Page"
          nextLabel="Next Page"
          breakLabel="..."
          breakClassName="break"
          pageCount={this.pageCount}
          pageRangeDisplayed={4}
          marginPagesDisplayed={1}
          onPageChange={this.handlePageClick}
          containerClassName="pagination darkContentBlock"
          subContainerClassName="pages pagination"
          activeClassName="active"
        />
      </div>
    );
  }
}

GameAchievements.propTypes = {
  gameInfo: PropTypes.shape({
    title: PropTypes.string.isRequired,
    platforms: PropTypes.arrayOf(PropTypes.string).isRequired,
    type: PropTypes.string.isRequired,
    sku: PropTypes.string.isRequired,
  }).isRequired,
  achievements: PropTypes.array.isRequired,
};

export default GameAchievements;
