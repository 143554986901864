import React from 'react';
import PropTypes from 'prop-types';

class CopyText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false,
      timer: false,
    };

    this.timer = false;
    this.copyToClipboard = this.copyToClipboard.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snap) {
    const obj = this;
    if (this.state.copied) {
      setTimeout(() => {
        obj.setState({ copied: false });
      }, 10);
    }
  }

  copyToClipboard(event, a, b) {
    if (window.clipboardData && window.clipboardData.setData) {
      // old IE support
      window.clipboardData.setData('Text', this.props.text);
    } else {
      this.txtRef.select();
      document.execCommand('copy', false, null);
      event.target.focus();
    }

    this.setState({ copied: true });
  }

  render() {
    return (
      <div className="clipboard-wrapper">
        <div className="width-fixer">{this.props.text}</div>

        <div className="clipboard-txt">Copied!</div>
        <input
          type="text"
          className={`clipboard-input bg-darkcontent ${this.state.copied ? 'clipped' : ''}`}
          onClick={this.copyToClipboard}
          readOnly
          title="Copy to clipboard"
          ref={txtRef => (this.txtRef = txtRef)}
          value={this.props.text}
        />
      </div>
    );
  }
}

CopyText.propTypes = {
  text: PropTypes.string.isRequired,
};

export default CopyText;
