const FormValidation = (fieldName, value, props, callback) => {
  const fieldValidationErrors = props.formErrors;
  let emailValidity = props.emailValid;
  let passwordValidity = props.passwordValid;
  let newEmailValidity = props.newEmailValid;
  let ageValidity = props.ageValid;

  switch (fieldName) {
    case 'email':
      emailValidity = value.match(
        /^[a-zA-Z0-9\u4e00-\u9eff.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9\u4e00-\u9eff-]+(\.[a-zA-Z0-9\u4e00-\u9eff-]+)+$/,
      );
      fieldValidationErrors.email = emailValidity ? '' : 'Email address is not valid.';
      break;
    case 'newEmail':
      newEmailValidity = value.match(
        /^[a-zA-Z0-9\u4e00-\u9eff.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9\u4e00-\u9eff-]+(\.[a-zA-Z0-9\u4e00-\u9eff-]+)+$/,
      );
      fieldValidationErrors.newEmail = newEmailValidity ? '' : 'Email address is not valid.';
      break;
    case 'password':
      passwordValidity = value.length >= 6;
      if (passwordValidity) {
        passwordValidity = value.length <= 128;
        fieldValidationErrors.password = passwordValidity ? '' : 'Password is too long.';
      } else {
        fieldValidationErrors.password = 'Password is too short.';
      }
      break;
    case 'terms':
      fieldValidationErrors.terms = value
        ? ''
        : 'You must accept the Terms of Use to create an account.';
      break;
    case 'privacy':
      fieldValidationErrors.privacy = value
        ? ''
        : 'You must have read and understood the Privacy Policy to create an account.';
      break;
    case 'age':
      ageValidity = value.match(/\d{4}-\d{2}-\d{2}/);
      // TODO check if >= 16 years old
      fieldValidationErrors.age = value ? 'Old enough' : 'Not old enough';
      break;
    default:
      break;
  }

  const validity = {
    fieldValidationErrors,
    emailValidity,
    passwordValidity,
    ageValidity,
    newEmailValidity,
  };
  callback(validity);
};

export default FormValidation;
