import { connect } from 'react-redux';
import View from './unverifiedEmail.view.jsx';
import { removeNotification } from '../../../../actions/notifications';
import { confirmationEmailRequest } from '../../../../actions/authentication';

const mapStateToProps = state => ({
  userId: state.authentication.user.userId,
  compState: state.notifications.components.find(item => item.name === 'UnverifiedEmail').state,
});

const mapDispatchToProps = {
  confirmationEmailRequest,
  removeNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
