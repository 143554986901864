import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import logo from '../../../../public/images/logo.svg';
import globe from '../../../../public/images/globe.svg';
import Utilities from '../../../utilities/Utilities';


class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
      accountMenuActive: false,
    };

    this.handleSignOut = this.handleSignOut.bind(this);
  }

  toggleActive() {
    this.setState(currentState => ({
      active: !currentState.active,
    }));
  }

  get username() {
    if (!this.props.firstName || this.props.firstName === '-') {
      return this.props.email;
    }

    return this.props.firstName;
  }

  toggleAccountMenuActive() {
    this.setState(currentState => ({
      accountMenuActive: !currentState.accountMenuActive,
    }));
  }

  async handleSignOut() {
    await this.props.logoutRequest(Utilities.sessionCookie);

    return this.props.history.push('/login');
  }

  render() {
    let dropdown = '';
    let dropdownMobile = '';

    if (this.state.accountMenuActive) {
      dropdown = (
        <div className="dropdown hideMobile">
          <div className="header">Your Account</div>
          <a role="link" className="links" onClick={this.handleSignOut} tabIndex="0">
            Sign Out
          </a>
        </div>
      );

      dropdownMobile = (
        <ul className="hideDesktop">
          <li>
            <a role="link" onClick={this.handleSignOut} tabIndex="0">
              Sign Out
            </a>
          </li>
        </ul>
      );
    }

    return (
      <div id="header" className={this.state.active ? 'active' : ''}>
        <div className="pageCenter">
          <ul className="headerLinks">
            <li className="logo">
              <Link to="/games">
                <img src={logo} alt="Paradox Interactive logo" />
              </Link>
            </li>
            <li className="pull-right">
              <a role={'button'} className="menu" onClick={() => this.toggleActive()} tabIndex="0">
                <div className="inner" />
              </a>
            </li>
          </ul>
          <nav>
            <a role="link" className="menuClose" onClick={() => this.toggleActive()} tabIndex="0">
              <div className="inner" />
            </a>
            <ul className="headerLinks mainLinks">
              <li>
                <a href="https://www.paradoxplaza.com">Games</a>
              </li>
              <li>
                <a href={process.env.FORUM_URL}>Forum</a>
              </li>
              <li>
                <a href="https://paradoxwikis.com/">Wikis</a>
              </li>
            </ul>
            <div className="pull-right">
              <ul className="accountMenu pull-right">
                <li>
                  <a
                    role="link"
                    className="name"
                    onClick={() => this.toggleAccountMenuActive()}
                    tabIndex="0"
                  >
                    <div className="circle" />
                    <div>{this.username}</div>
                  </a>
                  {dropdown}
                  {dropdownMobile}
                </li>
              </ul>
              <div className="languagePicker">
                <div>English</div>
                <img src={globe} alt="Globe icon" />
              </div>
            </div>
          </nav>
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  logoutRequest: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  firstName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

export default Header;
