import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import Login from '../Login/login';
import SignUp from '../SignUp/signUp';
import Dashboard from '../../dashboard/Dashboard/index';
import ResetPassword from '../ResetPassword/index';
import SetPassword from '../SetPassword/setPassword';
import Logo from './Logo';
import Footer from './Footer';

import globe from '../../../../public/images/globe.svg';

const Authentication = ({ isSignedIn, loginRequest }) => {
  if (!isSignedIn) {
    return (
      <div id="authentication">
        <Route exact path="/" render={() => <Redirect to="/login" />} />
        <Route exact path="/undefined" render={() => <Redirect to="/login" />} />
        <div className="languagePicker">
          <div>English</div>
          <img src={globe} alt="Globe icon" />
        </div>
        <div className="content">
          <Logo />
          <div className="formContainer">
            <Switch>
              <Route
                path="/signup"
                component={props => <SignUp {...props} handleSignIn={loginRequest} />}
              />
              <Route path="/reset/:token" component={props => <SetPassword {...props} />} />
              <Route path="/reset-password" component={props => <ResetPassword {...props} />} />
              <Route
                path="/"
                component={props => <Login {...props} handleSignIn={loginRequest} />}
              />
            </Switch>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
  return (
    <div>
      <Switch>
        <Route exact path="/reset/:token" component={props => <SetPassword {...props} />} />
        <Route path="/(login|signup|undefined)" render={() => <Redirect to="/games" />} />
        <Route path="/reset-password" component={() => <Redirect to="/settings" />}  />} />
        <Route exact path="/" render={() => <Redirect to="/games" />} />
        <Dashboard />
      </Switch>
    </div>
  );
};

Authentication.propTypes = {
  loginRequest: PropTypes.func.isRequired,
  isSignedIn: PropTypes.bool.isRequired,
};

export default Authentication;
