import React from 'react';
import Newsletter from './Newsletter/newsletter';
import YourProfile from './YourProfile/yourProfile';
import ThirdPartyConnections from './ThirdPartyConnections/thirdPartyConnections';
import NotificationsBase from '../notifications/Base/base';

const Settings = props => (
  <div>
    <NotificationsBase notificationType={['Settings']} />
    <div id="settings">
      <div>
        <YourProfile {...props} />
        <ThirdPartyConnections {...props} />
      </div>
      <div>
        <Newsletter />
      </div>
    </div>
  </div>
);

export default Settings;
