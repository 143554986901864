import React from 'react';
import PropTypes from 'prop-types';
import { withLoadingAnimation } from '../../../../utilities/loading';
import NotificationsBase from '../../notifications/Base/base';

import GameItem from '../GameItem';
import OldGameKeys from './OldGameKeys';

class Games extends React.Component {
  componentDidMount() {
    withLoadingAnimation(
      this.props.requestGames(),
      this.props.games,
      this.props.requestSteamConnection(this.props.userId),
    );
  }

  render() {
    return (
      <div>
        <NotificationsBase notificationType={['Game']} />
        <div id="yourGames">
          <h2>Your Games</h2>
          <ul className="gameList">
            {this.props.games.map(game => (
              <GameItem key={game.sku} {...game} userId={this.props.userId} />
            ))}
          </ul>
          <OldGameKeys />
        </div>
      </div>
    );
  }
}

Games.propTypes = {
  isSteamConnected: PropTypes.bool.isRequired,
  requestGames: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  games: PropTypes.array.isRequired,
  requestSteamConnection: PropTypes.func.isRequired,
};

export default Games;
