import React from 'react';
import PropTypes from 'prop-types';
import SteamAccount from '../SteamAccount/steamAccount';
import PsnAccount from '../PsnAccount/psnAccount';
import XboxAccount from '../XboxAccount/xboxAccount';
import TwitchAccount from '../TwitchAccount/twitchAccount';
import EpicAccount from '../EpicAccount/epicAccount';
import { withLoadingAnimation } from '../../../../utilities/loading';

class ThirdPartyConnections extends React.Component {
  componentDidMount() {
    withLoadingAnimation(
      this.props.requestConnections(this.props.userId),
      this.props.isSteamConnectionFetched,
      this.props.isPsnConnectionFetched,
      this.props.isXboxConnectionFetched,
      this.props.isTwitchConnectionFetched,
      this.props.isEpicConnectionFetched,
    );
  }

  get headerHtml() {
    return <h2>Account Connections</h2>;
  }

  render() {
    return (
      <div>
        <h2>Account Linking</h2>
        <div>
          <SteamAccount {...this.props} />
          <PsnAccount {...this.props} />
          <XboxAccount {...this.props} />
          <TwitchAccount {...this.props} />
          <EpicAccount {...this.props} />
        </div>
      </div>
    );
  }
}

ThirdPartyConnections.propTypes = {
  userId: PropTypes.string.isRequired,
  isSteamConnected: PropTypes.bool.isRequired,
  isSteamConnectionFetched: PropTypes.bool.isRequired,
  isPsnConnected: PropTypes.bool.isRequired,
  isPsnConnectionFetched: PropTypes.bool.isRequired,
  isXboxConnected: PropTypes.bool.isRequired,
  isXboxConnectionFetched: PropTypes.bool.isRequired,
  isTwitchConnected: PropTypes.bool.isRequired,
  isTwitchConnectionFetched: PropTypes.bool.isRequired,
  isEpicConnected: PropTypes.bool.isRequired,
  isEpicConnectionFetched: PropTypes.bool.isRequired,
  requestConnections: PropTypes.func.isRequired,
};

export default ThirdPartyConnections;
