import React from 'react';
import logoImg from '../../../../public/images/logo.svg';

const Logo = () => (
  <div className="welcome">
    <img src={logoImg} alt="Paradox logo" />
    Welcome to Paradox
  </div>
);

export default Logo;
