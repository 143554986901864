// TODO move this functionality to redux
// TODO fix a bug when `loading.start` is called from several places and then
// one of the `loading.stop` methods stops loader prematurely without waiting for other callers

export const loading = {
  start: () => {
    const $loading = document.querySelector('.loading');

    if ($loading) {
      $loading.style.display = 'flex';
    }
  },

  end: () => {
    const $loading = document.querySelector('.loading');

    if ($loading) {
      $loading.style.display = 'none';
    }
  },
};

export const withLoadingAnimation = async(promise, data = null) => {
  let result;

  if (
    data === undefined ||
    data === false ||
    data === null ||
    (data instanceof Array && data.length === 0)
  ) {
    loading.start();
    result = await promise;
    loading.end();
  } else {
    result = await promise;
  }

  return result;
};

export default loading;
