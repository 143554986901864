import sha from 'sha.js';
import { logError } from '../utilities/Utilities';

const INVALID_SESSION = 'session-does-not-exist';
const getCoreUrl = url => {
  const env = process.env.API_ENV;
  if (env === 'local') {
    // to run scalar locally:
    // return `http://localhost:8080/${url}`;
    return `/proxy-test/${url}`;
  }

  if (env === 'production') {
    return `https://api.paradox-interactive.com/${url}`;
  }

  return `https://${env}-api.paradox-interactive.com/${url}`;
};

const defaultHeaders = token => ({
  'User-Agent': 'octagon/0.0',
  'Content-Type': 'application/json',
  Authorization: JSON.stringify({
    session: { token },
  }),
});

export const hawkHeader = (username, password) =>
  JSON.stringify({
    hawkb64: {
      email: Buffer.from(username).toString('base64'),
      'sha256(password+salt)': sha('sha256')
        .update(`${password}64DC4DC6508F4A9D823179D4D99DB4EE`, 'utf8')
        .digest('hex'),
    },
  });

export const requestFromCore = ({
  httpMethod = 'GET',
  url = '',
  data = {},
  headers = {},
  token = null,
} = {}) => {
  let mergedHeaders = Object.assign(defaultHeaders(token), headers);
  // remove null headers
  mergedHeaders = Object.keys(mergedHeaders)
    .filter(f => mergedHeaders[f] !== null)
    .reduce((r, i) => {
      r[i] = mergedHeaders[i]; // eslint-disable-line no-param-reassign
      return r;
    }, {});

  return fetch(getCoreUrl(url), {
    method: httpMethod,
    headers: mergedHeaders,
    body: httpMethod === 'GET' ? undefined : JSON.stringify(data),
  });
};

export const respondWith = response => {
  if (!response.result || response.result !== 'OK') {
    if (response.errorCode === INVALID_SESSION) {
      window.location.reload(); // logout user
    } else {
      if (response.error) {
        logError('Response error (respondWith)', response.error);
        throw response.error;
      } else {
        const error = new Error(
          (response.exception ? `${response.exception} ` : '') +
            (response.errorCode ? `${response.errorCode} ` : '') +
            (response.errorMessage ? `${response.errorMessage} ` : '') +
            (response.detail ? `${response.detail}` : ''),
        );
        Object.assign(error, response);
        logError('Response error (respondWith)', error.message);
        throw error;
      }
    }
  }

  return response;
};
