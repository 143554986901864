import React from 'react';
import PropTypes from 'prop-types';
import steamIcon from '../../../../../public/images/icons/steam.svg';

class Generic extends React.Component {
  constructor(props) {
    super(props);

    this.removeNotification = this.removeNotification.bind(this);
  }

  removeNotification() {
    this.props.removeNotification({ componentId: this.props.options.id });
  }

  render() {
    if (this.props.options.name === 'ConnectSteam') {
      return (
        <div>
          <img src={steamIcon} alt="Steam icon" />
          <div>
            <h2>To be able to activate game keys, please link your Steam account.</h2>
          </div>
          <div className="buttonContainer">
            <a href={this.props.steamUrl} className="button" data-test="connect-steam-account">
              Link
            </a>
            <a className="close" onClick={this.removeNotification} role="button" tabIndex="0">
              <div className="inner" />
            </a>
          </div>
        </div>
      );
    }
    return (
      <div>
        <div>{this.props.options.text}</div>
        <div className="buttonContainer">
          <a className="close" onClick={this.removeNotification} role="button" tabIndex="0">
            <div className="inner" />
          </a>
        </div>
      </div>
    );
  }
}

Generic.propTypes = {
  removeNotification: PropTypes.func.isRequired,
  options: PropTypes.shape({
    id: PropTypes.number.isRequired,
    text: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  steamUrl: PropTypes.string.isRequired,
};

export default Generic;
