import React from 'react';

class OldGameKeys extends React.Component {
  constructor(props) {
    super(props);
    const state = {
      hidden: true,
    };

    state.oldGames = [
      {
        name: 'BATTLETECH',
        key: 'CryOfTheBanshee',
      },
      {
        name: 'Crusader Kings 2',
        key: 'CK20987li9e234',
      },
      {
        name: 'Europa Universalis IV',
        key: 'EU4R2JvakDKTJ8Cn',
      },
      {
        name: 'Hearts of Iron IV',
        key: '2DeZp2xuzsUmLhhP',
      },
      {
        name: 'Stellaris',
        key: 'oldstellaris',
      },
    ].map(game => (
      <div key={game.key} className="oldGame">
        <div>
          <b>{game.name}</b>
        </div>
        <div className="key">{game.key}</div>
      </div>
    ));

    this.state = state;
    this.toggleOldGames = this.toggleOldGames.bind(this);
  }

  toggleOldGames() {
    this.setState(prevState => ({ hidden: !prevState.hidden }));
  }

  render() {
    let containerClasses = 'oldGames';
    let toggleText = 'Hide';

    if (this.state.hidden) {
      containerClasses += ' hidden';
      toggleText = 'Show';
    }

    return (
      <div>
        <div className="oldGamesTitle">
          <h2>Access earlier versions</h2>
          <div>
            <button data-test="activateButton" className="button" onClick={this.toggleOldGames}>
              {toggleText}
            </button>
          </div>
        </div>

        <div className={containerClasses}>
          <div>
            Use the below passwords to unlock earlier versions of your games.&nbsp;
            <a
              className="button-outline"
              href="https://forum.paradoxplaza.com/forum/index.php?threads/changes-to-playing-previous-versions-of-pds-titles.1120883/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Read more
            </a>
          </div>
          <div className="darkContentBlock oldGameKeysContainer">{this.state.oldGames}</div>
        </div>
      </div>
    );
  }
}

export default OldGameKeys;
