import React from 'react';

const Footer = () => (
  <div className="footer">
    © Paradox Interactive. Trademarks belong to their respective owners. All rights reserved.
    <br />
    <a href="http://legal.paradoxplaza.com/eula">User Agreement</a>
  </div>
);

export default Footer;
