import { connect } from 'react-redux';
import View from './games.view.jsx';
import { requestGames, requestConnections } from '../../../../actions/authentication';

const mapStateToProps = state => ({
  userId: state.authentication.user.userId,
  games: state.authentication.games,
  isSteamConnected: state.authentication.isSteamConnected,
});

const mapDispatchToProps = {
  requestGames,
  requestSteamConnection: requestConnections,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
