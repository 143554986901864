import React from 'react';
import PropTypes from 'prop-types';
import { Route, NavLink } from 'react-router-dom';

import GamesIndex from '../games/Games/games';
import Game from '../games/game/Game/game';
import Settings from '../settings/Settings';
import EditProfile from '../settings/EditProfile/editProfile';
import Downloads from '../downloads/Downloads/downloads';
import RedeemAndClaim from '../RedeemAndClaim/redeemAndClaim';
import NotificationsBase from '../notifications/Base/base';
import Orders from '../Orders/orders';

const Sections = ({ user }) => (
  <div className="sections">
    <div className="header">
      <ul className="pageCenter">
        <li>
          <NavLink to="/games" activeClassName="active">
            Games
          </NavLink>
        </li>
        <li>
          <NavLink to="/orders" activeClassName="active">
            Orders
          </NavLink>
        </li>
        <li>
          <NavLink to="/downloads" activeClassName="active">
            Downloads
          </NavLink>
        </li>
        <li>
          <NavLink to="/redeem" activeClassName="active">
            Codes and Drops
          </NavLink>
        </li>
        <li>
          <NavLink to="/settings" activeClassName="active">
            Settings
          </NavLink>
        </li>
      </ul>
    </div>
    <div className="sectionsContent">
      <div className="pageCenter">
        <NotificationsBase
          notificationType={[
            'UnverifiedEmail',
            'ExpiredSteamConnection',
            'ConnectSteam',
            'RefreshTwitchConnection',
            'BetaAccounts',
          ]}
        />
        <Route
          exact
          path="/games"
          component={props => (
            <GamesIndex
              {...props}
              accountDetails={user.accountDetails}
              email={user.accountDetails.email}
              userId={user.session.userid}
              id="games"
            />
          )}
        />
        <Route exact path="/orders" component={props => <Orders {...props} />} />
        <Route
          exact
          path="/downloads"
          component={props => (
            <Downloads
              {...props}
              accountDetails={user.accountDetails}
              email={user.email}
              userId={user.session.userid}
            />
          )}
        />
        <Route exact path="/redeem" component={props => <RedeemAndClaim {...props} />} />
        <Route exact path="/settings" component={props => <Settings {...props} />} />
        <Route path="/settings/edit-profile" component={() => <EditProfile />} />
        <Route path="/games/:game" component={props => <Game {...props} />} />
      </div>
    </div>
  </div>
);

Sections.propTypes = {
  user: PropTypes.shape({
    session: PropTypes.object.isRequired,
    email: PropTypes.string.isRequired,
    accountDetails: PropTypes.object.isRequired,
  }).isRequired,
};

export default Sections;
