import { connect } from 'react-redux';
import View from './epicAccount.view.jsx';
import { requestEpicDisconnect } from '../../../../actions/authentication';
import { getEpicUrl } from '../../../../utilities/Utilities';

const mapStateToProps = state => ({
  epicUrl: getEpicUrl(state.authentication.epicState, state.authentication.epicNonce),
  userId: state.authentication.user.userId,
  isEpicConnected: state.authentication.isEpicConnected,
  isEpicConnectionFetched: state.authentication.isEpicConnectionFetched,
});

const mapDispatchToProps = {
  requestEpicDisconnect,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View);
