import React from 'react';
import PropTypes from 'prop-types';
import ActivateButton from './ActivateButton/activateButton';

import Utilities from '../../../../../utilities/Utilities';

class GameDetails extends React.Component {
  get purchaseDate() {
    const timestamp = ((this.props.gameKey || {}).transaction || {}).timestamp;

    if (timestamp) {
      return Utilities.formatDate(timestamp);
    }

    return '';
  }

  get steamKey() {
    if (!this.props.gameKey.code) {
      return '';
    }

    if (this.props.gameKey.code === 'hidden') {
      return 'Link your Steam account to activate';
    }

    return this.props.gameKey.code;
  }

  render() {
    return (
      <div className="keyList darkContentBlock">
        <div className="center">
          <div>
            <h3>Date of Purchase</h3>
            {this.purchaseDate}
          </div>
          <div>
            <h3>Steam key</h3>
            {this.steamKey}
          </div>
          <div className="pull-right">
            <ActivateButton gameKey={this.props.gameKey || {}} gameSku={this.props.gameInfo.sku} />
          </div>
        </div>
      </div>
    );
  }
}

GameDetails.propTypes = {
  gameKey: PropTypes.shape({
    code: PropTypes.string.isRequired,
  }).isRequired,
  gameInfo: PropTypes.shape({
    title: PropTypes.string.isRequired,
    platforms: PropTypes.arrayOf(PropTypes.string).isRequired,
    type: PropTypes.string.isRequired,
    sku: PropTypes.string.isRequired,
  }).isRequired,
};

export default GameDetails;
