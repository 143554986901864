import { respondWith } from '../utils';

const steamKey = async key => {
  const resp = await fetch(`/api/redeem/steamkey?key=${key}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
    method: 'GET',
  });

  return respondWith(await resp.json());
};

const claim = async(universe, userid, code, recaptchaResponse) => {
  const resp = await fetch(
    `/api/redeem/v2/claim?universe=${universe}&userid=${userid}&code=${code}&recaptchaResponse=${recaptchaResponse}`,
    {
      method: 'GET',
      credentials: 'same-origin',
    },
  );

  return respondWith(await resp.json());
};

export default {
  claim,
  steamKey,
};
