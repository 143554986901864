import { connect } from 'react-redux';
import View from './newsletter.view.jsx';
import {
  newsletterStatusRequest,
  subscribeNewsletterRequest,
  unsubscribeNewsletterRequest,
} from '../../../../actions/newsletter';

const mapStateToProps = state => ({
  newsletter: state.newsletter,
  newsletterGroups: state.newsletter.groups,
  notifications: state.notifications,
});

const mapDispatchToProps = {
  newsletterStatusRequest,
  subscribeNewsletterRequest,
  unsubscribeNewsletterRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
