import React from 'react';
import PropTypes from 'prop-types';
import twitchIcon from '../../../../../public/images/icons/glitch-icon.svg';

class TwitchAccount extends React.Component {
  constructor(props) {
    super(props);
    this.handleDisconnect = this.handleDisconnect.bind(this);
  }

  handleDisconnect(event) {
    event.preventDefault();
    this.props.requestTwitchDisconnect(this.props.userId);
  }

  get headerHtml() {
    return <h3>Twitch Account</h3>;
  }

  render() {
    if (this.props.isTwitchConnected) {
      return (
        <div id="thirdPartyAccount">
          <div className="darkContentBlock">
            <img className="glitchImg" src={twitchIcon} alt="Twitch icon" />
            <div>
              <div className="status">Linked</div>
            </div>
            <button
              className="button-outline red"
              onClick={this.handleDisconnect}
              data-test="disconnect-twitch-account"
            >
              Unlink
            </button>
          </div>
        </div>
      );
    }
    return (
      <div id="thirdPartyAccount">
        <div className="darkContentBlock">
          <img className="link-thirdparty-img glitchImg" src={twitchIcon} alt="Twitch icon" />
          <div className="status">
            Link your Account on Twitch.
          </div>
          <a href={this.props.twitchUrl} className="button-outline" data-test="connect-twitch-account">
            Link account
          </a>
        </div>
      </div>
    );
  }
}

TwitchAccount.propTypes = {
  twitchUrl: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  isTwitchConnected: PropTypes.bool.isRequired,
  isTwitchConnectionFetched: PropTypes.bool.isRequired,
  requestTwitchDisconnect: PropTypes.func.isRequired,
};

export default TwitchAccount;
