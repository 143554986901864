import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import View from './login.view.jsx';
import { loginRequest } from '../../../actions/authentication';

const mapStateToProps = ({ authentication }) => ({
  errorTitle: authentication.errorTitle,
});

const mapDispatchToProps = {
  loginRequest,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View));
