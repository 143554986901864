import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { loading } from '../../../utilities/loading';
import DateOfBirth from '../DateOfBirth';
import Country from '../Country';
import Utilities from '../../../utilities/Utilities';
import FormFeedback from '../FormFeedback';
import FormValidation from '../FormValidation';

let emailTimeout = null;
let passwordTimeout = null;

const queryString = require('query-string');

const CAS_LOGIN_BASE = process.env.CAS_LOGIN_BASE;
const SAML_LOGIN_URI = 'login.paradoxplaza.com/saml/login';

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    const params = queryString.parse(this.props.location.search);
    const rawParams = this.props.location.search;
    this.state = {
      dateOfBirth: '',
      country: '',
      newsletter: false,
      partner: false,
      terms: true,
      privacy: true,
      service: params.service,
      source: params.source,
      loginServiceUrl: params.service ? `${CAS_LOGIN_BASE}?service=${params.service}` : '',
      errorMessage: '',
      formErrors: { email: '', password: '', age: '', terms: '', privacy: '' },
      emailValid: true,
      passwordValid: true,
      ageValid: false,
      formValid: false,
      rawParams: rawParams,
    };

    Utilities.loadRecaptcha();

    window.recaptchaCallback = response => {
      this.submit(response);
    };

    // TODO> remove
    this.handleInputChange = this.handleInputChange.bind(this);
    this.preselectCountry = this.preselectCountry.bind(this);
    this.handleDobChange = this.handleDobChange.bind(this);
  }

  async handleInputChange(event) {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    this.setState({
      [name]: value,
    });

    if (name === 'email') {
      clearTimeout(emailTimeout);
      emailTimeout = setTimeout(() => {
        this.validateField(name, value);
      }, 500);
    } else if (name === 'password') {
      clearTimeout(passwordTimeout);
      passwordTimeout = setTimeout(() => {
        this.validateField(name, value);
      }, 500);
    } else {
      this.validateField(name, value);
    }
  }

  validateField(fieldName, value) {
    FormValidation(fieldName, value, this.state, validated => {
      this.setState(
        {
          formErrors: validated.fieldValidationErrors,
          emailValid: validated.emailValidity,
          passwordValid: validated.passwordValidity,
          ageValid: validated.ageValidity,
        },
        this.validateForm,
      );
    });
  }

  validateForm() {
    this.setState(prevState => ({
      formValid:
        prevState.emailValid &&
        prevState.passwordValid &&
        prevState.email !== undefined &&
        prevState.password !== undefined &&
        prevState.ageValid &&
        prevState.country !== '',
    }));
  }

  handleDobChange(dateOfBirth) {
    this.setState({ dateOfBirth });

    const name = 'age';
    const value = dateOfBirth;
    this.validateField(name, value);
  }

  async handleSubmit(event) {
    event.preventDefault();
    loading.start();
    window.grecaptcha.reset();
    window.grecaptcha.execute();
  }

  async submit(recaptchaResponse) {
    let landingUrl = this.state.service ? decodeURIComponent(this.state.service) : '';
    let sourceService = this.state.source;
    let samlSource;
    if (!sourceService && !landingUrl) {
      sourceService = 'accounts.paradoxplaza.com';
    } else if (!sourceService && landingUrl) {
      let sourceServiceArr;
      if (landingUrl.includes('paradoxwikis')) {
        sourceServiceArr = landingUrl.match(/.*\/\/(.*.paradoxwikis.com).*/);
      } else if (landingUrl.includes(SAML_LOGIN_URI)) {
        landingUrl = this.state.rawParams.replace('?service=', '');
        samlSource = 'www.paradoxinteractive.com';
      } else if (landingUrl.includes('paradoxinteractive')) {
        sourceServiceArr = landingUrl.match(/.*\/\/(.*.paradoxinteractive.com).*/);
      } else if (landingUrl.includes('paradoxplaza')) {
        sourceServiceArr = landingUrl.match(/.*\/\/(.*.paradoxplaza.com).*/);
      } else if (landingUrl.includes('worldofdarkness')) {
        sourceServiceArr = landingUrl.match(/.*\/\/(.*.worldofdarkness.com).*/);
      }

      if(sourceServiceArr && sourceServiceArr[1]) {
        sourceService = sourceServiceArr[1];
        landingUrl =  `http://${sourceServiceArr[1]}`;
        landingUrl = decodeURIComponent(landingUrl);
      }
      if(samlSource) {
        sourceService = samlSource;
      }
    }

    try {
      await this.props.signupRequest(this.state, landingUrl, sourceService, recaptchaResponse);
    } catch (e) {
      if (e === 'ua') {
        this.setState({
          errorMessage: 'You need to be over 16 years old to create an account.',
        });
      } else if (e === 'account-exists') {
        this.setState(() => ({
          errorMessage: (
            <>
              An account with that email address already exists.{' '}
              <a href="/login" style={{ color: '#fff' }}>
                Sign In
              </a>
            </>
          ),
        }));
      } else {
        this.setState({
          errorMessage: 'Unable to sign-up.',
        });
      }
    }

    loading.end();
  }

  preselectCountry(code) {
    this.setState({
      country: code,
    });
  }

  get loginLink() {
    if (this.state.loginServiceUrl) {
      return (
        <a href={this.state.loginServiceUrl} className="redirect-button pull-right">
          Log in
        </a>
      );
    }

    return (
      <Link
        to={{ pathname: '/login', search: this.props.location && this.props.location.search }}
        className="redirect-button pull-right"
      >
        Log in
      </Link>
    );
  }

  render() {
    let error;
    if (this.state.errorMessage !== '') {
      error = <div className="error">{this.state.errorMessage}</div>;
    }

    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <div id="signUp">
            <label htmlFor="email">
              <div className="text">Email</div>
              <div className="required">Required</div>
              <input
                field="email"
                className="form-control"
                name="email"
                id="email"
                style={
                  this.state.formErrors.email === ''
                    ? { border: '1px solid #adadad' }
                    : { border: '1px solid #fe0642' }
                }
                onChange={this.handleInputChange}
              />
              <FormFeedback feedback={this.state.formErrors.email} />
            </label>

            <label htmlFor="password">
              <div className="text">Password</div>
              <div className="required">Required</div>
              <input
                field="password"
                className="form-control"
                type="password"
                name="password"
                ref={el => {
                  this.password = el;
                }}
                id="password"
                style={
                  this.state.formErrors.password === ''
                    ? { border: '1px solid #adadad' }
                    : { border: '1px solid #fe0642' }
                }
                onChange={this.handleInputChange}
              />
              <FormFeedback feedback={this.state.formErrors.password} />
            </label>

            <DateOfBirth
              borderStyle={
                this.state.errorMessage === 'You need to be over 16 years old to create an account.'
                  ? { border: '1px solid #fe0642' }
                  : { border: '1px solid #adadad' }
              }
              updateOnParent={this.handleDobChange}
              date={this.state.dateOfBirth}
            />

            <Country
              handleInputChange={this.handleInputChange}
              preselectCountry={this.preselectCountry}
              country={this.state.country}
              state={this.state.state}
            />

            <div className="agreements">
              <label className="checkbox" htmlFor="agreementNewsletter">
                <input
                  id="agreementNewsletter"
                  name="newsletter"
                  type="checkbox"
                  checked={this.state.newsletter}
                  onChange={this.handleInputChange}
                />
                <div>I want to receive emails with news, offers and information about titles from Paradox Interactive.</div>
              </label>
              <label className="checkbox" htmlFor="partnerEnabled">
                <input
                  id="agreementPartner"
                  name="partner"
                  type="checkbox"
                  checked={this.state.partner}
                  onChange={this.handleInputChange}
                />
                <div>I want tailored ads for Paradox games (instead of boring ads!) by securely sharing my data with select partners.</div>
              </label>
            </div>
            <div className="tou_pp_agreement">
              By clicking "Create Account" you hereby confirm that you have read, understood and accepted our <a href="https://legal.paradoxplaza.com/eula?locale=en">EULA</a> and <a href="https://legal.paradoxplaza.com/privacy?locale=en">Privacy Policy</a>
            </div>
            <div className="controls">
              <input
                type="submit"
                value="Create account"
                className="button pull-right"
                disabled={!this.state.formValid}
              />
              <div
                className="g-recaptcha"
                data-sitekey={
                  window.Cypress
                    ? '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
                    : process.env.RECAPTCHA_SITE_KEY
                }
                data-callback="recaptchaCallback"
                data-size="invisible"
              />
            </div>
          </div>
        </form>
        {error}
        <div className="footer">
          Already have an account?
          {this.loginLink}
        </div>
      </div>
    );
  }
}

SignUp.propTypes = {
  signupRequest: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  errorTitle: PropTypes.string,
  match: PropTypes.object,
};

export default SignUp;
