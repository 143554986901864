const connect = async (code, redirectUri, nonce) =>
  fetch('/api/epic/connection', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      code,
      redirect_uri: redirectUri,
      nonce,
    }),
    credentials: 'same-origin',
    method: 'PUT',
  });

const disconnect = async () =>
  fetch('/api/epic/connection', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: '',
    credentials: 'same-origin',
    method: 'DELETE',
  });

export default {
  connect,
  disconnect,
};
