import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { loading } from '../../../../utilities/loading';
import FormValidation from '../../../authentication/FormValidation';

let emailTimeout = null;
class SetEmail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      requestSent: false,
      newEmail: '',
      password: '',
      formErrors: { newEmail: '' },
      newEmailValid: true,
      formValid: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value,
    });

    if (name === 'newEmail') {
      clearTimeout(emailTimeout);
      emailTimeout = setTimeout(() => {
        this.validateField(name, value);
      }, 500);
    }
  }

  validateField(fieldName, value) {
    FormValidation(fieldName, value, this.state, validated => {
      this.setState(
        {
          formErrors: validated.fieldValidationErrors,
          newEmailValid: validated.newEmailValidity,
        },
        this.validateForm,
      );
    });
  }

  validateForm() {
    this.setState(prevState => ({
      formValid: prevState.newEmailValid && prevState.newEmail !== '',
    }));
  }

  async handleSubmit(event) {
    event.preventDefault();
    loading.start();

    try {
      await this.props.setEmailRequest(this.props.email, this.state.password, this.state.newEmail);
      this.clearState();
    } finally {
      loading.end();
    }
  }

  clearState() {
    this.setState({
      requestSent: false,
      newEmail: '',
      password: '',
    });
  }

  render() {
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />;
    }

    if (this.state.requestSent) {
      return <div>Your email has been set.</div>;
    }

    return (
      <div id="setEmail">
        <h2>Change Email</h2>
          <div className="summary darkContentBlock borderBottom">
            <div className="changeEmailNotice">
              Change email functionality has been moved to the new account website.
              In order to change your email, please go to <a href="https://beta-accounts.paradoxinteractive.com/profile">Profile</a>
            </div>
          </div>
          <div className="darkContentBlock center">
          </div>
      </div>
    );
  }
}

SetEmail.propTypes = {
  email: PropTypes.string.isRequired,
  setEmailRequest: PropTypes.func.isRequired,
};

export default SetEmail;
