import { requestFromCore, respondWith } from '../utils';

const sortByTitle = function (a, b) {
  if (a.title < b.title) {
    return -1;
  }

  if (a.title > b.title) {
    return 1;
  }

  return 0;
}
/**
 * for games not present in known skus list
 * see https://beta.paradoxplaza.com/browse/AW-49
 *
 * TODO should probably be removed as soon as it's possible to use this:
 * https://beta.paradoxplaza.com/browse/PLAZA2-167
 */
const defaultMetaInfo = {
  name: '',
  os: [],
  platform: [],
  forumUrl: '',
  hasImage: false,
};
const groupDownloads = items => {
  const groups = [];
  items.forEach(download => {
    let found = false;
    groups.forEach(group => {
      if (group.title === download.title && !found) {
        found = true;
        group.label.push(download.label);
      }
    });
    if (!found) {
      groups.push({
        title: download.title,
        label: [download.label],
        description: download.description,
        id: download.id,
        universal: download.universal,
      });
    }
  });

  return groups;
};
const normalizeProducts = productList => {
  const games = [];
  productList.forEach(products => {
    if (products.steam) {
      games.push(
        products.steam.map(item => ({
          ...item,
          type: 'steam',
        })),
      );
    } else if (products.paradox) {
      games.push(
        products.paradox.map(item => ({
          ...item,
          type: 'paradox',
        })),
      );
    }
  });

  return Array.prototype.concat(...games).filter(item => item.sku && item.title);
};
const uniqueGames = games => {
  const unique = [];
  games.forEach(game => {
    let found = false;
    unique.forEach(u => {
      if (u.sku === game.sku) {
        found = true;
      }
    });

    if (!found) {
      unique.push(game);
    }
  });

  return unique;
};

const downloads = async token => {
  const [universalDownloadsResponse, downloadsResponse] = await Promise.all([
    fetch('/api/inventory/downloads/universal', {
      method: 'GET',
      credentials: 'same-origin',
    }),
    requestFromCore({
      httpMethod: 'GET',
      url: 'inventory/client/downloads',
      token,
    }),
  ]);
  const [universalDownloads, clientDownloads] = await Promise.all([
    universalDownloadsResponse.json(),
    respondWith(await downloadsResponse.json()),
  ]);
  if (clientDownloads.result === 'OK' && universalDownloads.result === 'OK') {
    const allDownloads = universalDownloads.downloads.concat(clientDownloads.downloads);
    return groupDownloads(
      allDownloads.map(download => ({
        title: download.title,
        label: download.label,
        description: download.description || '',
        id: download.id || download.skuid,
        universal: download.id != null,
      })),
    ).sort((a, b) => {
      if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
      if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
      return 0;
    });
  }

  return [];
};

const getClientDownloadURL = async(skuid, label, token) => {
  const response = await requestFromCore({
    httpMethod: 'GET',
    url: `inventory/client/downloads/url?skuid=${skuid}&label=${label}`,
    token,
  });

  return response.json();
};

const getUniversalDownloadURL = async id => {
  const resp = await fetch(`/api/inventory/downloads/universal/url?id=${id}`, {
    method: 'GET',
    credentials: 'same-origin',
  });

  return resp.json();
};

const products = async token => {
  try {
    const response = await requestFromCore({
      httpMethod: 'GET',
      url: 'inventory/products',
      token,
    });
    const data = await respondWith(await response.json());
    return normalizeProducts(data.products);
  } catch (e) {
    throw new Error('product-error');
  }
};

const keys = async token => {
  const response = await requestFromCore({
    httpMethod: 'GET',
    url: 'inventory/keys',
    token,
  });

  try {
    const data = await respondWith(await response.json());
    return data.keys;
  } catch (e) {
    throw new Error('key-error');
  }
};

const skus = async skuList => {
  const response = await fetch('/api/skus', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(skuList),
    method: 'POST',
    credentials: 'same-origin',
  });

  return response.json();
};

const games = async token => {
  const [productList, keyList] = await Promise.all([
    products(token),
    keys(token)
  ]);
  const gameList = productList
    .map(product => ({
      ...product,
      keys: keyList.filter(key => {
        // SKUs might have inconsistent case sometimes
        const sku = product.sku.toLowerCase();
        const keySku = (key.product || {}).name.toLowerCase();

        return keySku === sku;
      }),
    }))
    .concat(
      keyList.map(key => ({
        keys: [key],
        operatingSystem: [],
        product_id: '',
        product_type: 'basegame',
        sku: key.product.name,
        title: key.product.display_name,
        type: key.product.context,
      })),
    );
  const uniqueGamesList = uniqueGames(gameList);
  const metadataForUniqueGames = await skus(uniqueGamesList.map((game) => game.sku));
  return (
    uniqueGamesList
      .map(game => {
        const meta = metadataForUniqueGames[game.sku.toUpperCase()] || defaultMetaInfo;

        return {
          ...game,
          hasImage: meta.hasImage,
          operatingSystems: meta.os || defaultMetaInfo.os,
          platforms: meta.platform || defaultMetaInfo.platform,
          title: meta.name || game.title,
          forumUrl: meta.forumUrl || defaultMetaInfo.forumUrl,
        };
      })
      .sort(sortByTitle)
      .filter(game => (game.title !== undefined || game.title !== '') && game.title !== 'Paradox Family')
  );
};

const mappedOrders = async(skuItems, orders) => {
  const filteredSkuItems = {};
  Object.keys(skuItems).forEach(skuId => {
    const newItem = skuItems[skuId];
    filteredSkuItems[skuId.toUpperCase()] = newItem;
  });

  const title = sku => {
    const meta = filteredSkuItems[sku] || defaultMetaInfo;
    return meta.name;
  };

  return orders
    .map(order => ({
      ...order,
      items: order.items.map(item => ({
        ...item,
        sku: item.sku.toUpperCase(),
        title: title(item.sku.toUpperCase()),
        hasImage: item.sku.toUpperCase() in filteredSkuItems,
      })),
    }))
    .filter(order => order.status === 'complete');
};

const simpleOrdersCall = async(userId, pageSize, pageIndex) => {
  const response = await fetch(
    `/api/inventory/orders?page=${pageIndex}&pageSize=${pageSize}`,
    {
      method: 'GET',
      credentials: 'same-origin',
    },
  );
  return respondWith(await response.json());
};

const allOrders = async(userId, token) => {
  const pageSize = 20;
  let data = await simpleOrdersCall(userId, pageSize, 0);
  let orders = [];
  orders = data.orders;
  for (let i = 1; i < data.totalPages; i += 1) {
    /* Ignoring eslint rule since we want the
    responses to be in sorted order */
    /* eslint-disable no-await-in-loop */
    data = await simpleOrdersCall(userId, pageSize, i);
    orders = orders.concat(data.orders);
    /* eslint-enable no-await-in-loop */
  }
  const skuItems = await skus(token);
  return {
    orders: await mappedOrders(skuItems, orders),
    userHasMoreOrders: false,
  };
};

const latestOrders = async(userId, token) => {
  const pageSize = 20;
  const data = await simpleOrdersCall(userId, pageSize, 0);
  const skuItems = await skus(token);
  return {
    orders: await mappedOrders(skuItems, data.orders),
    userHasMoreOrders: data.totalPages > 1,
  };
};

export default {
  games,
  downloads,
  getUniversalDownloadURL,
  getClientDownloadURL,
  allOrders,
  latestOrders,
};
