import { connect } from 'react-redux';
import View from './betaAccounts.view.jsx';
import { removeNotification } from '../../../../actions/notifications';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  removeNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
