import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CopyText from '../../../generic/copyText.view';
import PasswordRequest from '../PasswordRequest';

import check from '../../../../../public/images/check.svg';

class YourProfile extends React.Component {
  componentDidMount() {
    if (!this.props.user.account.isEmailVerified) {
      this.props.emailVerified();
    }

    if (!this.props.referralCode) {
      this.props.fetchReferralCode(this.props.user.userId);
    }
  }

  get verifiedSpan() {
    if (this.props.user.account.isEmailVerified) {
      return (
        <div className="verified">
          <img src={check} alt="" />
          <div>Verified</div>
        </div>
      );
    }

    return (
      <div className="verified unverified">
        <div>Unverified</div>
      </div>
    );
  }

  render() {
    return (
      <div id="yourProfile">
        <h2>Your Profile</h2>
        <div className="summary darkContentBlock borderBottom">
          <div className="form-group">
            <div>Email</div>
            <div className="pull-right center wrap-flexbox">
              <div className="email-address">{this.props.user.email}</div>
              {this.verifiedSpan}
            </div>
          </div>
          <div className="form-group">
            <div>Password</div>
            <div>
              <PasswordRequest email={this.props.user.email} />
            </div>
          </div>
          <div className="form-group">
            <div>Referral code</div>
            <div className="pull-right center wrap-flexbox">
              <CopyText text={this.props.referralCode} />
            </div>
          </div>
        </div>
        <div className="editProfile darkContentBlock">
          <Link to="/settings/edit-profile" className="button">
            Edit Profile
          </Link>
        </div>
      </div>
    );
  }
}

YourProfile.propTypes = {
  emailVerified: PropTypes.func.isRequired,
  fetchReferralCode: PropTypes.func.isRequired,
  referralCode: PropTypes.string,
  user: PropTypes.shape({
    account: PropTypes.shape({
      isEmailVerified: PropTypes.bool.isRequired,
    }),
    userId: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
};

export default YourProfile;
