import React from 'react';
import PropTypes from 'prop-types';
import epicIcon from '../../../../../public/images/icons/epic.svg';

class EpicAccount extends React.Component {
  constructor(props) {
    super(props);
    this.handleDisconnect = this.handleDisconnect.bind(this);
  }

  handleDisconnect(event) {
    event.preventDefault();
    this.props.requestEpicDisconnect(this.props.userId);
  }

  get headerHtml() {
    return <h3>Epic Account</h3>;
  }

  render() {
    if (this.props.isEpicConnected) {
      return (
        <div id="thirdPartyAccount">
          <div className="darkContentBlock">
            <img className="epicImg" src={epicIcon} alt="Epic icon" />
            <div>
              <div className="status">Linked</div>
            </div>
            <button
              className="button-outline red"
              onClick={this.handleDisconnect}
              data-test="disconnect-epic-account"
            >
              Unlink
            </button>
          </div>
        </div>
      );
    }
    return (
      <div id="thirdPartyAccount">
        <div className="darkContentBlock">
          <img className="link-thirdparty-img epicImg" src={epicIcon} alt="Epic icon" />
          <div className="status">Link your Account on Epic.</div>
          <a href={this.props.epicUrl} className="button-outline" data-test="connect-epic-account">
            Link account
          </a>
        </div>
      </div>
    );
  }
}

EpicAccount.propTypes = {
  epicUrl: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  isEpicConnected: PropTypes.bool.isRequired,
  isEpicConnectionFetched: PropTypes.bool.isRequired,
  requestEpicDisconnect: PropTypes.func.isRequired,
};

export default EpicAccount;
