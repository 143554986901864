import React from 'react';
import PropTypes from 'prop-types';
import { withLoadingAnimation } from '../../../utilities/loading';

import OrderItem from './OrderItem.jsx';

class Orders extends React.Component {
  constructor(props) {
    super(props);
    this.showAllOrders = this.showAllOrders.bind(this);
  }

  componentDidMount() {
    withLoadingAnimation(this.props.requestOrders({ getAllOrders: false }), this.props.orders);
  }

  showAllOrders() {
    withLoadingAnimation(this.props.requestOrders({ getAllOrders: true }), null);
  }

  render() {
    return (
      <div id="yourOrders">
        <h2>Your Order History</h2>
        <div id="orders">
          {this.props.orders.map(order => <OrderItem key={order.id} order={order} />)}
        </div>
        {this.props.userHasMoreOrders ? (
          <button className="button" onClick={this.showAllOrders}>
            All orders
          </button>
        ) : null}
      </div>
    );
  }
}

Orders.propTypes = {
  requestOrders: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  userHasMoreOrders: PropTypes.bool.isRequired,
  orders: PropTypes.array.isRequired,
};

export default Orders;
