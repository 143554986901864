import { connect } from 'react-redux';
import View from './steamConnect.view.jsx';
import { steamConnectRequest } from '../../../actions/authentication';

const mapStateToProps = state => ({
  isSignedIn: state.authentication.isSignedIn,
  userId: state.authentication.user.userId,
  errorTitle: state.authentication.user.errorTitle || '',
  steamState: state.authentication.steamState,
});

const mapDispatchToProps = {
  steamConnectRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
