import React from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import queryString from 'query-string';

import ConfirmationMessage from '../../ConfirmationMessage';


class XboxConnect extends React.Component {
  constructor(props) {
    super(props);
    this.state = { requestSent: false, errorTitle: null };
  }

  async componentDidMount() {
    const parsedUrl = this.parsedUrl;
    if (parsedUrl && 'state' in parsedUrl) {
      if (parsedUrl.state !== this.props.xboxState) {
        this.setState({ requestSent: true, errorTitle: 'An error occurred' });
        return;
      }
    } else {
      this.setState({ requestSent: true, errorTitle: 'An error occurred' });
      return;
    }
    if (!parsedUrl.error) {
      try {
        await this.props.xboxConnectRequest(
          this.props.userId,
          parsedUrl.code,
        );
        this.setState({ requestSent: true });
      } catch (e) {
        this.setState({ requestSent: true, errorTitle: e.message });
      }
    }
  }

  get parsedUrl() {
    return queryString.parse(window.location.search);
  }

  get linkContent() {
    if (this.returnUrl) {
      return null;
    }

    return (
      <Link to="/settings" className="button-outline">
        Back to settings
      </Link>
    );
  }

  get resultContext() {
    if (this.parsedUrl.error) {
      return <span>Could not link XBOX account: {this.parsedUrl.error.replace('_', ' ')}</span>;
    }

    if (!this.state.requestSent) {
      return <span>Linking your XBOX account</span>;
    }

    if (this.state.errorTitle) {
      return `${this.state.errorTitle}`;
    }

    if (this.returnUrl) {
      window.location = this.returnUrl;
      return (
        <span>
          Your XBOX account is now linked. Redirecting you back to {this.returnUrl} now...
        </span>
      );
    }

    return <span>Your XBOX account is now linked.</span>;
  }

  get returnUrl() {
    if (this.parsedUrl.state && this.parsedUrl.state.return_url) {
      return this.parsedUrl.state.return_url;
    }

    return '';
  }

  render() {
    if (this.props.isSignedIn) {
      return (
        <ConfirmationMessage>
          <div>{this.resultContext}</div>
          {this.linkContent}
        </ConfirmationMessage>
      );
    }

    return <Redirect to="/" />;
  }
}

XboxConnect.propTypes = {
  userId: PropTypes.string.isRequired,
  isSignedIn: PropTypes.bool.isRequired,
  xboxConnectRequest: PropTypes.func.isRequired,
};

export default XboxConnect;
