import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../../../public/images/logo.png';
import facebook from '../../../public/images/icons/facebook.svg';
import twitter from '../../../public/images/icons/twitter.svg';
import youtube from '../../../public/images/icons/youtube.svg';
import twitch from '../../../public/images/icons/twitch.svg';
import spotify from '../../../public/images/icons/spotify.svg';
import psnIcon from '../../../public/images/icons/psn-icon.svg';

const Footer = () => (
  <div id="footer">
    <div className="pageCenter">
      <nav className="mainLinks">
        <div className="logo">
          <Link to="/games">
            <img src={logo} alt="Paradox Interactive logo" />
          </Link>
        </div>
        <div>
          <h3>Games</h3>
          <ul>
            <li>
              <a href="https://www.paradoxplaza.com/featured-games.html">Featured</a>
            </li>
            <li>
              <a href="https://www.paradoxplaza.com/browse/">All</a>
            </li>
            <li>
              <a href="https://www.paradoxplaza.com/genres/">Genres</a>
            </li>
            <li>
              <a href="https://www.paradoxinteractive.com/our-games/launcher">Play on Paradox technology</a>
            </li>
          </ul>
        </div>
        <div>
          <h3>Community</h3>
          <ul>
            <li>
              <a href={process.env.FORUM_URL}>Paradox Forums</a>
            </li>
            <li>
              <a href="https://paradoxwikis.com/">Paradox Wikis</a>
            </li>
            <li>
              <a href="https://www.paradoxplaza.com/support-static-info-ca.html">Support</a>
            </li>
          </ul>
        </div>
        <div>
          <h3>About</h3>
          <ul>
            <li>
              <a href="https://beta-accounts.paradoxinteractive.com" target="_blank">New accounts website preview</a>
            </li>
            <li>
              <a href="https://www.paradoxplaza.com/news">News</a>
            </li>
            <li>
              <a href="http://www.paradoxplaza.com/about-us-static-info-ca.html">About Us</a>
            </li>
            <li>
              <a href="http://career.paradoxplaza.com/">Careers</a>
            </li>
            <li>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSfuU4IQGbG6LhpaVpSXcP2STZGQoJlq1wASfA3pQD4LBexM9Q/viewform">
                Join Our Playtests
              </a>
            </li>
            <li>
              <a href="http://www.paradoxplaza.com/press-static-info-ca.html">Media contact</a>
            </li>
          </ul>
        </div>
        <div className="socialMedia">
          <h3>Social Media</h3>
          <ul className="icons">
            <li>
              <a href="https://www.facebook.com/ParadoxInteractive">
                <img src={facebook} alt="Facebook icon" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/PdxInteractive">
                <img src={twitter} alt="Twitter icon" />
              </a>
            </li>
            <li>
              <a href="http://www.youtube.com/user/Paradoxplaza">
                <img src={youtube} alt="Youtube icon" />
              </a>
            </li>
            <li>
              <a href="http://www.twitch.tv/paradoxinteractive">
                <img src={twitch} alt="Twitch icon" />
              </a>
            </li>
            <li>
              <a href="https://open.spotify.com/artist/75N2nC2KNgaQ1e6bGs0wyc">
                <img src={spotify} alt="Spotify icon" />
              </a>
            </li>
          </ul>
          <ul>
            <li>
              <a href="http://www.paradoxplaza.com/legal-static-info-ca.html">Legal Information</a>
            </li>
            <li>
              <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage">
                EU Online Dispute Resolution
              </a>
            </li>
            <li>
              <a href="http://www.paradoxplaza.com/faq-static-info-ca.html">
                Frequently Asked Questions
              </a>
            </li>
            <li>
              <a href="http://www.paradoxinteractive.com/">Paradox Interactive corporate website</a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
    <div className="paddingCenter darker">
      © Paradox Interactive. Trademarks belong to their respective owners. All rights reserved.
      <br />
      <br />
      ©2019 Valve Corporation. Steam and the Steam logo are trademarks and/or registered trademarks
      of Valve Corporation in the U.S. and/or other countries.
      <br />
      “<img className="thirdPartyImg" src={psnIcon} alt="PSN icon" />” and “PlayStation” are
      registered trademarks or trademarks of Sony Interactive Entertainment Inc.
      <br />
      Microsoft, Xbox, and the Xbox logo are trademarks of the Microsoft group of companies.
      <br />
      TWITCH, the TWITCH Logo, the Glitch Logo, and/or TWITCHTV are trademarks of Twitch
      Interactive, Inc. or its affiliates.
      <br />
      Epic, Epic Games and the Epic Games logo are trademarks or registered trademarks of Epic
      Games, Inc.
      <br />
      <br />
      <a href="http://legal.paradoxplaza.com/eula">EULA</a>
    </div>
  </div>
);

export default Footer;
