import { connect } from 'react-redux';
import View from './claim.view.jsx';
import { requestTwitchDrops, requestConnections } from '../../../../actions/authentication';
import { getTwitchUrl } from '../../../../utilities/Utilities';

const mapStateToProps = state => ({
  twitchUrl: getTwitchUrl(state.authentication.twitchState, state.authentication.twitchNonce, { internalRedirect: "redeem" }),
  userId: state.authentication.user.session.userid,
  isTwitchConnected: state.authentication.isTwitchConnected,
});

const mapDispatchToProps = {
  requestTwitchDrops,
  requestConnections,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
