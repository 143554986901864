import { connect } from 'react-redux';
import View from './twitchAccount.view.jsx';
import { requestTwitchDisconnect } from '../../../../actions/authentication';
import { getTwitchUrl } from '../../../../utilities/Utilities';

const mapStateToProps = state => ({
  twitchUrl: getTwitchUrl(state.authentication.twitchState, state.authentication.twitchNonce),
  userId: state.authentication.user.userId,
  isTwitchConnected: state.authentication.isTwitchConnected,
  isTwitchConnectionFetched: state.authentication.isTwitchConnectionFetched,
});

const mapDispatchToProps = {
  requestTwitchDisconnect,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
