import React from 'react';
import PropTypes from 'prop-types';

import Utilities from '../../utilities/Utilities';

class Country extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      countries: [],
      preSelectedCountry: '',
      override: false,
    };
  }

  async loadCountries() {
    let response = await fetch('/api/countries');
    response = await response.json();

    this.setState({
      countries: response.countries,
    });
  }

  async componentWillMount() {
    await this.setState({
      preSelectedCountry: Utilities.getCookie('COUNTRY'),
    });

    if (this.props.country) {
      this.setState({
        override: true,
      });
    }

    if (this.state.preSelectedCountry === '' || this.state.override) {
      await this.loadCountries();
    } else {
      this.props.preselectCountry(this.state.preSelectedCountry);
    }
  }

  renderDropDown() {
    const countryOptions = [];

    this.state.countries.forEach(country => {
      countryOptions.push(
        <option key={country.code} value={country.code}>
          {country.name}
        </option>,
      );
    });

    return (
      <div>
        <label htmlFor="country">
          <div className="text">Country/Region</div>
          <div className="required">Required</div>
          <select
            id="country"
            className="form-control"
            name="country"
            required="required"
            onChange={this.props.handleInputChange}
            value={this.props.country}
          >
            <option disabled="disabled" value="">
              Select your country/region
            </option>
            {countryOptions}
          </select>
        </label>
      </div>
    );
  }

  render() {
    if (this.state.preSelectedCountry !== '' && this.state.override === false) {
      return (
        <div>
          <input type="hidden" name="country" id="country" value={this.state.preSelectedCountry} />
        </div>
      );
    }

    // Only renders if no country cookie is set
    return this.renderDropDown();
  }
}

Country.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
  preselectCountry: PropTypes.func,
  country: PropTypes.string.isRequired,
};

Country.defaultProps = {
  preselectCountry: () => {},
};

export default Country;
