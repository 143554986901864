import React from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import ConfirmationMessage from '../../ConfirmationMessage';
import { getEpicRedirectUri } from "../../../utilities/Utilities";
import styles from './epicConnect.scss';

class EpicConnect extends React.Component {
  constructor(props) {
    super(props);
    this.state = { requestSent: false, errorTitle: null, returnUrl: '/settings', returnUrlLabel: 'Settings' };
  }

  async componentDidMount() {
    const parsedUrl = this.parsedUrl;

    if (parsedUrl && 'error' in parsedUrl) {
      if (parsedUrl.error === 'cancelled') {
        this.setState({ requestSent: true, errorTitle: 'You cancelled the Epic account linking process' });
        return;
      }
      this.setState({ requestSent: true, errorTitle: parsedUrl.error_description || 'An error occurred' });
      return;
    }

    if (parsedUrl && 'code' in parsedUrl && 'state' in parsedUrl) {
      if (parsedUrl.state !== this.props.epicState) {
        this.setState({ requestSent: true, errorTitle: 'An error occurred' });
        return;
      }

      try {
        await this.props.requestEpicConnect(
          parsedUrl.code,
          getEpicRedirectUri(),
          this.props.epicNonce
        );
        this.setState({ requestSent: true });
      } catch (error) {
        this.setState({ requestSent: true, errorTitle: error.message });
      }
    } else {
      this.setState({ requestSent: true, errorTitle: 'An error occurred' });
    }
  }

  get parsedUrl() {
    return queryString.parse(this.props.location.hash || this.props.location.search);
  }

  get linkContent() {
    if (this.state.errorTitle) {
      return (
        <Link to={this.state.returnUrl} className="button-outline">
          Back to {this.state.returnUrlLabel}
        </Link>
      );
    }

    return null;
  }

  get resultContext() {
    if (!this.state.requestSent) {
      return <p className={styles.margin_small}>Linking your Epic account...</p>;
    }

    if (this.state.errorTitle) {
      return (
        <p className={styles.margin_small}>
          {this.state.errorTitle}.
        </p>
      );
    }

    window.location = this.state.returnUrl;
    return (
      <React.Fragment>
        <p className={styles.margin_small}>
          Your Epic account is now linked.
        </p>
        <p className={styles.margin_small}>
          Redirecting you back to {this.state.returnUrlLabel}...
        </p>
      </React.Fragment>
    );
  }

  render() {    
    if (this.props.isSignedIn) {
      return (
        <ConfirmationMessage>
          {this.resultContext}
          {this.linkContent}
        </ConfirmationMessage>
      );
    }

    return <Redirect to="/" />;
  }
}

EpicConnect.propTypes = {
  isSignedIn: PropTypes.bool.isRequired,
  requestEpicConnect: PropTypes.func.isRequired,
  epicState: PropTypes.string.isRequired,
  epicNonce: PropTypes.string.isRequired,
};

export default EpicConnect;
