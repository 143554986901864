import { respondWith, requestFromCore } from '../utils';

const connect = async(code, redirectUri, nonce) =>
  fetch('/api/twitch/connection', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      code,
      redirect_uri: redirectUri,
      nonce,
    }),
    credentials: 'same-origin',
    method: 'PUT',
  });

const disconnect = async() =>
  fetch('/api/twitch/connection', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: '',
    credentials: 'same-origin',
    method: 'DELETE',
  });

const getDrops = async(token) => {
  const response = await requestFromCore({
    httpMethod: 'POST',
    url: 'loyalty/twitch/claim',
    headers: {
      'x-pdx-error-version': 'v2',
    },
    token,
  });

  return respondWith(await response.json());
};

export default {
  connect,
  disconnect,
  getDrops,
};
