import { connect } from 'react-redux';
import View from './psnConnect.view.jsx';
import { psnConnectRequest } from '../../../actions/authentication';

const mapStateToProps = state => ({
  isSignedIn: state.authentication.isSignedIn,
  userId: state.authentication.user.userId,
  errorTitle: state.authentication.user.errorTitle || '',
  psnState: state.authentication.psnState,
});

const mapDispatchToProps = {
  psnConnectRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
