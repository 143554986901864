import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { loading } from '../../../utilities/loading';

import ConfirmationMessage from '../../ConfirmationMessage/index';

class Confirmation extends React.Component {
  constructor(props) {
    super(props);

    this.state = { requestSent: false, errorTitle: null };
  }

  async componentDidMount() {
    loading.start();
    try {
      await this.props.confirmationRequest(
        this.props.match.params.token,
        this.props.confirmationType,
        this.props.match.params.interest,
      );
      this.setState({ errorTitle: null });
    } catch (e) {
      let description = '';
      switch (e.errorCode) {
        case 'invalid-token':
          if (this.props.confirmationType === 'subscription') {
            description =
              'The code in the link is invalid, or has already been used. ' +
              'Please check your account and see if you have already been subscribed.';
          } else {
            description = 'The code in the link is invalid.';
          }
          break;
        case 'account-missing':
          description = 'The account linked to this code could not be found.';
          break;
        case 'not-found':
          description = 'The account could not be found.';
          break;
        case 'third-party-exception':
          description = 'Unable to reach newsletter service. Please try again later';
          break;
        default:
          if (this.props.confirmationType === 'subscription') {
            description =
              'An unknown error occurred. To subscribe to the newsletter, ' +
              'please log in to your Paradox account and go to the settings page.';
          } else {
            description = 'An unknown error occurred. Please try again.';
          }
      }
      this.setState({
        errorTitle: description,
      });
    } finally {
      this.setState({
        requestSent: true,
      });
      loading.end();
    }
  }

  get linkContent() {
    if (this.props.isSignedIn) {
      return (
        <Link to="/settings" className="button-outline">
          Back to settings
        </Link>
      );
    }

    return (
      <Link to="/login" className="button-outline">
        Back to login
      </Link>
    );
  }

  get resultContext() {
    if (this.state.errorTitle) {
      return (
        <div data-test="confirmationFailure">
          {this.props.errorText}
          <br />
          <br />
          {this.state.errorTitle}
        </div>
      );
    }

    return <div data-test="confirmationSuccess">{this.props.successText}</div>;
  }

  render() {
    if (this.state.requestSent) {
      return (
        <ConfirmationMessage>
          {this.resultContext}
          {this.linkContent}
        </ConfirmationMessage>
      );
    }

    return (
      <ConfirmationMessage>
        <div>{this.props.processingText}</div>
      </ConfirmationMessage>
    );
  }
}

Confirmation.propTypes = {
  confirmationRequest: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string,
      interest: PropTypes.string,
    }).isRequired,
    isExact: PropTypes.bool.isRequired,
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  errorText: PropTypes.string.isRequired,
  isSignedIn: PropTypes.bool.isRequired,
  successText: PropTypes.string.isRequired,
  processingText: PropTypes.string.isRequired,
  confirmationType: PropTypes.string.isRequired,
};

export default Confirmation;
