export const APPEND_NOTIFICATION = 'APPEND_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export const appendNotification = (compName, options = {}) => dispatch => {
  if (
    options.name === 'UnverifiedEmail' ||
    options.name === 'ExpiredSteamConnection' ||
    options.name === 'ConnectSteam' ||
    options.name === 'RefreshTwitchConnection' ||
    options.name === 'BetaAccounts'
  ) {
    dispatch({
      type: REMOVE_NOTIFICATION,
      name: options.name,
    });
  } else {
    dispatch({
      type: REMOVE_NOTIFICATION,
      text: options.text,
    });
  }
  const compId = Math.round(Math.random() * (1000000 - 1) + 1);
  if (options.timing) {
    setTimeout(() => {
      dispatch({
        type: REMOVE_NOTIFICATION,
        options,
        componentId: compId,
      });
    }, options.timing);
  }
  dispatch({
    type: APPEND_NOTIFICATION,
    compName,
    options,
    id: compId,
  });
};

export const removeNotification = (options = {}) => ({
  type: REMOVE_NOTIFICATION,
  ...options,
});
