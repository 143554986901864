import React from 'react';
import PropTypes from 'prop-types';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

class DateOfBirth extends React.Component {
  constructor(props) {
    super(props);

    if (props.date) {
      const date = new Date(props.date);

      this.state = {
        year: date.getUTCFullYear(),
        month: this.leadingZero(date.getUTCMonth() + 1),
        day: this.leadingZero(date.getUTCDate()),
      };
    } else {
      this.state = {
        year: '',
        month: '',
        day: '',
      };
    }

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  leadingZero(i) {
    if (i < 10) {
      return `0${i}`;
    }

    return i;
  }

  handleInputChange(event) {
    event.preventDefault();

    const name = event.target.name;
    const value = event.target.value;

    this.setState(
      {
        [name]: value,
      },
      () => {
        const date = `${this.state.year}-${this.state.month}-${this.state.day}`;
        this.props.updateOnParent(date);
      },
    );
  }

  get currentYear() {
    return new Date().getFullYear();
  }

  get dateOptions() {
    const dateOptions = [];

    for (let i = 1; i <= 31; i += 1) {
      dateOptions.push(
        <option key={i} value={this.leadingZero(i)}>
          {i}
        </option>,
      );
    }

    return dateOptions;
  }

  get monthOptions() {
    const monthOptions = [];

    monthNames.forEach((name, i) => {
      monthOptions.push(
        <option key={name} value={this.leadingZero(i + 1)}>
          {name}
        </option>,
      );
    });

    return monthOptions;
  }

  get yearOptions() {
    const yearOptions = [];

    for (let i = this.currentYear; i >= this.currentYear - 100; i -= 1) {
      yearOptions.push(
        <option key={i} value={i}>
          {i}
        </option>,
      );
    }

    return yearOptions;
  }

  render() {
    return (
      <label className="form-group" htmlFor="date">
        <div className="text">Date of Birth</div>
        <div className="required">Required</div>
        <div className="dateOfBirth">
          <select
            id="day"
            className="form-control"
            name="day"
            required="required"
            onChange={this.handleInputChange}
            value={this.state.day}
            style={this.props.borderStyle}
          >
            <option disabled="disabled" value="">
              Day
            </option>
            {this.dateOptions}
          </select>
          <select
            id="month"
            className="form-control"
            name="month"
            required="required"
            onChange={this.handleInputChange}
            value={this.state.month}
            style={this.props.borderStyle}
          >
            <option disabled="disabled" value="">
              Month
            </option>
            {this.monthOptions}
          </select>
          <select
            id="year"
            className="form-control"
            name="year"
            required="required"
            onChange={this.handleInputChange}
            value={this.state.year}
            style={this.props.borderStyle}
          >
            <option disabled="disabled" value="">
              Year
            </option>
            {this.yearOptions}
          </select>
        </div>
      </label>
    );
  }
}

DateOfBirth.propTypes = {
  updateOnParent: PropTypes.func.isRequired,
  date: PropTypes.string,
  borderStyle: PropTypes.object,
};

DateOfBirth.defaultProps = {
  date: '',
  borderStyle: { border: '1px solid #adadad' },
};

export default DateOfBirth;
