import { connect } from 'react-redux';
import View from './orders.view.jsx';
import { requestOrders } from '../../../actions/authentication';

const mapStateToProps = state => ({
  userId: state.authentication.user.userId,
  orders: state.authentication.orders,
  userHasMoreOrders: state.authentication.userHasMoreOrders,
});

const mapDispatchToProps = {
  requestOrders,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
