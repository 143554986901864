import React from 'react';
import PropTypes from 'prop-types';

import logo from '../../../public/images/logo.svg';
import s from './styles.scss';

const Confirmation = ({ children }) => (
  <div className={s.root}>
    <img className={s.logo} src={logo} alt="Paradox logo" />
    <div className={s.message}>{children}</div>
  </div>
);

Confirmation.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
};

export default Confirmation;
