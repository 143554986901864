import React from 'react';
import PropTypes from 'prop-types';
import { inventory } from '../../../api/api';
import Utilities from '../../../utilities/Utilities';

class DownloadItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPanel: false,
    };
  }

  async startDownload(labelIndex) {
    let url;

    if (this.props.universal) {
      ({ url } = await inventory.getUniversalDownloadURL(this.props.id));
    } else {
      ({ url } = await inventory.getClientDownloadURL(
        this.props.id,
        this.props.label[labelIndex],
        Utilities.sessionCookie,
      ));
    }

    if (url !== '') {
      window.location = url;
    }
  }

  // show urls as html links
  get description() {
    const description = this.props.description;
    const geturl = new RegExp(
      '(^|[ \t\r\n])((ftp|http|https|gopher|mailto|news|nntp|telnet|wais|file|prospero|' +
        'aim|webcal):(([A-Za-z0-9$_.+!*(),;/?:@&~=-])|%[A-Fa-f0-9]{2}){2,}' +
        '(#([a-zA-Z0-9][a-zA-Z0-9$_.+!*(),;/?:@&~=%-]*))?([A-Za-z0-9$_+!*();/?:~-]))',
      'g',
    );
    const urls = description.match(geturl);

    if (urls) {
      return (
        <span>
          {description.split(urls[0])[0]}
          &nbsp;
          <a href={urls[0].replace(/\s/g, '')}>{urls[0].replace(/\s/g, '')}</a>
          &nbsp;
          {description.split(urls[0])[1]}
        </span>
      );
    }

    return description;
  }

  render() {
    const labelRows = this.props.label.map((label, index) => (
      <div tabIndex={1} role="button" onClick={() => this.startDownload(index)} key={label}>
        {label}
      </div>
    ));

    return (
      <li>
        <div className="content">
          <h4>{this.props.title}</h4>
          <div className="info">{this.description}</div>
          <div />
        </div>
        <div className="manage">
          {this.props.label.length > 1 ? (
            <div
              role="button"
              tabIndex={3}
              onClick={() =>
                this.setState(currentState => ({ showPanel: !currentState.showPanel }))
              }
              className="button"
            >
              Download
              <span className="down-arrow" />
              {this.state.showPanel && <div className="panel">{labelRows}</div>}
            </div>
          ) : (
            <div
              tabIndex={2}
              role="button"
              onClick={() => this.startDownload(0)}
              className="button"
            >
              Download
            </div>
          )}
          <div className="label">{this.props.label.join(', ')}</div>
        </div>
      </li>
    );
  }
}

DownloadItem.propTypes = {
  id: PropTypes.string.isRequired,
  universal: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  label: PropTypes.array.isRequired,
};

export default DownloadItem;
