import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { loading } from '../../../utilities/loading';
import Utilities from '../../../utilities/Utilities';
import FormFeedback from '../FormFeedback';
import FormValidation from '../FormValidation';
import Logo from '../Authentication/Logo';
import Footer from '../Authentication/Footer';

let passwordTimeout = null;
class SetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newPassword: '',
      token: this.props.match.params.token,
      requestSent: false,
      error: '',
      formErrors: { password: '' },
      passwordValid: true,
      formValid: false,
    };

    if(window.history && window.history.replaceState) {
      window.history.replaceState(null, '', '/reset');
    }

    Utilities.loadRecaptcha();

    window.recaptchaCallback = response => {
      this.submit(response);
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ error: '' });

    window.grecaptcha.reset();
    window.grecaptcha.execute();
  }

  async submit(recaptchaResponse) {
    loading.start();
    try {
      await this.props.resetPasswordRequest(
        this.state.newPassword,
        this.state.token,
        recaptchaResponse,
      );
      this.setState({ requestSent: true });
    } catch (e) {
      if (e.errorCode === 'bad-password-length') {
        this.setState({
          error: 'Length of password needs to be between 6 and 128 characters. Please try again.',
        });
      } else {
        this.setState({
          error: 'An error occurred, please request a new password reset.',
        });
      }
    } finally {
      loading.end();
    }
  }

  handleInputChange(event) {
    this.setState({ newPassword: event.target.value });

    const value = event.target.value;

    clearTimeout(passwordTimeout);
    passwordTimeout = setTimeout(() => {
      this.validateField('password', value);
    }, 500);
  }

  validateField(fieldName, value) {
    FormValidation(fieldName, value, this.state, validated => {
      this.setState(
        {
          formErrors: validated.fieldValidationErrors,
          passwordValid: validated.passwordValidity,
        },
        this.validateForm,
      );
    });
  }

  validateForm() {
    this.setState(prevState => ({
      formValid: prevState.passwordValid && prevState.password !== '',
    }));
  }

  renderError() {
    let error;
    if (this.state.error) {
      error = <div className="error">{this.state.error}</div>;
    }
    return error;
  }

  renderLinkContext() {
    let linkContext = (
      <Link to="/login" className="button-outline">
        Back to login
      </Link>
    );
    if (this.props.isSignedIn) {
      linkContext = (
        <Link to="/settings" className="button-outline">
          Back to settings
        </Link>
      );
    }
    return linkContext;
  }

  renderSetPassword() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div>
          <div id="setPassword">
            <h3>Enter your new password</h3>
            <div className="content" />
            <label htmlFor="password">
              <input
                field="password"
                id="password"
                className="form-control"
                type="password"
                name="password"
                required
                placeholder="New Password"
                onChange={this.handleInputChange}
                style={
                  this.state.formErrors.password === ''
                    ? { border: '1px solid #adadad' }
                    : { border: '1px solid #fe0642' }
                }
              />
              <FormFeedback feedback={this.state.formErrors.password} />
            </label>
          </div>
          {this.renderError()}
          <div className="footer">
            {this.renderLinkContext()}
            <div
              className="g-recaptcha"
              data-sitekey={process.env.RECAPTCHA_SITE_KEY}
              data-callback="recaptchaCallback"
              data-size="invisible"
            />
            <input
              type="submit"
              disabled={!this.state.formValid}
              value="Submit"
              className="button pull-right"
            />
          </div>
        </div>
      </form>
    );
  }

  /** Surrounds the content with a specific HTML markup
   * so that the design don't break when being logged in */
  renderContentContainer(content) {
    return (
      <div id="authentication">
        <div className="content">
          <Logo />
          <div className="formContainer">{content}</div>
        </div>
        <Footer />
      </div>
    );
  }

  renderSetPasswordFeedback() {
    return (
      <form>
        <div id="setPassword">
          <h3>Your password has been set</h3>
        </div>
        <div className="content" />
        {this.renderError()}
        <div className="footer">{this.renderLinkContext()}</div>
      </form>
    );
  }

  render() {
    if (this.state.requestSent) {
      const passwordFeedbackRendering = this.renderSetPasswordFeedback();
      if (this.props.isSignedIn) {
        return this.renderContentContainer(passwordFeedbackRendering);
      }
      return passwordFeedbackRendering;
    }

    const setPasswordRendering = this.renderSetPassword();
    if (this.props.isSignedIn) {
      return this.renderContentContainer(setPasswordRendering);
    }
    return setPasswordRendering;
  }
}

SetPassword.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string.isRequired,
    }),
  }).isRequired,
  isSignedIn: PropTypes.bool.isRequired,
  resetPasswordRequest: PropTypes.func,
};

export default SetPassword;
