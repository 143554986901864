import { connect } from 'react-redux';
import View from './xboxAccount.view.jsx';
import { requestXboxDisconnect } from '../../../../actions/authentication';
import { getXboxUrl } from '../../../../utilities/Utilities';

const mapStateToProps = state => ({
  xboxUrl: getXboxUrl(state.authentication.xboxState),
  userId: state.authentication.user.userId,
  isXboxConnected: state.authentication.isXboxConnected,
  isXboxConnectionFetched: state.authentication.isXboxConnectionFetched,
  xboxState: state.authentication.xboxState,
});

const mapDispatchToProps = {
  requestXboxDisconnect,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
