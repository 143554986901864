import React from 'react';
import PropTypes from 'prop-types';

class Redeem extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <div>
          <h2>Redeem Code</h2>
          <div className="summary darkContentBlock borderBottom">
            <div className="redeemCodeNotice">
              Redeem code functionality has been moved to the new account website. 
              In order to redeem a code, please go to <a href="https://beta-accounts.paradoxinteractive.com/redeem">Redeem Code</a>
            </div>
          </div>
          <div className="darkContentBlock center">
          </div>
        </div>
    );
  }
}

Redeem.propTypes = {
  userId: PropTypes.string.isRequired,
  invalidateGameState: PropTypes.func.isRequired,
};

export default Redeem;
