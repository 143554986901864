import { newsletter } from '../api/api';
import { errorAction, getToken, logError } from '../utilities/Utilities';
import { appendNotification } from './notifications';

const timeoutNotifications = 10000;

export const NEWSLETTER_STATUS_REQUEST = 'NEWSLETTER_STATUS_REQUEST';
export const NEWSLETTER_STATUS_SUCCESS = 'NEWSLETTER_STATUS_SUCCESS';
export const NEWSLETTER_STATUS_FAILURE = 'NEWSLETTER_STATUS_FAILURE';
export const newsletterStatusFailure = (error, meta) =>
  errorAction(NEWSLETTER_STATUS_FAILURE, error, meta);

export const newsletterStatusRequest = () => async(dispatch, getState) => {
  try {
    dispatch({ type: NEWSLETTER_STATUS_REQUEST });
    const resp = await newsletter.status(getToken(getState));
    dispatch({
      type: NEWSLETTER_STATUS_SUCCESS,
      subscriber: resp.subscriber,
    });
  } catch (e) {
    logError('Error in React action newsletterStatusRequest', e);
    let errorMessage = 'Unable to get newsletter status.';
    if (e.errorCode === 'third-party-exception') {
      errorMessage = 'Unable to reach newsletter service. Please try again later.';
    }
    dispatch(
      appendNotification('Settings', {
        text: errorMessage,
        timing: timeoutNotifications,
      }),
    );
  }
};

export const SUBSCRIBE_NEWSLETTER_REQUEST = 'SUBSCRIBE_NEWSLETTER_REQUEST';
export const SUBSCRIBE_NEWSLETTER_SUCCESS = 'SUBSCRIBE_NEWSLETTER_SUCCESS';

export const subscribeNewsletterRequest = verified => async(dispatch, getState) => {
  try {
    dispatch({ type: SUBSCRIBE_NEWSLETTER_REQUEST });
    await newsletter.subscribe(getToken(getState));

    dispatch({
      type: SUBSCRIBE_NEWSLETTER_SUCCESS,
    });
    if (verified) {
      dispatch(
        appendNotification('Settings', {
          text: 'Thanks for signing up! We have sent you an email with a link to confirm your email address.',
          timing: timeoutNotifications,
        }),
      );
    }
  } catch (e) {
    logError('Error in React action subscribeNewsletterRequest', e);
    let errorMessage = 'Could not subscribe to newsletter.';
    if (e.errorCode === 'third-party-exception') {
      errorMessage = 'Unable to reach newsletter service. Please try again later.';
    }
    dispatch(
      appendNotification('Settings', {
        text: errorMessage,
        timing: timeoutNotifications,
      }),
    );
  }
};

export const UNSUBSCRIBE_NEWSLETTER_REQUEST = 'UNSUBSCRIBE_NEWSLETTER_REQUEST';
export const UNSUBSCRIBE_NEWSLETTER_SUCCESS = 'UNSUBSCRIBE_NEWSLETTER_SUCCESS';

export const unsubscribeNewsletterRequest = () => async(dispatch, getState) => {
  try {
    dispatch({ type: UNSUBSCRIBE_NEWSLETTER_REQUEST });
    await newsletter.unsubscribe(getToken(getState));

    dispatch({
      type: UNSUBSCRIBE_NEWSLETTER_SUCCESS,
    });
    dispatch(
      appendNotification('Settings', {
        text: 'You have been unsubscribed.',
        timing: timeoutNotifications,
      }),
    );
  } catch (e) {
    logError('Error in React action unsubscribeNewsletterRequest', e);
    let errorMessage = 'Could not unsubscribe from newsletter.';
    if (e.errorCode === 'third-party-exception') {
      errorMessage = 'Unable to reach newsletter service. Please try again later.';
    }
    dispatch(
      appendNotification('Settings', {
        text: errorMessage,
        timing: timeoutNotifications,
      }),
    );
  }
};

export const SUBSCRIBE_GROUP_NEWSLETTER_REQUEST = 'SUBSCRIBE_GROUP_NEWSLETTER_REQUEST';
export const SUBSCRIBE_GROUP_NEWSLETTER_SUCCESS = 'SUBSCRIBE_GROUP_NEWSLETTER_SUCCESS';

export const subscribeGroupNewsletterRequest = group => async(dispatch, getState) => {
  try {
    dispatch({ type: SUBSCRIBE_GROUP_NEWSLETTER_REQUEST });
    await newsletter.updateGroup(group.id, true, getToken(getState));

    dispatch({
      type: SUBSCRIBE_GROUP_NEWSLETTER_SUCCESS,
      group,
    });
    dispatch(
      appendNotification('Settings', {
        text: 'Newsletter options updated.',
        timing: timeoutNotifications,
      }),
    );
  } catch (e) {
    logError('Error in React action subscribeGroupNewsletterRequest', e);
    let errorMessage = 'Could not subscribe to group newsletter.';
    if (e.errorCode === 'third-party-exception') {
      errorMessage = 'Unable to reach newsletter service. Please try again later.';
    }
    dispatch(
      appendNotification('Settings', {
        text: errorMessage,
        timing: timeoutNotifications,
      }),
    );
  }
};

export const UNSUBSCRIBE_GROUP_NEWSLETTER_REQUEST = 'UNSUBSCRIBE_GROUP_NEWSLETTER_REQUEST';
export const UNSUBSCRIBE_GROUP_NEWSLETTER_SUCCESS = 'UNSUBSCRIBE_GROUP_NEWSLETTER_SUCCESS';

export const unsubscribeGroupNewsletterRequest = group => async(dispatch, getState) => {
  try {
    dispatch({ type: UNSUBSCRIBE_GROUP_NEWSLETTER_REQUEST });
    await newsletter.updateGroup(group.id, false, getToken(getState));

    dispatch({
      type: UNSUBSCRIBE_GROUP_NEWSLETTER_SUCCESS,
      group,
    });
    dispatch(
      appendNotification('Settings', {
        text: 'Newsletter options updated.',
        timing: timeoutNotifications,
      }),
    );
  } catch (e) {
    logError('Error in React action unsubscribeGroupNewsletterRequest', e);
    let errorMessage = 'Could not unsubscribe from group newsletter.';
    if (e.errorCode === 'third-party-exception') {
      errorMessage = 'Unable to reach newsletter service. Please try again later.';
    }
    dispatch(
      appendNotification('Settings', {
        text: errorMessage,
        timing: timeoutNotifications,
      }),
    );
  }
};

export const NEWSLETTER_CONFIRMATION_SUCCESS = 'NEWSLETTER_CONFIRMATION_SUCCESS';
