import { push } from 'react-router-redux';
import Util, { errorAction, getToken, logError } from '../utilities/Utilities';
import {
  redeem,
  achievements,
  accounts,
  inventory,
  steam,
  psn,
  xbox,
  twitch,
  epic,
} from '../api/api';
import { appendNotification, removeNotification } from './notifications';
import { NEWSLETTER_CONFIRMATION_SUCCESS } from './newsletter';

const { ACCOUNT, SUBSCRIPTION, ACCOUNT_SUBSCRIPTION } = accounts;

export const ACHIEVEMENTS_PREFIXES = [
  { namespace: 'stellaris', prefix: 'STST01' },
  { namespace: 'tyranny', prefix: 'TYTY01' },
  { namespace: 'surviving_mars', prefix: 'SUSM01' },
  { namespace: 'aow_planetfall', prefix: 'PFPF01' },
];

const timeoutNotifications = 10000;

const handleSignIn = async function(email, session) {
  try {
    const data = await accounts.accountDetails(session.token);

    Util.setSessionCookie(session.token);

    return {
      user: {
        session,
        email: email || data.account.email,
        userId: session.userid,
        accountDetails: data.accountDetails,
        account: data.account,
      },
    };
  } catch (error) {
    logError('Error in React action handleSignIn', error);
    throw new Error('Unable to get account details.');
  }
};

export const LOGOUT = 'LOGOUT';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const logoutRequest = sessionToken => async dispatch => {
  try {
    if (sessionToken) {
      await accounts.deleteSession(sessionToken);
    }
    Util.clearCookies();
    Util.clearLocalStorage();
    dispatch({ type: LOGOUT });
  } catch (error) {
    logError('Error in React action logoutRequest', error);
    dispatch({ type: LOGOUT_FAILURE, payload: error, error: true });
  }
};

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const resetPasswordRequest = (newPassword, token, recaptchaResponse) => async dispatch => {
  try {
    dispatch({ type: RESET_PASSWORD_REQUEST, payload: { newPassword, token, recaptchaResponse } });
    await accounts.reset(newPassword, token, recaptchaResponse);
    dispatch({ type: RESET_PASSWORD_SUCCESS, payload: { newPassword, token, recaptchaResponse } });
  } catch (error) {
    logError('Error in React action resetPasswordRequest', error);
    throw error;
  }
};

export const RESET_PASSWORD_EMAIL_REQUEST = 'RESET_PASSWORD_EMAIL_REQUEST';
export const RESET_PASSWORD_EMAIL_SUCCESS = 'RESET_PASSWORD_EMAIL_SUCCESS';

export const resetPasswordEmailRequest = email => async (dispatch, getState) => {
  try {
    dispatch({ type: RESET_PASSWORD_EMAIL_REQUEST, payload: email });
    await accounts.requestResetPassword(email, getToken(getState));
    dispatch({ type: RESET_PASSWORD_EMAIL_SUCCESS, payload: email });
    dispatch(
      appendNotification('Settings', {
        text:
          'A confirmation request has been sent to your inbox. ' +
          'It may take a few minutes to arrive. Once you get it, please click ' +
          'on the link in it to complete your password change.',
        timing: timeoutNotifications,
      }),
    );
  } catch (error) {
    logError('Error in React action resetPasswordEmailRequest', error);
    dispatch(
      appendNotification('Settings', {
        text: 'Unable to request a password reset email, please try again.',
        timing: timeoutNotifications,
      }),
    );
    throw error;
  }
};

export const emailVerified = () => async dispatch => {
  try {
    dispatch(
      appendNotification('UnverifiedEmail', {
        name: 'UnverifiedEmail',
      }),
    );
  } catch (error) {
    logError('Error in React action emailVerified', error);
    const errorMessage = 'Error when contacting server.';
    dispatch(
      appendNotification('Settings', {
        text: errorMessage,
        timing: timeoutNotifications,
      }),
    );
  }
};

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const loginSuccess = user => dispatch => {
  dispatch({
    type: LOGIN_SUCCESS,
    user,
  });
};

export const loginRequest = (email, password, landingUrl) => async dispatch => {
  try {
    dispatch({ type: LOGIN_REQUEST });
    const resp = await accounts.userAuthenticate(email, password);
    const accountDetails = await handleSignIn(email, resp.session);
    if (!accountDetails.user.account.isEmailVerified) {
      dispatch(
        appendNotification('UnverifiedEmail', {
          name: 'UnverifiedEmail',
        }),
      );
    }
    const allowedHostnames = ['test-login.paradoxplaza.com','staging-login.paradoxplaza.com','sandbox-login.paradoxplaza.com','login.paradoxplaza.com']
    if(landingUrl && allowedHostnames.includes(new URL(landingUrl).hostname)) {
      window.location.replace(landingUrl);
    }
    else {
      dispatch(loginSuccess(accountDetails.user));
      dispatch(
        appendNotification('BetaAccounts', {
          name: 'BetaAccounts',
        }),
      );
      if(landingUrl) {
        if (landingUrl.indexOf('http') === 0 && accountDetails.user.account.isEmailVerified) {
          window.location.replace(landingUrl);
        } else if (landingUrl.indexOf('http') !== 0) {
          dispatch(push(landingUrl));
        }
      }
    }
  } catch (error) {
    logError('Error in React action loginRequest', error);
    let errorMessage;
    if (error.errorCode && error.errorCode === 'not-authorized') {
      errorMessage = 'Wrong username or password.';
    } else {
      errorMessage = 'Error when contacting server.';
    }
    throw errorMessage;
  }
};

export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';

export const signupFailure = (error, meta) => errorAction(SIGNUP_FAILURE, error, meta);

export const signupRequest = (
  formObj,
  landingUrl,
  sourceService,
  recaptchaResponse,
) => async dispatch => {
  try {
    const response = await accounts.signupRequest(
      formObj,
      landingUrl,
      sourceService,
      recaptchaResponse,
    );
    if (response.result === 'OK') {
      return dispatch(
        loginRequest(formObj.email, formObj.password, landingUrl),
      );
    }
    const errorMessage =
      response.errorCode === 'ua'
        ? 'You need to be over 16 years old to sign up for an account.'
        : 'Unable to sign-up.';
    throw (errorMessage, response.errorCode);
  } catch (error) {
    logError('Error in React action signupRequest', error);
    throw error;
  }
};

export const CONFIRMATION_REQUEST = 'CONFIRMATION_REQUEST';
export const CONFIRMATION_SUCCESS = 'CONFIRMATION_SUCCESS';
export const CONFIRMATION_FAILURE = 'CONFIRMATION_FAILURE';

export const confirmationFailure = (error, meta) => errorAction(CONFIRMATION_FAILURE, error, meta);

export const confirmationRequest = (token, type = ACCOUNT, interest) => async dispatch => {
  try {
    dispatch({ type: CONFIRMATION_REQUEST });

    const resp = await accounts.confirm(token, type, interest);

    if (type === SUBSCRIPTION || type === ACCOUNT_SUBSCRIPTION) {
      dispatch({ type: NEWSLETTER_CONFIRMATION_SUCCESS });
    }

    dispatch(removeNotification({ name: 'UnverifiedEmail' }));

    dispatch({
      type: CONFIRMATION_SUCCESS,
    });

    if (resp.landingUrl) {
      const url = resp.landingUrl;
      if (url.indexOf('http') === 0) {
        window.location.replace(url);
      } else {
        dispatch(push(url));
      }
    }
  } catch (error) {
    logError('Error in React action confirmationRequest', error);
    dispatch(confirmationFailure(error, 'Could not send confirmation email.'));
    throw error;
  }
};

export const INVALIDATE_GAMES = 'INVALIDATE_GAMES';
export const invalidateGameState = () => async dispatch => {
  dispatch({ type: INVALIDATE_GAMES });
};

export const STEAM_CONNECTED = 'STEAM_CONNECTED';
export const STEAM_NOT_CONNECTED = 'STEAM_NOT_CONNECTED';
export const STEAM_CONNECT_EXPIRED = 'STEAM_CONNECT_EXPIRED';

export const PSN_CONNECTED = 'PSN_CONNECTED';
export const PSN_NOT_CONNECTED = 'PSN_NOT_CONNECTED';

export const XBOX_CONNECTED = 'XBOX_CONNECTED';
export const XBOX_NOT_CONNECTED = 'XBOX_NOT_CONNECTED';

export const TWITCH_CONNECTED = 'TWITCH_CONNECTED';
export const TWITCH_NOT_CONNECTED = 'TWITCH_NOT_CONNECTED';

export const EPIC_CONNECTED = 'EPIC_CONNECTED';
export const EPIC_NOT_CONNECTED = 'EPIC_NOT_CONNECTED';

export const requestConnections = userId => async (dispatch, getState) => {
  try {
    if (
      !getState().authentication.isSteamConnectionFetched ||
      !getState().authentication.isPsnConnectionFetched
    ) {
      const {
        psnIsConnected,
        xboxIsConnected,
        steamIsConnected,
        steamExpiredTime,
        twitchIsConnected,
        epicIsConnected,
      } = await accounts.checkConnections(userId);
      if (psnIsConnected) {
        dispatch({ type: PSN_CONNECTED });
      }
      if (xboxIsConnected) {
        dispatch({ type: XBOX_CONNECTED });
      }
      if (twitchIsConnected) {
        dispatch({ type: TWITCH_CONNECTED });
      }
      if (epicIsConnected) {
        dispatch({ type: EPIC_CONNECTED });
      }
      if (steamIsConnected) {
        if (steamExpiredTime) {
          dispatch(
            appendNotification('ExpiredSteamConnection', {
              name: 'ExpiredSteamConnection',
            }),
          );
          dispatch({ type: STEAM_CONNECT_EXPIRED, expired: steamExpiredTime });
        }
        dispatch({ type: STEAM_CONNECTED });
      } else if (!psnIsConnected) {
        dispatch({ type: PSN_NOT_CONNECTED });
      } else if (!xboxIsConnected) {
        dispatch({ type: XBOX_NOT_CONNECTED });
      } else if (!twitchIsConnected) {
        dispatch({ type: TWITCH_NOT_CONNECTED });
      } else if (!epicIsConnected) {
        dispatch({ type: EPIC_NOT_CONNECTED });
      } else if (!steamIsConnected) {
        dispatch(
          appendNotification('ConnectSteam', {
            name: 'ConnectSteam',
            text: '',
          }),
        );
        dispatch({ type: STEAM_NOT_CONNECTED });
      }
    } else {
      if (!getState().authentication.isSteamConnected) {
        dispatch(
          appendNotification('ConnectSteam', {
            name: 'ConnectSteam',
            text: '',
          }),
        );
      }
    }
  } catch (error) {
    dispatch({ type: STEAM_NOT_CONNECTED });
    dispatch({ type: PSN_NOT_CONNECTED });
    dispatch({ type: TWITCH_NOT_CONNECTED });
    dispatch({ type: EPIC_NOT_CONNECTED });
  }
};

export const STEAM_CONNECT_REQUEST = 'STEAM_CONNECT_REQUEST';
export const STEAM_CONNECT_SUCCESS = 'STEAM_CONNECT_SUCCESS';
export const STEAM_CONNECT_FAILURE = 'STEAM_CONNECT_FAILURE';

export const steamConnectionFailure = (error, meta) =>
  errorAction(STEAM_CONNECT_FAILURE, error, meta);

export const steamConnectRequest = (userId, steamId, steamToken) => async dispatch => {
  try {
    const resp = await (await steam.connect(userId, steamId, steamToken)).json();

    if (!resp.connected) {
      let errorMessage = 'Could not link to Steam.';
      if (resp.errors) {
        errorMessage += ` ${resp.errors.join(', ')}`;
      }
      throw new Error(errorMessage);
    }

    dispatch({ type: INVALIDATE_GAMES });
    dispatch({ type: STEAM_CONNECT_SUCCESS });
    return resp;
  } catch (error) {
    logError('Error in React action steamConnectRequest', error);
    dispatch(steamConnectionFailure(error, 'Unable to link to Steam, please try again'));
    throw error;
  }
};

export const steamReconnectRequest = () => {
  console.log('Reconnect');
};

export const STEAM_DISCONNECT_FAILURE = 'STEAM_CONNECT_FAILURE';
export const steamDisonnectionFailure = (error, meta) =>
  errorAction(STEAM_DISCONNECT_FAILURE, error, meta);

export const requestSteamDisconnect = userId => async dispatch => {
  try {
    await steam.disconnect(userId);

    dispatch({ type: STEAM_NOT_CONNECTED });
    dispatch(
      appendNotification('Settings', {
        text: 'Your Steam account has now been unlinked.',
        timing: timeoutNotifications,
      }),
    );
  } catch (error) {
    logError('Error in React action requestSteamDisconnect', error);
    dispatch(steamDisonnectionFailure(error, 'Unable to unlink from Steam, please try again.'));
  }
};

export const PSN_CONNECT_REQUEST = 'PSN_CONNECT_REQUEST';
export const PSN_CONNECT_SUCCESS = 'PSN_CONNECT_SUCCESS';
export const PSN_CONNECT_FAILURE = 'PSN_CONNECT_FAILURE';

export const psnConnectionFailure = (error, meta) =>
  errorAction(PSN_CONNECT_FAILURE, error, meta);

export const psnConnectRequest = (userId, psnCode) => async dispatch => {
  try {
    const resp = await (await psn.connect(userId, psnCode)).json();
    if (!resp.connected) {
      let errorMessage = 'Could not link to Psn.';
      if (resp.errors) {
        errorMessage += ` ${resp.errors.join(', ')}`;
      }
      throw new Error(errorMessage);
    }
    dispatch({ type: PSN_CONNECT_SUCCESS });
    return resp;
  } catch (error) {
    logError('Error in React action psnConnectRequest', error);
    dispatch(psnConnectionFailure(error, 'Unable to link to PSN, please try again'));
    throw error;
  }
};

export const psnReconnectRequest = () => {
  console.log('Reconnect');
};

export const PSN_DISCONNECT_FAILURE = 'PSN_DISCONNECT_FAILURE';
export const psnDisonnectionFailure = (error, meta) =>
  errorAction(PSN_DISCONNECT_FAILURE, error, meta);

export const requestPsnDisconnect = userId => async dispatch => {
  try {
    await psn.disconnect(userId);

    dispatch({ type: PSN_NOT_CONNECTED });
    dispatch(
      appendNotification('Settings', {
        text: 'Your PSN account has now been unlinked.',
        timing: timeoutNotifications,
      }),
    );
  } catch (error) {
    logError('Error in React action requestPsnDisconnect', error);
    dispatch(psnDisonnectionFailure(error, 'Unable to unlink from PSN, please try again.'));
  }
};

export const XBOX_CONNECT_REQUEST = 'XBOX_CONNECT_REQUEST';
export const XBOX_CONNECT_SUCCESS = 'XBOX_CONNECT_SUCCESS';
export const XBOX_CONNECT_FAILURE = 'XBOX_CONNECT_FAILURE';

export const xboxConnectionFailure = (error, meta) =>
  errorAction(XBOX_CONNECT_FAILURE, error, meta);

export const xboxConnectRequest = (userId, xboxCode) => async dispatch => {
  try {
    const resp = await (await xbox.connect(userId, xboxCode)).json();
    if (!resp.connected) {
      let errorMessage = 'Could not link to XBOX.';
      if (resp.errors) {
        errorMessage += ` ${resp.errors.join(', ')}`;
      }
      throw new Error(errorMessage);
    }
    dispatch({ type: XBOX_CONNECT_SUCCESS });
    return resp;
  } catch (error) {
    logError('Error in React action xboxConnectRequest', error);
    dispatch(xboxConnectionFailure(error, 'Unable to link to XBOX, please try again'));
    throw error;
  }
};

export const xboxReconnectRequest = () => {
  console.log('Reconnect');
};

export const XBOX_DISCONNECT_FAILURE = 'XBOX_DISCONNECT_FAILURE';
export const xboxDisonnectionFailure = (error, meta) =>
  errorAction(XBOX_DISCONNECT_FAILURE, error, meta);

export const requestXboxDisconnect = userId => async dispatch => {
  try {
    await xbox.disconnect(userId);

    dispatch({ type: XBOX_NOT_CONNECTED });
    dispatch(
      appendNotification('Settings', {
        text: 'Your Xbox account has now been unlinked.',
        timing: timeoutNotifications,
      }),
    );
  } catch (error) {
    logError('Error in React action requestXboxDisconnect', error);
    dispatch(xboxDisonnectionFailure(error, 'Unable to unlink from Xbox, please try again.'));
  }
};

export const TWITCH_CONNECT_REQUEST = 'TWITCH_CONNECT_REQUEST';
export const TWITCH_CONNECT_SUCCESS = 'TWITCH_CONNECT_SUCCESS';
export const TWITCH_CONNECT_FAILURE = 'TWITCH_CONNECT_FAILURE';

export const twitchConnectionFailure = (error, meta) =>
  errorAction(TWITCH_CONNECT_FAILURE, error, meta);

export const twitchConnectRequest = (code, redirectUri, nonce) => async dispatch => {
  try {
    const resp = await (await twitch.connect(code, redirectUri, nonce)).json();

    if (!resp.connected) {
      let errorMessage = 'Could not connect to Twitch';
      if (resp.error) {
        errorMessage += ` (${resp.error})`;
      }
      throw new Error(errorMessage);
    }
    dispatch({ type: TWITCH_CONNECT_SUCCESS });
    return resp;
  } catch (error) {
    logError('Error in React action twitchConnectRequest', error);
    dispatch(twitchConnectionFailure(error, 'Unable to link to Twitch, please try again'));
    throw error;
  }
};

export const twitchReconnectRequest = () => {
  console.log('Reconnect');
};

export const TWITCH_DISCONNECT_FAILURE = 'TWITCH_DISCONNECT_FAILURE';
export const twitchDisconnectionFailure = (error, meta) =>
  errorAction(TWITCH_DISCONNECT_FAILURE, error, meta);

export const requestTwitchDisconnect = () => async dispatch => {
  try {
    await twitch.disconnect();

    dispatch({ type: TWITCH_NOT_CONNECTED });
    dispatch(
      appendNotification('Settings', {
        text: 'Your Twitch account has now been unlinked.',
        timing: timeoutNotifications,
      }),
    );
  } catch (error) {
    logError('Error in React action requestTwitchDisconnect', error);
    dispatch(twitchDisconnectionFailure(error, 'Unable to unlink from Twitch, please try again.'));
  }
};

export const TWITCH_CLAIM_FAILURE = 'TWITCH_CLAIM_FAILURE';
export const twitchClaimDropsFailure = (error, meta) =>
  errorAction(TWITCH_CLAIM_FAILURE, error, meta);

export const requestTwitchDrops = () => async (dispatch, getState) => {
  try {
    const entitlements = await twitch.getDrops(getToken(getState));
    const numberOfEntitlements = entitlements.processedEntitlements;
    let notificationText = 'You have no Twitch drops to claim.';
    if (numberOfEntitlements !== 0) {
      notificationText = `You claimed ${numberOfEntitlements} Twitch ${numberOfEntitlements === 1 ? 'drop' : 'drops'}!`;
    }
    dispatch(
      appendNotification('Redeem', {
        text: notificationText,
        timing: timeoutNotifications,
      }),
    );
  } catch (error) {
    if (error && error.subCategory === 'twitch-refresh-failure') {
      logError('Error in React action requestTwitchDrops', error);
      dispatch(
        appendNotification('RefreshTwitchConnection', {
          name: 'RefreshTwitchConnection',
        }),
      );
    } else {
      logError('Error in React action requestTwitchDrops', error);
      dispatch(
        appendNotification('Redeem', {
          text: 'Failed to claim drops',
          timing: timeoutNotifications,
        }),
      );
      dispatch(twitchClaimDropsFailure(error, 'Unable to claim your Twitch drops.'));
    }
  }
};

export const EPIC_CONNECT_REQUEST = 'EPIC_CONNECT_REQUEST';
export const EPIC_CONNECT_SUCCESS = 'EPIC_CONNECT_SUCCESS';
export const EPIC_CONNECT_FAILURE = 'EPIC_CONNECT_FAILURE';
export const EPIC_DISCONNECT_FAILURE = 'EPIC_DISCONNECT_FAILURE';

export const epicConnectionFailure = (error, meta) =>
  errorAction(EPIC_CONNECT_FAILURE, error, meta);

export const requestEpicConnect = (code, redirectUri, nonce) => async dispatch => {
  try {
    const resp = await (await epic.connect(code, redirectUri, nonce)).json();

    if (!resp.connected) {
      let errorMessage = 'Could not connect to Epic';
      if (resp.error) {
        errorMessage += ` (${resp.error})`;
      }
      throw new Error(errorMessage);
    }
    dispatch({ type: EPIC_CONNECT_SUCCESS });
    return resp;
  } catch (error) {
    logError('Error in React action epicConnectRequest', error);
    dispatch(epicConnectionFailure(error, 'Unable to link to Epic, please try again'));
    throw error;
  }
};

export const epicDisconnectionFailure = (error, meta) =>
  errorAction(EPIC_DISCONNECT_FAILURE, error, meta);

export const requestEpicDisconnect = () => async dispatch => {
  try {
    await epic.disconnect();

    dispatch({ type: EPIC_NOT_CONNECTED });
    dispatch(
      appendNotification('Settings', {
        text: 'Your Epic account has now been unlinked.',
        timing: timeoutNotifications,
      }),
    );
  } catch (error) {
    logError('Error in React action requestEpicDisconnect', error);
    dispatch(epicDisconnectionFailure(error, 'Unable to unlink from Epic, please try again.'));
  }
};

export const ACCOUNT_DETAILS_UPDATE_REQUEST = 'ACCOUNT_DETAILS_UPDATE_REQUEST';
export const ACCOUNT_DETAILS_UPDATE_SUCCESS = 'ACCOUNT_DETAILS_UPDATE_SUCCESS';

export const requestAccountDetailsUpdate = accountDetailsObj => async (dispatch, getState) => {
  try {
    await accounts.updateAccountDetails(accountDetailsObj, getToken(getState));
    dispatch({
      type: ACCOUNT_DETAILS_UPDATE_SUCCESS,
      accountDetails: accountDetailsObj,
    });
    dispatch(
      appendNotification('Settings', {
        text: 'Account details updated.',
        timing: timeoutNotifications,
      }),
    );
  } catch (error) {
    if (error.errorCode === 'ua') {
      dispatch(
        appendNotification('Settings', {
          text: 'You need to be over 16 years old to have an account.',
          timing: timeoutNotifications,
        }),
      );
    } else {
      dispatch(
        appendNotification('Settings', {
          text: 'An error occurred, please try again.',
          timing: timeoutNotifications,
        }),
      );
    }
    logError('Error in React action requestAccountDetailsUpdate', error);
    throw error.errorCode;
  }
};

export const GAMES_SUCCESS = 'GAMES_SUCCESS';
export const GAMES_REQUEST_FINSIHED = 'GAMES_REQUEST_FINSIHED';

export const requestGames = () => async (dispatch, getState) => {
  if (getState().authentication.gameStateValid) {
    const games = getState().authentication.games;
    dispatch({ type: GAMES_SUCCESS, games });
    return;
  }

  try {
    const games = await inventory.games(getToken(getState));
    dispatch({ type: GAMES_REQUEST_FINSIHED });
    dispatch({ type: GAMES_SUCCESS, games });
  } catch (error) {
    logError('Error in React action requestGames', error);
    let errorText;
    switch (error.message) {
      case 'product-error':
        errorText = 'An error occurred when trying to load your games. Please try again later.';
        break;
      case 'key-error':
        errorText = 'An error occurred when trying to load your games. Please try again later.';
        break;
      default:
        errorText = 'Connection error, please try reloading the page.';
    }
    dispatch(
      appendNotification('Game', {
        text: errorText,
        timing: timeoutNotifications,
      }),
    );
  }
};

export const DOWNLOADS_SUCCESS = 'DOWNLOADS_SUCCESS';
export const requestDownloads = () => async (dispatch, getState) => {
  try {
    const downloads = await inventory.downloads(getToken(getState));

    dispatch({
      type: DOWNLOADS_SUCCESS,
      downloads,
    });
  } catch (error) {
    logError('Error in React action requestDownloads', error);
    dispatch(
      appendNotification('Download', {
        text: 'Connection error, please try again.',
        timing: timeoutNotifications,
      }),
    );
  }
};

export const ORDERS_SUCCESS = 'ORDERS_SUCCESS';
export const ORDERS_FAILURE = 'ORDERS_FAILURE';
export const ordersFailure = (error, meta) => errorAction(ORDERS_FAILURE, error, meta);

export const requestOrders = ({ getAllOrders }) => async (dispatch, getState) => {
  try {
    const userId = await getState().authentication.user.userId;
    const orderHistory = getAllOrders
      ? await inventory.allOrders(userId, getToken(getState))
      : await inventory.latestOrders(userId, getToken(getState));

    dispatch({
      type: ORDERS_SUCCESS,
      userHasMoreOrders: orderHistory.userHasMoreOrders,
      orders: orderHistory.orders,
    });
  } catch (error) {
    logError('Error in React action requestOrders', error);
    dispatch(ordersFailure(error, 'Could not load your orders.'));
  }
};

export const SET_EMAIL_REQUEST = 'SET_EMAIL_REQUEST';
export const SET_EMAIL_SUCCESS = 'SET_EMAIL_SUCCESS';
export const setEmailSuccess = newEmail => ({
  type: SET_EMAIL_SUCCESS,
  newEmail,
});

export const setEmailRequest = (oldEmail, password, newEmail) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_EMAIL_REQUEST });

    await accounts.setEmail(oldEmail, password, newEmail, getToken(getState));
    dispatch(
      appendNotification('Settings', {
        text:
          'A message has been sent to the new email address you specified. ' +
          'Open it and click on the link to confirm the email address change.',
        timing: timeoutNotifications,
      }),
    );
    dispatch(setEmailSuccess(newEmail));
  } catch (error) {
    const errorCodeMap = {
      'not-authorized': 'You’ve entered the wrong password',
      'account-exists': 'The new email address is already in use, please use another',
    };
    let errorMessage = 'We were not able to change your email address';
    if (Object.prototype.hasOwnProperty.call(errorCodeMap, error.errorCode)) {
      errorMessage = errorCodeMap[error.errorCode];
    }
    dispatch(
      appendNotification('Settings', {
        text: `Unable to change your email. ${errorMessage}`,
        timing: timeoutNotifications,
      }),
    );
  }
};

export const REFRESH_SESSION_REQUEST = 'REFRESH_SESSION_REQUEST';
export const REFRESH_SESSION_SUCCESS = 'REFRESH_SESSION_SUCCESS';
export const REFRESH_SESSION_FAILURE = 'REFRESH_SESSION_FAILURE';
export const refreshSessionSuccess = user => ({
  type: REFRESH_SESSION_SUCCESS,
  user,
});
export const refreshSessionFailure = (error, meta) =>
  errorAction(REFRESH_SESSION_FAILURE, error, meta);

export const refreshSessionRequest = sessionToken => async dispatch => {
  try {
    dispatch({ type: REFRESH_SESSION_REQUEST });
    let formattedResponse;
    try {
      const getUserResponse = await accounts.getUser(sessionToken);
      formattedResponse = {
        userid: getUserResponse.id,
        namespace: 'accounts',
        token: sessionToken,
      };
    } catch (error) {
      if (error.errorCode === 'not-authorized') {
        const createSessionResponse = await accounts.createSession(sessionToken);
        Util.setSessionCookie(createSessionResponse.session.token);
        formattedResponse = createSessionResponse.session;
      } else {
        throw error;
      }
    }
    const signinResponse = await handleSignIn(null, formattedResponse);
    const user = signinResponse.user;
    if (user && user.session && user.session.token) {
      dispatch(refreshSessionSuccess(user));
    } else {
      dispatch(logoutRequest());
    }
  } catch (error) {
    logError('Error in React action refreshSessionRequest', error);
    Util.clearCookies();
    Util.clearLocalStorage();
    dispatch(refreshSessionFailure(error, 'Unable to refresh session.'));
  }
};

export const CONFIRMATION_EMAIL_REQUEST = 'CONFIRMATION_EMAIL_REQUEST';
export const CONFIRMATION_EMAIL_SUCCESS = 'CONFIRMATION_EMAIL_SUCCESS';

export const confirmationEmailRequest = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CONFIRMATION_EMAIL_REQUEST });
    await accounts.resendConfirmationEmail(getToken(getState));
    dispatch({ type: CONFIRMATION_EMAIL_SUCCESS });
  } catch (error) {
    logError('Error in React action confirmationEmailRequest', error);
    dispatch(
      appendNotification('Settings', {
        text: 'Unable to send email confirmation request, please try again.',
        timing: timeoutNotifications,
      }),
    );
  }
};

export const GAME_ACHIEVEMENTS_REQUEST = 'GAME_ACHIEVEMENTS_REQUEST';
export const GAME_ACHIEVEMENTS_SUCCESS = 'GAME_ACHIEVEMENTS_SUCCESS';
export const GAME_ACHIEVEMENTS_FAILURE = 'GAME_ACHIEVEMENTS_FAILURE';
export const gameAchievementsFailure = (error, meta) =>
  errorAction(GAME_ACHIEVEMENTS_FAILURE, error, meta);

export const gameAchievementsRequest = gameSku => async (dispatch, getState) => {
  try {
    dispatch({ type: GAME_ACHIEVEMENTS_REQUEST });

    const gameOptions = ACHIEVEMENTS_PREFIXES.find(options => gameSku.startsWith(options.prefix));
    if (!gameOptions) {
      dispatch(gameAchievementsFailure(`No achievements available for game ${gameSku}.`));
    } else {
      const resp = await achievements.userdataWithMeta(gameOptions.namespace, getToken(getState));
      const nonEmptyAchievements = resp.achievements.filter(
        item => item.titles && item.titles['en-US'],
      );

      dispatch({
        type: GAME_ACHIEVEMENTS_SUCCESS,
        gameAchievements: nonEmptyAchievements,
        gameSku,
      });
    }
  } catch (error) {
    logError('Error in React action gameAchievementsRequest', error);
    dispatch(
      gameAchievementsFailure(
        error,
        'Unable to load game achievements, please try reloading the page.',
      ),
    );
  }
};

export const ACTIVATE_GAME_KEY_REQUEST = 'ACTIVATE_GAME_KEY_REQUEST';
export const ACTIVATE_GAME_KEY_SUCCESS = 'ACTIVATE_GAME_KEY_SUCCESS';
export const ACTIVATE_GAME_KEY_FAILURE = 'ACTIVATE_GAME_KEY_FAILURE';
export const activateGameKeyFailure = (error, meta) =>
  errorAction(ACTIVATE_GAME_KEY_FAILURE, error, meta);

export const activateGameKeyRequest = (gameSku, key) => async dispatch => {
  try {
    dispatch({ type: ACTIVATE_GAME_KEY_REQUEST });

    await redeem.steamKey(key);

    dispatch({
      type: ACTIVATE_GAME_KEY_SUCCESS,
      gameSku,
    });
    dispatch({
      type: INVALIDATE_GAMES,
    });
    dispatch(
      appendNotification('Settings', {
        text: 'Game key activated.',
        timing: timeoutNotifications,
      }),
    );
  } catch (error) {
    logError('Error in React action activateGameKeyRequest', error);
    dispatch(activateGameKeyFailure(error, 'Unable to activate game key, please try again.'));
    throw error;
  }
};

export const REFERRAL_CODE_SUCCESS = 'ACTIVATE_GAME_KEY_REQUEST';

export const fetchReferralCode = userId => async dispatch => {
  try {
    if (!userId) {
      return;
    }
    const { referral_code: referralCode } = await accounts.fetchReferralCode(userId);

    dispatch({ type: REFERRAL_CODE_SUCCESS, referralCode });
  } catch (error) {
    logError('Failed fetching user referral code', error);
  }
};
