import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { resetPasswordEmailRequest } from '../../../actions/authentication';

class PasswordRequest extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      requestedPasswordReset: false,
    };
    this.handlePasswordReset = this.handlePasswordReset.bind(this);
  }

  handlePasswordReset() {
    this.props.resetPasswordEmailRequest(this.props.email);

    this.setState({
      requestedPasswordReset: true,
    });

    setTimeout(() => this.setState({ requestedPasswordReset: false }), 5000);
  }

  render() {
    return (
      <button
        onClick={this.handlePasswordReset}
        disabled={this.state.requestedPasswordReset}
        className="button-outline"
      >
        Request Password Change
      </button>
    );
  }
}

PasswordRequest.propTypes = {
  email: PropTypes.string.isRequired,
  resetPasswordEmailRequest: PropTypes.func,
};

const mapDispatchToProps = {
  resetPasswordEmailRequest,
};

export default connect(null, mapDispatchToProps)(PasswordRequest);
