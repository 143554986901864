import React from 'react';
import PropTypes from 'prop-types';
import gtmParts from 'react-google-tag-manager';

class GoogleTagManager extends React.Component {
  componentDidMount() {
    if (!window[this.props.dataLayerName]) {
      const gtmScriptNode = document.getElementById(this.props.scriptId);

      if (gtmScriptNode === null) {
        console.warn(`Can't find Google Tag Manager script by id ${this.props.scriptId}`);
      } else {
        eval(gtmScriptNode.textContent); // eslint-disable-line no-eval
      }
    }
  }

  render() {
    const gtm = gtmParts({
      id: this.props.gtmId,
      dataLayerName: this.props.dataLayerName,
      additionalEvents: this.props.additionalEvents || {},
      previewVariables: this.props.previewVariables || false,
    });

    return (
      <div>
        <div>{gtm.noScriptAsReact()}</div>
        <div id={this.props.scriptId}>{gtm.scriptAsReact()}</div>
      </div>
    );
  }
}

GoogleTagManager.defaultProps = {
  dataLayerName: 'dataLayer',
  additionalEvents: null,
  previewVariables: null,
  scriptId: 'react-google-tag-manager-gtm',
};

GoogleTagManager.propTypes = {
  gtmId: PropTypes.string.isRequired,
  dataLayerName: PropTypes.string,
  additionalEvents: PropTypes.object,
  previewVariables: PropTypes.string,
  scriptId: PropTypes.string,
};

export default GoogleTagManager;
