import React from 'react';
import PropTypes from 'prop-types';
import steamIcon from '../../../../../public/images/icons/steam.svg';

const ExpiredSteamConnection = props => (
  <div>
    <img src={steamIcon} alt="Steam icon" />
    <div>
      <h2>
        Steam link lost, please refresh your Steam account link.{' '}
        <a
          href="https://support.paradoxplaza.com/hc/en-us/articles/360009336553-Steam-connection-lost-please-refresh-your-Steam-account-link"
          className="plain-link"
        >
          What is this?
        </a>
      </h2>
    </div>
    <div className="buttonContainer">
      <a href={props.steamUrl} className="button" data-test="reconnect-steam-account">
        Refresh Link
      </a>
    </div>
  </div>
);

ExpiredSteamConnection.propTypes = {
  userId: PropTypes.string.isRequired,
  steamUrl: PropTypes.string.isRequired,
};

export default ExpiredSteamConnection;
