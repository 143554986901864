import { connect } from 'react-redux';
import View from './refreshTwitchConnection.view.jsx';
import { getTwitchUrl } from '../../../../utilities/Utilities';

const mapStateToProps = state => ({
  userId: state.authentication.user.userId,
  twitchUrl: getTwitchUrl(state.authentication.twitchState, state.authentication.twitchNonce, { internalRedirect: "redeem" }),
});

export default connect(mapStateToProps)(View);
