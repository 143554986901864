const checkSteamConnection = async (userId) => {
  fetch(`/api/steam/connection?userId=${userId}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
    method: 'GET',
  });
}

const connect = (userId, steamId, steamToken) =>
  fetch('/api/steam/connection', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      userId,
      steamId,
      steamToken,
    }),
    credentials: 'same-origin',
    method: 'PUT',
  });

const disconnect = userId =>
  fetch('/api/steam/connection', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ userId }),
    credentials: 'same-origin',
    method: 'DELETE',
  });

export default {
  checkSteamConnection,
  connect,
  disconnect,
};
