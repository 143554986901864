import base64Url from 'base64url';
// General utilities which should break into different classes later
// should be removed with feat/salesforce-subscriptions
const STATUS_KEY = 'status';
const SESSION_TOKEN = process.env.SESSION_COOKIE_NAME;
const STEAM_BASE_URL = 'https://steamcommunity.com/oauth/login?response_type=token';
const PSN_BASE_URL =
  process.env.REACT_APP_NODE_ENV === 'production'
    ? 'https://ca.account.sony.com/api/v1/oauth/authorize?service_entity=urn:service-entity:psn&response_type=code'
    : 'https://auth.api.sp-int.sonyentertainmentnetwork.com/2.0/oauth/authorize?service_entity=urn:service-entity:psn&response_type=code';
const TWITCH_BASE_URL = 'https://id.twitch.tv/oauth2/authorize?response_type=code';
const XBOX_CLIENT_ID = '9abf1121-8098-4ec7-b54f-7be1280c7b0b';
const XBOX_BASE_URL = `https://login.live.com/oauth20_authorize.srf?client_id=${XBOX_CLIENT_ID}`;
const STEAM_CLIENT_ID = process.env.STEAM_CLIENT_ID;
const PSN_CLIENT_ID = '74c27800-468a-4290-84b1-94f8d95784c1';
const TWITCH_CLIENT_ID = process.env.TWITCH_CLIENT_ID;
const EPIC_CLIENT_ID = process.env.EPIC_CLIENT_ID;
const EPIC_BASE_URL = `https://www.epicgames.com/id/authorize?`
const COUNTRY = 'COUNTRY';
const CAS_COOKIE = 'tgt';
const EXPIRATION_TIME = 60 * 60 * 60 * 1000;
const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const getSteamUrl = (state) => `${STEAM_BASE_URL}&client_id=${STEAM_CLIENT_ID}&state=${state}`;

export const getXboxUrl = function(state) {
  const xboxRedirectUri = getXBoxRedirectUri();
  return `${XBOX_BASE_URL}
    &scope=xboxlive.signin
    &redirect_uri=${xboxRedirectUri}
    &response_type=code&state=${state}`;
};

const getXBoxRedirectUri = () => {
  if (process.env.NODE_ENV === 'development') {
    return 'http://localhost:8082/connect/xbox/callback';
  } else if (process.env.REACT_APP_NODE_ENV !== 'production') {
    return `https://${
      process.env.REACT_APP_NODE_ENV
    }-accounts.paradoxplaza.com/connect/xbox/callback`;
  }
  return 'https://accounts.paradoxplaza.com/connect/xbox/callback';
};

export const getPsnUrl = function(state) {
  const psnRedirectUri = getPsnRedirectUri();
  return `${PSN_BASE_URL}&client_id=${PSN_CLIENT_ID}&redirect_uri=${psnRedirectUri}&scope=psn:s2s&state=${state}`;
};

export const getPsnRedirectUri = function() {
  if (process.env.NODE_ENV === 'local') {
    return 'https://test-accounts.paradoxplaza.com/connect/psn/callback';
  } else if (process.env.REACT_APP_NODE_ENV !== 'production') {
    return `https://${
      process.env.REACT_APP_NODE_ENV
    }-accounts.paradoxplaza.com/connect/psn/callback`;
  }
  return 'https://accounts.paradoxplaza.com/connect/psn/callback';
};

/**
 * @param state {string} Random token to be echoed back (see https://dev.twitch.tv/docs/authentication/getting-tokens-oidc#oidc-authorization-code-flow)
 * @param nonce {string} Random token for Twitch to encode in the ID token
 * @param options {?{ internalRedirect: string }} Optional object with custom internal redirect path for Twitch linking (will redirect to 'settings' if omitted)
 * @returns {string}
 */
export const getTwitchUrl = function(state, nonce, options = { internalRedirect: 'settings' }) {
  const twitchRedirectUri = getTwitchRedirectUri();
  const scopes = 'user:read:email+openid'; // https://dev.twitch.tv/docs/authentication/#scopes
  const stateAndRedirect = JSON.stringify({ state, redirect: options && options.internalRedirect })
  const stateValue = base64Url.encode(stateAndRedirect);
  const claims = ''; // https://dev.twitch.tv/docs/authentication/getting-tokens-oidc#claims
  return `${TWITCH_BASE_URL}&force_verify=true&client_id=${TWITCH_CLIENT_ID}&redirect_uri=${twitchRedirectUri}&scope=${scopes}&claims=${claims}&state=${stateValue}&nonce=${nonce}`;
};

export const getTwitchRedirectUri = function() {
  if (process.env.API_ENV === 'local') {
    return 'http://localhost:8082/connect/twitch/callback';
  } else if (process.env.REACT_APP_NODE_ENV !== 'production') {
    return `https://${
      process.env.REACT_APP_NODE_ENV
    }-accounts.paradoxplaza.com/connect/twitch/callback`;
  }
  return 'https://accounts.paradoxplaza.com/connect/twitch/callback';
};

/**
 * @param state {string} Random token to be echoed back
 * @param nonce {string} Random token for Epic to return in the response
 * @returns {string}
 */
export const getEpicUrl = function(state, nonce) {
  const epicRedirectUri = getEpicRedirectUri();
  const scope = 'basic_profile';
  return `${EPIC_BASE_URL}client_id=${EPIC_CLIENT_ID}&response_type=code&redirect_uri=${epicRedirectUri}&scope=${scope}&state=${state}&nonce=${nonce}`;
};

export const getEpicRedirectUri = function() {
  if (process.env.API_ENV === 'local') {
    return 'http://localhost:8082/connect/epic/callback';
  } else if (process.env.REACT_APP_NODE_ENV !== 'production') {
    return `https://${
      process.env.REACT_APP_NODE_ENV
    }-accounts.paradoxplaza.com/connect/epic/callback`;
  }
  return 'https://accounts.paradoxplaza.com/connect/epic/callback';
};

export const getToken = function(getState) {
  return getState().authentication.user.session.token;
};

export const errorAction = function(type, error, meta) {
  // Creates a Flux standard action type error
  // See https://github.com/redux-utilities/flux-standard-action
  let errorObject = error;
  if (typeof errorObject === 'string') {
    errorObject = new Error(errorObject);
  }
  const action = {
    type,
    payload: errorObject,
    error: true,
  };
  if (meta) {
    action.meta = meta;
  }
  return action;
};

export const logError = function(title, text) {
  const error = {
    title,
    text,
  };

  return fetch('/api/logger', {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(error),
    credentials: 'same-origin',
  });
};

export const emailTemplate = () => {
  if (process.env.REACT_APP_NODE_ENV === 'production') {
    return null;
  } else {
    return process.env.REACT_APP_NODE_ENV;
  }
};

export default class Utilities {
  static get isLocalhost() {
    return window.location.href.includes('localhost');
  }

  static setCookie(name, sessionToken, expire = false) {
    const value = `${name}=${sessionToken}`;
    const path = 'path=/';
    const domain = `${this.getDomain()}`;
    const date = new Date();
    // fix me
    if (expire) {
      date.setTime(date.getTime() - EXPIRATION_TIME);
    } else {
      date.setTime(date.getTime() + EXPIRATION_TIME);
    }
    const expires = `expires=${date.toUTCString()}`;
    const secure = this.isLocalhost ? '' : 'secure;';

    document.cookie = `${value}; ${path}; ${domain}; ${expires}; ${secure}`;
  }

  static setSessionCookie(value, expired) {
    this.setCookie(SESSION_TOKEN, value, expired);
  }

  static get sessionCookie() {
    return this.getCookie(SESSION_TOKEN);
  }

  static clearCasCookie() {
    this.setCookie(CAS_COOKIE, '', true);
  }

  static clearCookies() {
    this.setSessionCookie('', true);
    this.clearCasCookie();
  }

  static clearLocalStorage() {
    // should be removed with feat/salesforce-subscriptions
    localStorage.removeItem(STATUS_KEY);
    // should be removed with feat/salesforce-subscriptions
    setTimeout(() => localStorage.removeItem(STATUS_KEY), 300);
    setTimeout(() => window.persistor.purge(), 300);
  }

  static getDomain() {
    let domain = 'domain=paradoxplaza.com';

    if (this.isLocalhost) {
      domain = 'domain=localhost';
    }

    return domain;
  }

  static serializeTitle(title) {
    return title
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace('(', '')
      .replace(')', '')
      .replace(':', '');
  }

  /* eslint-disable no-param-reassign */
  static getParams(query) {
    if (!query) {
      return {};
    }

    // TODO: use an actual URL parser
    return (/^[?#]/.test(query) ? query.slice(1) : query).split('&').reduce((params, param) => {
      const [key, ...values] = param.split('=');
      params[key] = values.join('=');
      return params;
    }, {});
  }

  static getHashParams(str) {
    if (str[0] === '#') {
      str = str.substring(1);
    }

    const output = {};
    const pieces = str.split('&');

    pieces.forEach(piece => {
      const parts = piece.split('=');

      if (parts.length < 2) {
        parts.push('');
      }

      output[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
    });

    if (output.state) {
      output.state = Utilities.getHashParams(output.state);
    }

    return output;
  }

  static getHashPsnParams(str) {
    if (str[0] === '?') {
      str = str.substring(1);
    }
    const output = {};
    const pieces = str.split('&');
    pieces.forEach(piece => {
      const parts = piece.split('=');
      if (parts.length < 2) {
        parts.push('');
      }
      output[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
    });
    if (output.state) {
      output.state = Utilities.getHashPsnParams(output.state);
    }
    return output;
  }

  static getCookie(cname) {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  static loadRecaptcha() {
    const country = this.getCookie(COUNTRY);

    const script = document.createElement('script');
    if (country === 'CN') {
      script.src = '/api/reCaptcha';
    } else {
      script.src = 'https://www.google.com/recaptcha/api.js';
    }
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }

  static formatDate(timestamp) {
    const date = new Date(timestamp);

    return `${monthNames[date.getMonth()]} ${Utilities.applyOrdinal(
      date.getDate(),
    )} ${date.getFullYear()}`;
  }

  static applyOrdinal(day) {
    if (day > 3 && day < 21) {
      return `${String(day)}th`;
    }

    switch (day % 10) {
      case 1:
        return `${String(day)}st`;
      case 2:
        return `${String(day)}nd`;
      case 3:
        return `${String(day)}rd`;
      default:
        return `${String(day)}th`;
    }
  }
}
