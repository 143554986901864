import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import Authentication from '../authentication/Authentication/authentication';
import Loading from '../Loading/index';
import Utilities from '../../utilities/Utilities';
import { accounts } from '../../api/api';
import GoogleTagManager from '../GoogleTagManager/googleTagManager';
import Confirmation from '../authentication/Confirmation/confirmation';
import SteamConnect from '../dashboard/SteamConnect/steamConnect';
import PsnConnect from '../dashboard/PsnConnect/psnConnect';
import XboxConnect from '../dashboard/XboxConnect/xboxConnect';
import TwitchConnect from '../dashboard/TwitchConnect/twitchConnect';
import EpicConnect from '../dashboard/EpicConnect/epicConnect';

const { ACCOUNT, REACTIVATION, SUBSCRIPTION, ACCOUNT_SUBSCRIPTION, LEAD_INTEREST } = accounts;

class Root extends React.Component {
  constructor(props) {
    super(props);
    this.state = { tokenRefreshed: false };
  }

  async componentDidMount() {
    if (Utilities.sessionCookie) {
      await this.props.refreshSessionRequest(Utilities.sessionCookie);
    }

    this.setState({
      tokenRefreshed: true,
    });

    return true;
  }

  get steamConnectSubscriptionRoute() {
    return (
      <Route
        exact
        path="/connect/steam/callback"
        component={props => <SteamConnect {...props} />}
      />
    );
  }

  get psnConnectSubscriptionRoute() {
    return (
      <Route exact path="/connect/psn/callback" component={props => <PsnConnect {...props} />} />
    );
  }

  get xboxConnectSubscriptionRoute() {
    return (
      <Route exact path="/connect/xbox/callback" component={props => <XboxConnect {...props} />} />
    );
  }

  get twitchConnectSubscriptionRoute() {
    return (
      <Route
        exact
        path="/connect/twitch/callback"
        component={props => <TwitchConnect {...props} />}
      />
    );
  }

  get epicConnectSubscriptionRoute() {
    return (
      <Route exact path="/connect/epic/callback" component={props => <EpicConnect {...props} />} />
    );
  }

  get confirmAccountRoute() {
    return (
      <Route
        exact
        path="/confirm/:token"
        component={props => (
          <Confirmation
            {...props}
            processingText="Wait while we confirm your account"
            successText="Your account is now confirmed"
            errorText="Your account could not be confirmed"
            confirmationType={ACCOUNT}
          />
        )}
      />
    );
  }

  get confirmReactivateAccountRoute() {
    return (
      <Route
        exact
        path="/reactivate/:token"
        component={props => (
          <Confirmation
            {...props}
            processingText="Wait while we reactivate your account"
            successText="Your account is now activated"
            errorText="Your account could not be reactivated"
            confirmationType={REACTIVATION}
          />
        )}
      />
    );
  }

  get confirmSubscriptionRoute() {
    return (
      <Route
        exact
        path="/confirm/newsletter/:token"
        component={props => (
          <Confirmation
            {...props}
            processingText="Wait while we confirm your newsletter subscription"
            successText="Your newsletter subscription is now confirmed"
            errorText="Your newsletter subscription could not be confirmed"
            confirmationType={SUBSCRIPTION}
          />
        )}
      />
    );
  }

  get confirmAccountSubscriptionRoute() {
    return (
      <Route
        exact
        path="/confirm/account-newsletter/:token"
        component={props => (
          <Confirmation
            {...props}
            processingText="Wait while we confirm your account and subscribe you to our newsletter"
            successText="Your account is now confirmed with a subscription
            to our newsletter, per your request"
            errorText="Your account or newsletter subscription could not be confirmed"
            confirmationType={ACCOUNT_SUBSCRIPTION}
          />
        )}
      />
    );
  }

  get confirmLeadSubscriptionRoute() {
    return (
      <Route
        exact
        path="/confirm/interest/:token/:interest"
        component={props => (
          <Confirmation
            {...props}
            processingText="Wait while we confirm your email"
            successText="Your email is now confirmed"
            errorText="Your email subscription could not be confirmed"
            confirmationType={LEAD_INTEREST}
          />
        )}
      />
    );
  }

  render() {
    if (this.state.tokenRefreshed) {
      return (
        <div id="root">
          <Switch>
            {this.confirmAccountRoute}
            {this.confirmReactivateAccountRoute}
            {this.confirmSubscriptionRoute}
            {this.confirmSubscriptionOnetimeKeyRoute}
            {this.confirmAccountSubscriptionRoute}
            {this.confirmLeadSubscriptionRoute}
            {this.steamConnectSubscriptionRoute}
            {this.psnConnectSubscriptionRoute}
            {this.xboxConnectSubscriptionRoute}
            {this.twitchConnectSubscriptionRoute}
            {this.epicConnectSubscriptionRoute}
            <Route
              path="/"
              component={() => (
                <Fragment>
                  <Authentication />
                  <GoogleTagManager />
                </Fragment>
              )}
            />
          </Switch>
          <Loading />
        </div>
      );
    }

    return <div id="root" />;
  }
}

Root.propTypes = {
  isSignedIn: PropTypes.bool.isRequired,
  refreshSessionRequest: PropTypes.func.isRequired,
};

export default Root;
