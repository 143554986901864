import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { Route } from 'react-router-dom';
import { ConnectedRouter as BrowserRouter } from 'react-router-redux';
import { PersistGate } from 'redux-persist/es/integration/react';

import Root from './components/Root/root';
import ScrollToTop from './components/ScrollToTop';
import './styles/style.scss';
import initFontObserver from './styles/style';
import { store, history, persistor } from './store';

window.store = store;
window.persistor = persistor;

initFontObserver();

const render = Component => {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter history={history}>
          <AppContainer>
            <ScrollToTop>
              <Route component={Component} />
            </ScrollToTop>
          </AppContainer>
        </BrowserRouter>
      </PersistGate>
    </Provider>,
    document.getElementById('app'),
  );
};

render(Root);

if (module.hot) {
  module.hot.accept('./components/Root/root', () => {
    render(Root);
  });
}
