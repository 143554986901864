import { connect } from 'react-redux';
import View from './epicConnect.view.jsx';
import { requestEpicConnect } from '../../../actions/authentication';

const mapStateToProps = state => ({
  isSignedIn: state.authentication.isSignedIn,
  errorTitle: state.authentication.user.errorTitle || '',
  epicState: state.authentication.epicState,
  epicNonce: state.authentication.epicNonce,
});

const mapDispatchToProps = {
  requestEpicConnect,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
