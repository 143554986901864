import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import View from './confirmation.view.jsx';
import { confirmationRequest } from '../../../actions/authentication';

const mapStateToProps = ({ authentication }) => ({
  isSignedIn: authentication.isSignedIn,
});

const mapDispatchToProps = {
  confirmationRequest,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View));
