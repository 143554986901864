import React from 'react';
import PropTypes from 'prop-types';
import twitchIcon from '../../../../../public/images/icons/twitch-icon.svg';

const RefreshTwitchConnection = props => (
  <div>
    <img src={twitchIcon} alt="Twitch icon" />
    <div>
      <h2>
        Twitch link lost, please refresh your Twitch account link.{' '}
      </h2>
    </div>
    <div className="buttonContainer">
      <a href={props.twitchUrl} className="button" data-test="reconnect-twitch-account">
        Refresh Link
      </a>
    </div>
  </div>
);

RefreshTwitchConnection.propTypes = {
  userId: PropTypes.string.isRequired,
  twitchUrl: PropTypes.string.isRequired,
};

export default RefreshTwitchConnection;
