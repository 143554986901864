import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import View from './game.view.jsx';
import {
  requestGames,
  gameAchievementsRequest,
  requestConnections,
} from '../../../../../actions/authentication';
import Utilities from '../../../../../utilities/Utilities';

const defaultGameInfo = {
  title: '',
  platforms: [],
  type: '',
  sku: '',
  keys: [],
  operatingSystems: [],
  forumUrl: '',
};

const mapStateToProps = (state, props) => ({
  userId: state.authentication.user.userId,
  achievements: state.authentication.achievements,
  gameInfo:
    state.authentication.games.find(
      g => Utilities.serializeTitle(g.title) === props.match.params.game,
    ) || defaultGameInfo,
  isLoadingAchievements: state.authentication.isLoadingAchievements,
  isSteamConnectionFetched: state.authentication.isSteamConnectionFetched,
  games: state.authentication.games,
});

const mapDispatchToProps = {
  gameAchievementsRequest,
  requestSteamConnection: requestConnections,
  requestGames,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View));
