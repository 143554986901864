import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import View from './setPassword.view.jsx';
import { resetPasswordRequest } from '../../../actions/authentication';

const mapStateToProps = ({ authentication }) => ({
  isSignedIn: authentication.isSignedIn,
});

const mapDispatchToProps = {
  resetPasswordRequest,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View));
