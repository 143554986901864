import React from 'react';
import PropTypes from 'prop-types';

class Group extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      enabled: this.props.group.enabled,
    };

    this.toggle = this.toggle.bind(this);
  }

  get disabledClass() {
    return this.props.disabled ? 'disabled' : '';
  }

  get subscribedClass() {
    return this.state.enabled ? 'slider on' : 'slider';
  }

  toggle() {
    if (!this.props.disabled) {
      if (this.props.group.enabled) {
        this.props.unsubscribe(this.props.group);
      } else {
        this.props.subscribe(this.props.group);
      }

      this.setState(currentState => ({
        enabled: !currentState.enabled,
      }));
    }
  }

  render() {
    return (
      <div className="group">
        <div className="name">{this.props.group.name}</div>
        <div className={`${this.subscribedClass} ${this.disabledClass}`}>
          <a onClick={this.toggle} role="button" tabIndex="0">
            <div />
          </a>
        </div>
      </div>
    );
  }
}

Group.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    enabled: PropTypes.bool.isRequired,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  subscribe: PropTypes.func.isRequired,
  unsubscribe: PropTypes.func.isRequired,
};

export default Group;
