import { connect } from 'react-redux';
import View from './redeem.view.jsx';
import { invalidateGameState } from '../../../../actions/authentication';

const mapStateToProps = state => ({
  userId: state.authentication.user.session.userid,
});

const mapDispatchToProps = {
  invalidateGameState,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
