/**
 * Avoiding FOIT (Flash Of Invisible Text)
 * details: https://css-tricks.com/fout-foit-foft/
 */

import FontFaceObserver from 'fontfaceobserver';

const regularWeight = 400;
const semiboldWeight = 700;

export default () => {
  // there are also light and bold variants, but we don't use them right now
  const fontRegular = new FontFaceObserver('Graphik', {
    weight: regularWeight,
  });
  const fontSemibold = new FontFaceObserver('Graphik', {
    weight: semiboldWeight,
  });

  Promise.all([fontRegular.load(), fontSemibold.load()]).then(() => {
    document.body.className += ' fonts-loaded';
  });
};
