import { emailTemplate } from '../../utilities/Utilities';
import { hawkHeader, requestFromCore, respondWith } from '../utils';

const ACCOUNT = 'account';
const REACTIVATION = 'reactivation';
const SUBSCRIPTION = 'subscription';
const ACCOUNT_SUBSCRIPTION = 'account-subscription';
const LEAD_INTEREST = 'lead-interest';

const checkConnections = async (userId) => {
  try {
    const response = await fetch(`/api/accounts/connections/${userId}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    return response.json();
  } catch(error) {
    throw error;
  }
};

const reset = async (newPassword, token, recaptchaResponse) => {
  const response = await fetch('/api/accounts/reset', {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      password: newPassword,
      token,
      recaptchaResponse,
    }),
  });
  return respondWith(await response.json());
};

const signupRequest = (formObj, landingUrl, sourceService, recaptchaResponse) =>
  fetch('/api/accounts', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: formObj.email,
      password: formObj.password,
      details: {
        dateOfBirth: formObj.dateOfBirth,
        country: formObj.country,
        state: formObj.state,
      },
      recaptchaResponse,
      sourceService,
      options: Object.assign(
        {},
        {
          landingUrl: landingUrl ? decodeURIComponent(landingUrl) : null,
          marketingPermission: formObj.newsletter,
          personalizedCommunicationsEnabled: true,
          partnerAdvertisingEnabled: formObj.partner,
        },
        emailTemplate() ? { emailTemplate: emailTemplate() } : {},
      ),
    }),
  })
    .then(response => response.json())
    .catch(error => ({ result: 'Failure', errorMessage: error }))
    .then(response => response);

const createSession = async token => {
  const response = await requestFromCore({
    token,
    httpMethod: 'PUT',
    url: 'accounts/sessions/accounts',
  });

  return respondWith(await response.json());
};

const getUser = async token => {
  const response = await requestFromCore({
    token,
    httpMethod: 'GET',
    url: 'accounts',
  });

  return respondWith(await response.json());
};

const userAuthenticate = async (username, password) => {
  const response = await requestFromCore({
    httpMethod: 'PUT',
    url: 'accounts/sessions/accounts',
    headers: {
      Authorization: hawkHeader(username, password),
    },
  });
  return respondWith(await response.json());
};

const accountDetails = async token => {
  const response = await requestFromCore({
    token,
    httpMethod: 'GET',
    url: 'accountdetails',
  });

  return respondWith(await response.json());
};

const updateAccountDetails = async (data = {}, token) => {
  const response = await requestFromCore({
    httpMethod: 'POST',
    url: 'accountdetails',
    data,
    token,
  });

  return respondWith(await response.json());
};

const requestResetPassword = async (email, token) => {
  const response = await requestFromCore({
    httpMethod: 'POST',
    url: 'accounts/password-reset',
    data: {
      email,
      ...(emailTemplate() ? { emailTemplate: emailTemplate() } : {}),
    },
    headers: { Authorization: null },
    token,
  });

  return respondWith(await response.json());
};

const setEmail = async (email, password, newEmail, token) => {
  const response = await requestFromCore({
    httpMethod: 'POST',
    url: 'accounts/change-email',
    data: Object.assign(
      { email: newEmail },
      emailTemplate() ? { emailTemplate: emailTemplate() } : {},
    ),
    headers: {
      Authorization: hawkHeader(email, password),
    },
    token,
  });

  return respondWith(await response.json());
};

const resendConfirmationEmail = async token => {
  const response = await requestFromCore({
    httpMethod: 'POST',
    url: 'accounts/resend-validation',
    data: Object.assign({}, emailTemplate() ? { 'email-template': emailTemplate() } : {}),
    token,
  });

  return respondWith(await response.json());
};

const confirm = async (token, type = ACCOUNT, interest = null) => {
  if (type === ACCOUNT) {
    const response = await requestFromCore({
      httpMethod: 'PUT',
      url: `accounts/confirm/${token}`,
      headers: { Authorization: null },
    });

    return respondWith(await response.json());
  }

  if (type === REACTIVATION) {
    const response = await requestFromCore({
      httpMethod: 'POST',
      url: `accounts/reactivate/${token}`,
      headers: { Authorization: null },
    });

    return respondWith(await response.json());
  }

  if (type === SUBSCRIPTION) {
    const response = await requestFromCore({
      httpMethod: 'GET',
      url: `accounts/newsletter/finalize/${token}`,
    });

    return respondWith(await response.json());
  }

  if (type === ACCOUNT_SUBSCRIPTION) {
    const response = await requestFromCore({
      httpMethod: 'PUT',
      url: `accounts/confirm/account-newsletter/${token}`,
      headers: { Authorization: null },
    });

    return respondWith(await response.json());
  }

  if (type === LEAD_INTEREST) {
    const response = await requestFromCore({
      httpMethod: 'GET',
      url: `accounts/newsletter/finalize-lead/${token}/${interest}`,
      headers: { Authorization: null },
    });

    return respondWith(await response.json());
  }

  return {};
};

const fetchReferralCode = async () => {
  const response = await fetch(`/api/loyalty/referral/code`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
    method: 'GET',
  });

  return respondWith(await response.json());
};

const deleteSession = async token => {
  const response = await requestFromCore({
    token,
    httpMethod: 'delete',
    url: 'accounts/sessions/accounts',
  });

  return respondWith(await response.json());
};

export default {
  checkConnections,
  createSession,
  deleteSession,
  fetchReferralCode,
  getUser,
  userAuthenticate,
  accountDetails,
  updateAccountDetails,
  requestResetPassword,
  setEmail,
  resendConfirmationEmail,
  confirm,
  signupRequest,
  reset,
  ACCOUNT,
  REACTIVATION,
  SUBSCRIPTION,
  ACCOUNT_SUBSCRIPTION,
  LEAD_INTEREST,
};
