import { connect } from 'react-redux';
import View from './generic.view.jsx';
import { removeNotification } from '../../../../actions/notifications';
import { getSteamUrl } from '../../../../utilities/Utilities';

const mapStateToProps = state => ({
  steamUrl: getSteamUrl(state.authentication.steamState),
});

const mapDispatchToProps = {
  removeNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
