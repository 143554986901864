import { connect } from 'react-redux';
import View from './setEmail.view.jsx';
import { setEmailRequest } from '../../../../actions/authentication';

const mapStateToProps = state => ({
  email: state.authentication.user.email,
});

const mapDispatchToProps = {
  setEmailRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
