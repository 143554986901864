const connect = (userId, psnCode) =>
  fetch('/api/psn/connection', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      userId,
      psnCode,
    }),
    credentials: 'same-origin',
    method: 'PUT',
  });

const disconnect = userId =>
  fetch('/api/psn/connection', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ userId }),
    credentials: 'same-origin',
    method: 'DELETE',
  });

export default {
  connect,
  disconnect,
};
