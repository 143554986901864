import React from 'react';
import PropTypes from 'prop-types';
import xboxIcon from '../../../../../public/images/icons/XBOX.svg';

class XboxAccount extends React.Component {
  constructor(props) {
    super(props);
    this.handleDisconnect = this.handleDisconnect.bind(this);
  }

  handleDisconnect(event) {
    event.preventDefault();
    this.props.requestXboxDisconnect(this.props.userId);
  }

  render() {
    if (this.props.isXboxConnected) {
      return (
        <div id="thirdPartyAccount">
          <div className="darkContentBlock">
            <img className="xboxImg" src={xboxIcon} alt="XBOX icon" />
            <div>
              <div className="status">Linked</div>
            </div>
            <button
              className="button-outline red"
              onClick={this.handleDisconnect}
              data-test="disconnect-xbox-account"
            >
              Unlink
            </button>
          </div>
        </div>
      );
    }
    return (
      <div id="thirdPartyAccount">
        <div className="darkContentBlock">
          <img className="link-thirdparty-img xboxImg" src={xboxIcon} alt="XBOX icon" />
          <div className="status">
            Link your Microsoft account.
          </div>
          <a href={this.props.xboxUrl} className="button-outline" data-test="connect-xbox-account">
            Link account
          </a>
        </div>
      </div>
    );
  }
}

XboxAccount.propTypes = {
  xboxUrl: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  isXboxConnected: PropTypes.bool.isRequired,
  isXboxConnectionFetched: PropTypes.bool.isRequired,
  requestXboxDisconnect: PropTypes.func.isRequired,
};

export default XboxAccount;
