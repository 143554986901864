import { connect } from 'react-redux';
import View from './yourProfile.view.jsx';
import { emailVerified, fetchReferralCode } from '../../../../actions/authentication';

const mapStateToProps = state => ({
  user: state.authentication.user,
  referralCode: state.authentication.referralCode,
});

const mapDispatchToProps = {
  emailVerified,
  fetchReferralCode,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View);
