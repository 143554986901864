import React from 'react';
import PropTypes from 'prop-types';
import { loading } from '../../../../../../utilities/loading';

const DEFAULT = 0;
const ACTIVATING = 1;
const ACTIVATED = 2;

const errorCodeMap = {
  'steam-api-error': 'Unexpected response received from Steam servers. Please try again later.',
  'invalid-token':
    'This redeem code is not valid. Please check that you have entered it correctly.',
  'expired-token': 'Steam link lost, please refresh your Steam account link.',
  'steam-key-registration-error': 'This steam key seems to have been activated already.',
  'steam-connection-not-found':
    "Your Paradox account hasn't been linked to Steam yet. Please go to the Settings page and link it.",
};

class ActivateButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      buttonState: props.gameKey.activated ? ACTIVATED : DEFAULT,
    };
    this.activateKey = this.activateKey.bind(this);
  }

  async activateKey() {
    this.setState({ buttonState: ACTIVATING });
    try {
      loading.start();
      this.props
        .activateGameKeyRequest(this.props.gameSku, this.props.gameKey.code)
        .then(() => {
          this.setState({ buttonState: ACTIVATED });
        })
        .catch(e => {
          let errorMessage = 'An unknown error occured when communicating with Steam.';
          if (e) {
            if (e.data.errorCode) {
              if (errorCodeMap[e.data.errorCode]) {
                errorMessage = errorCodeMap[e.data.errorCode];
              } else if (e.errorMessage) {
                errorMessage += ` Original error: ${e.data.errorMessage}.`;
              }
            }
          }
          this.setState({ buttonState: DEFAULT });
          this.props.appendNotification('Game', {
            text: `Could not activate key: ${errorMessage}`,
            timing: 10000,
          });
        });
    } catch (e) {
      let errorMessage = 'An unknown error occured when communicating with Steam.';
      if (e) {
        if (e.data.errorCode) {
          if (errorCodeMap[e.data.errorCode]) {
            errorMessage = errorCodeMap[e.data.errorCode];
          } else if (e.data.errorMessage) {
            errorMessage += ` Original error: ${e.data.errorMessage}.`;
          }
        }
      }
      this.props.appendNotification('Game', {
        text: `Could not activate key: ${errorMessage}.`,
        timing: 10000,
      });
      this.setState({ buttonState: DEFAULT });
    } finally {
      loading.end();
    }
  }

  render() {
    if (!this.props.isSteamConnected || this.props.isSteamExpired) {
      return (
        <div>
          <button
            data-test="activateButton:disabled"
            className="button"
            onClick={this.activateKey}
            disabled
          >
            Activate
          </button>
        </div>
      );
    }

    if (this.state.buttonState === ACTIVATED) {
      return <div data-test="activatedMessage">Activated</div>;
    }

    if (this.state.buttonState === ACTIVATING) {
      return (
        <div>
          <button data-test="activatingButton" className="button" disabled>
            Activating
          </button>
        </div>
      );
    }

    return (
      <div>
        <button data-test="activateButton" className="button" onClick={this.activateKey}>
          Activate
        </button>
      </div>
    );
  }
}

ActivateButton.propTypes = {
  gameKey: PropTypes.shape({
    universe: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    activated: PropTypes.bool.isRequired,
  }).isRequired,
  gameSku: PropTypes.string.isRequired,
  isSteamConnected: PropTypes.bool.isRequired,
  isSteamExpired: PropTypes.bool.isRequired,
  appendNotification: PropTypes.func.isRequired,
  activateGameKeyRequest: PropTypes.func.isRequired,
};

export default ActivateButton;
