import { requestFromCore, respondWith } from '../utils';

const userdata = async(namespace = '', token) => {
  const response = await requestFromCore({
    httpMethod: 'GET',
    url: `achievements/userdata/${namespace}`,
    token,
  });

  return respondWith(await response.json());
};

const metadata = async(namespace = '', token) => {
  const response = await requestFromCore({
    httpMethod: 'GET',
    url: `achievements/metadata/${namespace}`,
    token,
  });

  return respondWith(await response.json());
};

// computed property (maches userdata with metadata)
const userdataWithMeta = async(namespace, token) => {
  let userData;
  try {
    userData = await userdata(namespace, token);
  } catch (e) {
    userData = { result: 'Failure' };
  }
  if (userData.result !== 'OK') {
    userData = {
      achievements: {},
      result: 'OK',
    };
  }
  const metaData = await metadata(namespace);
  if (metaData.result !== 'OK') {
    return metaData;
  }
  const metaAchievements = metaData.achievements;
  const metaKeys = Object.keys(metaAchievements);

  const userAchievements = userData.achievements;
  const userKeys = Object.keys(userData.achievements);

  const finalArray = [];

  metaKeys.forEach(key => {
    metaAchievements[key].id = key;
    finalArray.push(metaAchievements[key]);
  });

  // fix me! (mocked data is a bit weird)
  userKeys.forEach((key, index) => {
    finalArray[index].userData = userAchievements[key];
  });
  // keeping the same interface
  return respondWith({
    achievements: finalArray,
    result: 'OK',
  });
};

export default {
  userdataWithMeta,
};
