import { connect } from 'react-redux';
import { refreshSessionRequest } from '../../actions/authentication';
import View from './root.view.jsx';

const mapStateToProps = ({ authentication }) => ({
  isSignedIn: authentication.isSignedIn,
});

const mapDispatchToProps = {
  refreshSessionRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
