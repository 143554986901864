import React from 'react';
import PropTypes from 'prop-types';

import Group from '../Group/group';
import newsletterIcon from '../../../../../public/images/icons/newsletter.svg';
import { withLoadingAnimation } from '../../../../utilities/loading';

class Newsletter extends React.Component {
  constructor(props) {
    super(props);

    this.subscribe = this.subscribe.bind(this);
  }

  componentDidMount() {
    withLoadingAnimation(this.props.newsletterStatusRequest(), this.props.newsletter);
  }

  subscribe() {
    let verified = false;
    if (!this.props.notifications.components.find(obj => obj.name === 'UnverifiedEmail')) {
      verified = true;
    }
    withLoadingAnimation(this.props.subscribeNewsletterRequest(verified));
  }

  get isPending() {
    return this.props.newsletter.status === 'pending';
  }

  get isSubscribed() {
    return this.props.newsletter.status === 'subscribed';
  }

  render() {
    let content;
    
      content = (
        <div className="content">
          <h3>Almost there...!</h3>
          Communication settings have been moved. In order to update the settings, please visit <a href="https://beta-accounts.paradoxinteractive.com/profile">Profile settings</a> on the new accounts website.
        </div>
      );

    return (
      <div id="newsletter">
        <h2>Communication Preferences</h2>
        <div className="communityNewsletter darkContentBlock borderBottom">
          <img src={newsletterIcon} alt="Newsletter icon" />
          {content}
        </div>
      </div>
    );
  }
}

Newsletter.propTypes = {
  newsletterStatusRequest: PropTypes.func.isRequired,
  subscribeNewsletterRequest: PropTypes.func.isRequired,
  unsubscribeNewsletterRequest: PropTypes.func.isRequired,
  newsletter: PropTypes.shape({
    groups: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        enabled: PropTypes.bool.isRequired,
      }),
    ).isRequired,
    platformGroups: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        enabled: PropTypes.bool.isRequired,
      }),
    ).isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  notifications: PropTypes.shape({
    components: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

export default Newsletter;
