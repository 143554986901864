import React from 'react';
import PropTypes from 'prop-types';
import Util from '../../../utilities/Utilities';

const OrderItem = props => (
  <div>
    <div className="darkContentBlock borderBottom">
      <div>
        {Util.formatDate(props.order.created)}
        <span className="pull-right">
          {props.order.items.length}
          {props.order.items.length === 1 ? <span> item</span> : <span> items</span>}
        </span>
      </div>
      <span className="ordernbr">Order #{props.order.id}</span>
    </div>
    {props.order.items.map(item => (
      <div className="order item" key={item.sku}>
        {item.hasImage ? (
          <img alt="" src={`/images/games/${item.sku}.png`} />
        ) : (
          <div className="game-image game-image_default" />
        )}
        <span>
          <span className="order-title">{item.title}</span>
        </span>
        <span className="price pull-right">
          {item.price} <sup>{props.order.currency}</sup>
        </span>
      </div>
    ))}

    <div className="order total">
      <span>Order Total</span>
      <span className="pull-right">
        {props.order.total} <sup>{props.order.currency}</sup>
      </span>
    </div>
  </div>
);

OrderItem.propTypes = {
  order: PropTypes.object.isRequired,
};
export default OrderItem;
