import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { loading } from '../../../utilities/loading';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      rememberMe: true,
      errorMessage: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  async handleInputChange(event) {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    this.setState({
      [name]: value,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    loading.start();
    try {
      await this.props.loginRequest(this.state.email, this.state.password);
    } catch (e) {
      this.setState({
        errorMessage: e,
      });
    }
    loading.end();
  }

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <div id="login">
            <input
              className="form-control"
              name="email"
              placeholder="Email address"
              tabIndex="1"
              onChange={this.handleInputChange}
            />

            <div className="passwordContainer">
              <input
                className="form-control"
                type="password"
                name="password"
                placeholder="Password"
                tabIndex="2"
                onChange={this.handleInputChange}
              />

              <Link to="/reset-password" className="button-outline" tabIndex="4">
                Forgot?
              </Link>
            </div>
            <div className="controls">
              <label className="checkbox" htmlFor="rememberMe">
                <input
                  id="rememberMe"
                  type="checkbox"
                  name="rememberMe"
                  checked={this.state.rememberMe}
                  onChange={this.handleInputChange}
                  tabIndex="5"
                />
                <div>Remember me</div>
              </label>
              <input type="submit" value="Login" className="button" tabIndex="3" />
            </div>
          </div>
        </form>
        {// TODO: Use exception to errorTitle selector
        this.state.errorMessage && <div className="error">{this.state.errorMessage}</div>}
        <div className="footer">
          Don’t have an account?
          <Link
            to={{ pathname: '/signup', search: this.props.location.search }}
            className="pull-right redirect-button"
          >
            Create account
          </Link>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  errorTitle: PropTypes.string.isRequired,
  loginRequest: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default Login;
