import { connect } from 'react-redux';
import View from './editProfile.view.jsx';
import { requestAccountDetailsUpdate } from '../../../../actions/authentication';

const mapStateToProps = state => ({
  accountDetails: state.authentication.user.accountDetails,
});

const mapDispatchToProps = {
  requestAccountDetailsUpdate,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
