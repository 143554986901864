import React from 'react';
import PropTypes from 'prop-types';

import Utilities from '../../../../../utilities/Utilities';

import check from '../../../../../../public/images/check.svg';

const imageUrlBase = 'https://achievements.paradoxplaza.com';

class AchievementItem extends React.Component {
  get title() {
    return this.props.titles['en-US'];
  }

  get descriptionHtml() {
    if (this.props.descriptions && this.props.descriptions['en-US']) {
      return this.props.descriptions['en-US'];
    }

    return null;
  }

  // TODO: Fix me! (mocked data are quite weird)
  get unlockedDate() {
    const timestamp = this.props.userData.achieved || this.props.userData.updated;

    return Utilities.formatDate(timestamp);
  }

  // TODO: Fix me! (mocked data are quite weird)
  get isUnlocked() {
    return this.props.userData && (this.props.userData.achieved || this.props.userData.updated);
  }

  // TODO: Fix me! (mocked data are quite weird)
  get lockedUnlockedHtml() {
    let html = <span>Locked</span>;

    if (this.isUnlocked) {
      html = (
        <div className="center verified">
          <div className="white">Unlocked {this.unlockedDate}</div>
          <img src={check} alt="" />
        </div>
      );
    }

    return html;
  }

  get imageUrl() {
    return `${imageUrlBase}/${this.props.gameNamespace}/${this.props.id}/${this.imageAlt}.png`;
  }

  get imageAlt() {
    return this.isUnlocked ? 'achieved' : 'unachieved';
  }

  render() {
    return (
      <div className={`center darkContentBlock borderBottom ${this.isUnlocked ? '' : 'locked'}`}>
        <div className="center">
          <div>
            <img alt={this.imageAlt} src={this.imageUrl} width="64" height="64" />
          </div>
          <div>
            <h4>{this.title}</h4>
            <div className="grey">{this.descriptionHtml}</div>
          </div>
        </div>
        <div className="pull-right">{this.lockedUnlockedHtml}</div>
      </div>
    );
  }
}

AchievementItem.defaultProps = {
  userData: {
    isAchieved: false,
    achieved: '',
    updated: '',
  },
};

AchievementItem.propTypes = {
  id: PropTypes.string.isRequired,
  titles: PropTypes.shape({
    'en-US': PropTypes.string.isRequired,
  }).isRequired,
  descriptions: PropTypes.shape({
    'en-US': PropTypes.string.isRequired,
  }).isRequired,
  userData: PropTypes.shape({
    isAchieved: PropTypes.bool,
    achieved: PropTypes.string,
    updated: PropTypes.string,
  }),
  gameNamespace: PropTypes.string.isRequired,
};

export default AchievementItem;
