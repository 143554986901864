import { connect } from 'react-redux';
import View from './expiredSteamConnection.view.jsx';
import { getSteamUrl } from '../../../../utilities/Utilities';

const mapStateToProps = state => ({
  userId: state.authentication.user.userId,
  steamUrl: getSteamUrl(state.authentication.steamState),
});

export default connect(mapStateToProps)(View);
