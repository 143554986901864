import { connect } from 'react-redux';
import View from './psnAccount.view.jsx';
import { requestPsnDisconnect } from '../../../../actions/authentication';
import { getPsnUrl } from '../../../../utilities/Utilities';

const mapStateToProps = state => ({
  psnUrl: getPsnUrl(state.authentication.psnState),
  userId: state.authentication.user.userId,
  isPsnConnected: state.authentication.isPsnConnected,
  isPsnConnectionFetched: state.authentication.isPsnConnectionFetched,
  psnState: state.authentication.psnState,
});

const mapDispatchToProps = {
  requestPsnDisconnect,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
