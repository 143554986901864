import React from 'react';
import PropTypes from 'prop-types';
import UnverifiedEmail from '../UnverifiedEmail/unverifiedEmail';
import ExpiredSteamConnection from '../ExpiredSteamConnection/expiredSteamConnection';
import RefreshTwitchConnection from '../RefreshTwitchConnection/refreshTwitchConnection';
import BetaAccounts from '../BetaAccounts/betaAccounts';
import Generic from '../Generic/generic';

const allComponents = {
  UnverifiedEmail,
  ExpiredSteamConnection,
  Generic,
  RefreshTwitchConnection,
  BetaAccounts,
};

const NotificationsBase = ({ notifications, notificationType }) => {
  const componentsFilter = notifications.components;
  const notificationOfType = componentsFilter.filter(
    component =>
      notificationType.includes(component.name) &&
      !(component.timing && component.timing > Date.now()),
  );

  if (notificationOfType.length <= 0) {
    return <span />;
  }

  const components = notificationOfType.map(comp => {
    const props = { key: comp.options.id, options: comp.options, name: comp.options.name };
    if (
      comp.name === 'UnverifiedEmail' ||
      comp.name === '' ||
      comp.name === 'ExpiredSteamConnection' ||
      comp.name === 'RefreshTwitchConnection' ||
      comp.name === 'BetaAccounts'
    ) {
      return React.createElement(allComponents[comp.name], props, null);
    }
    return React.createElement(allComponents.Generic, props, null);
  });

  const componentsContext = components.map(comp => (
    <div
      className={
        comp.props.name === 'ExpiredSteamConnection' ||
        comp.props.name === 'RefreshTwitchConnection' ||
        comp.props.name === 'UnverifiedEmail'
          ? 'notification specialNotification'
          : 'notification'
      }
      data-cy="notification"
      key={comp.key}
    >
      <div className="content">{comp}</div>
    </div>
  ));

  return <div>{componentsContext}</div>;
};

NotificationsBase.propTypes = {
  notifications: PropTypes.shape({
    components: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        options: PropTypes.shape({
          id: PropTypes.number.isRequired,
          text: PropTypes.string,
          triggeredBy: PropTypes.string,
          timing: PropTypes.number,
        }),
      }),
    ),
  }).isRequired,
  notificationType: PropTypes.array,
};

NotificationsBase.defaultProps = {
  notificationType: '',
};

export default NotificationsBase;
