import { connect } from 'react-redux';
import View from './thirdPartyConnections.view.jsx';
import { requestConnections } from '../../../../actions/authentication';

const mapStateToProps = state => ({
  userId: state.authentication.user.userId,
  isSteamConnected: state.authentication.isSteamConnected,
  isSteamConnectionFetched: state.authentication.isSteamConnectionFetched,
  isPsnConnected: state.authentication.isPsnConnected,
  isPsnConnectionFetched: state.authentication.isPsnConnectionFetched,
  isXboxConnected: state.authentication.isXboxConnected,
  isXboxConnectionFetched: state.authentication.isXboxConnectionFetched,
  isTwitchConnected: state.authentication.isTwitchConnected,
  isTwitchConnectionFetched: state.authentication.isTwitchConnectionFetched,
  isEpicConnected: state.authentication.isEpicConnected,
  isEpicConnectionFetched: state.authentication.isEpicConnectionFetched,
});

const mapDispatchToProps = {
  requestConnections,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View);
