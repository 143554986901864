import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Utilities from '../../../utilities/Utilities';
import OsPlatformSupport from './OsPlatformSupport';

class GameItem extends React.Component {
  get serializedTitle() {
    return Utilities.serializeTitle(this.props.title);
  }

  truncate(title) {
    if (!title) {
      return '';
    }

    const maxLength = 50;
    return title.length > maxLength ? `${title.substr(0, maxLength - 3)}...` : title;
  }

  render() {
    return (
      <li>
        {this.props.hasImage ? (
          <img
            alt={`${this.props.title} icon`}
            src={`/images/games/${this.props.sku}.png`}
            width="180"
            height="160"
          />
        ) : (
          <div className="game-image game-image_default" />
        )}
        <div className="content">
          <h4>{this.truncate(this.props.title)}</h4>
          <OsPlatformSupport
            operatingSystems={this.props.operatingSystems}
            platforms={this.props.platforms}
          />
        </div>
        <div className="manage">
          <Link
            to={{
              pathname: `/games/${this.serializedTitle}/details`,
              state: this.props,
            }}
            className="button"
          >
            Manage
          </Link>
        </div>
      </li>
    );
  }
}

GameItem.propTypes = {
  title: PropTypes.string.isRequired,
  hasImage: PropTypes.bool.isRequired,
  operatingSystems: PropTypes.arrayOf(PropTypes.string).isRequired,
  platforms: PropTypes.arrayOf(PropTypes.string).isRequired,
  sku: PropTypes.string.isRequired,
};
export default GameItem;
