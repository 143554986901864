import { connect } from 'react-redux';
import View from './downloads.view.jsx';
import { requestDownloads } from '../../../../actions/authentication';

const mapStateToProps = state => ({
  userId: state.authentication.user.userId,
  downloads: state.authentication.downloads,
});

const mapDispatchToProps = {
  requestDownloads,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
