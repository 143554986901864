import { connect } from 'react-redux';
import View from './group.view.jsx';
import {
  subscribeGroupNewsletterRequest,
  unsubscribeGroupNewsletterRequest,
} from '../../../../actions/newsletter';

const mapDispatchToProps = dispatch => ({
  subscribe(group) {
    dispatch(subscribeGroupNewsletterRequest(group));
  },
  unsubscribe(group) {
    dispatch(unsubscribeGroupNewsletterRequest(group));
  },
});

export default connect(null, mapDispatchToProps)(View);
